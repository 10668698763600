import React, { useEffect, useState } from 'react'
import axios from 'axios';
import './Padd.css'
import Slider from "react-slick";
import { getImgURL } from './util/getImgUrl';
import { useNavigate } from 'react-router-dom';
import { SERVER } from './server/server';


// import "~slick-carousel/slick/slick.css"; 
// import "~slick-carousel/slick/slick-theme.css";

export default function PaddCarou() {

  const [state, setState] = useState([]);
  const navigate = useNavigate()
  // let LandingAPI =()=>{
  //   axios.get('http://34.242.111.55:3002/dashboard/landingWork').then((res)=>{
  //     setState(res.data.message);
  //   }).catch((err) => console.log(err))
  // }

  let LandingAPI = async () => {
    await axios.get(`${SERVER}/dashboard/landingWork`).then((res) => {
      if (res) {
        setState(res.data.message);
      } else {
        console.log('failed');
      }

    }).catch((error) => console.log(error))
  }
  useEffect(() => {
    LandingAPI()
  }, [])

  const settings = {

    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 2712,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          // dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          // dots:false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // dots:false
        }
      }
    ]
  };
  return (
    <div className='bg-dark'>
      <Slider {...settings}>
        {state && state.length ? state.map((e, i) => {
          return (
            <div key={i} className='bgpadd'>
              <div className='mx-1 shadow-lg'>
                <div className=''><img className="card-img-top" src={getImgURL(e.work.coverPicture)} alt="Card" style={{ width: '100%', height: '200px' }} /></div>
                <div className="card-body text-light  cardtop" style={{ borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }} >
                  <p className="card-title mb-1" style={{ fontSize: '14px', marginTop: '-10px', color: 'rgb(251, 244, 231)' }}><b><i>- {e.name}</i></b></p>
                  <h6 className="card-title mb-1">{e.work.title}</h6>
                  <p className="card-text mb-2 pe-4" style={{ fontSize: '12px' }}>{e.work.description.slice(0, 20).join(' ')}...</p>
                  <button className='btn p-2 caro'
                    onClick={() => navigate(`WorkView/${e.work?.userId}/${e.work?._id}`)}
                  >View</button>

                </div>
              </div>
            </div>
          )
        }
        ) :
          ""
        }
      </Slider>
    </div>
  )
}
