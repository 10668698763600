let initialState = {
    loading:false,
    userPitch : []
}

const getPitch = (state=initialState,action) =>{
    switch (action.type) {
        case "CALLING_PITCH":
            return {
                ...state,
                loading:true
            }
        case "GET_CREATOR_PITCH":
            return {
                ...state,
                userPitch:action.payload,
                loading:false
            }
        case "NO_PITCH":
            return {
                ...state,
                loading:false,
                userPitch : []
            }
        case "LOG_OUT":
            return {
                ...state,
                loading:false,
                userPitch : []
            }
    
        default:
            return state
    }
}

export default getPitch;