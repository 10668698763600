import React, { useRef, useState } from 'react';
import './WorkPdf.css';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { newButton } from '../../background'
import Button from '@mui/material/Button';
import { SERVER } from '../../../server/server';
import { useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import { makeStyles } from '@mui/styles';
import { useWindowSize } from '../../../Hooks/useWindowSize';
import { useDispatch } from 'react-redux';
import { callPopUp } from '../../../Action';
const WorkPdf = ({ file }) => {
    const resize = useWindowSize();
    const pdfRef = useRef()
    const dispatch = useDispatch()
    const getPdf = (name) => name?.startsWith('https') ? `${name}#toolbar=0` : `${SERVER}/uploads/${name}#toolbar=0`

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [offsets, setOffsets] = useState("")
    useEffect(() => {
        setOffsets(document.getElementById('11a').getBoundingClientRect());
        // code for right click disabled {
        const handleContextMenu = (e) => {
            e.preventDefault()
        }
        document.addEventListener("contextmenu", handleContextMenu)
        return () => {
            document.removeEventListener("contextmenu", handleContextMenu)
        }
        // } code for right click disabled
    }, [])
    function onDocLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);

    }
    function changePage(offset) {
        setPageNumber(prevPageNo => prevPageNo + offset)
    }

    function changePageBack() {
        pdfRef.current?.scrollIntoView({ behavior: "smooth" })
        changePage(-1)
    }
    function changePageNext() {
        pdfRef.current?.scrollIntoView({ behavior: "smooth" })

        if (localStorage.getItem('token')) {
            changePage(+1)

        } else {
            if (pageNumber < Math.round(numPages * 20 / 100)) {
                changePage(+1)
            } else {
                dispatch(callPopUp({ type: 'signin' }))

            }
        }


    }
    const useStyles = makeStyles(() => ({
        ul: {
            "& .MuiPaginationItem-root": {
                color: "#ff0000",
            },
            "& li .Mui-selected": {
                color: "white",
                backgroundColor: "#ff0000"
            }
        }
    }));
    const classes = useStyles();


    const pdfSize = (size) => {
        if (size[0] <= 390) {
            return '320'
        }
        if (size[0] <= 500 && size[0] >= 390) {
            return '350'
        }
        else if (size[0] <= 700 && size[0] >= 500) {
            return '450'
        }
        else if (size[0] <= 900 && size[0] >= 700) {
            return '700'
        }
        else if (size[0] >= 900) {
            return '1000'
        }
    }
    const onChangeHandle = (e) => {

        if (localStorage.getItem('token')) {
            setPageNumber(Number(e.target.innerText))

        } else {
            if (e.target.innerText <= Math.round(numPages * 20 / 100)) {
                setPageNumber(Number(e.target.innerText))
            } else {
                dispatch(callPopUp({ type: 'signin' }))
            }
        }
    }
    return (

        <>
            <div className='pdf_main'>
                <header className='pdf_header' id='11a' ref={pdfRef}>
                    <div className="m-2">
                        <Pagination size="large" classes={{ ul: classes.ul }} count={numPages} page={pageNumber} onChange={onChangeHandle} shape="rounded" color="primary" hidePrevButton hideNextButton />
                    </div>

                    <Document style={{ backgroundColor: 'white' }}

                        // file='http://54.246.61.54:3002/uploads/1675190001943assignmentsNew.pdf'
                        file={getPdf(file)}
                        onLoadSuccess={onDocLoadSuccess}>
                        {/* <Typography color='white'>Page{pageNumber} of {numPages}</Typography> */}
                        <Page renderAnnotationLayer={false} renderTextLayer={false} className='pdfPage' width={pdfSize(resize)} pageNumber={pageNumber} />
                        {/* <h1 style={{color:'#fff'}}>{resize[0]}</h1> */}

                        {
                            pageNumber > 1 &&
                            <Button size='small' sx={{ m: 2, ...newButton }} onClick={changePageBack} variant="contained">Prev Page</Button>

                        }
                        {
                            pageNumber < numPages &&
                            <Button size='small' sx={{ m: 2, ...newButton }} onClick={changePageNext} variant="contained">Next Page</Button>


                        }
                    </Document>
                </header>

            </div>
            {/* <center>
        <div>
            <Document file='http://54.246.61.54:3002/uploads/1675190001943assignmentsNew.pdf' onLoadSuccess={onDocLoadSuccess}>
                {Array.from(
                    new Array(numPages),
                    (el, index) => (
                        <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                        />
                    )
                )}
            </Document>
        </div>
    </center> */}
        </>
    )
}

export default WorkPdf