let initialState = {
    userList : { loading:false ,list:[]},
    activeUser: '',
    userMessage:{loading:false , data:[]}
}

const messageSection = (state=initialState,action) =>{
    switch (action.type) {
        case "CALL_GET_USER_LIST":
            return {
                ...state,
                userList:{loading:true}
            }
        case "GET_USER_LIST":
            return {
                ...state,
                userList:{loading:false,list:action.payload}
            }
        case "ADD_IN_USER_LIST":
            return {
                ...state,
                userList:{loading:false,list:[action.payload,...state.userList.list]}
            }
        case "GET_USER_RECOGNIZATION":
            
            return {
                ...state,
                activeUser:action.payload
            }
        case "CALL_GET_USER_MSG":
            return {
                ...state,
                userMessage:{...state.userMessage,loading:true}
            }
        case "GET_USER_MSG":
            return {
                ...state,
                userMessage:{loading:false,data:action.payload}
            }
        case "GET_USER_MSG_BY_SOCKET":
            return {
                ...state,
                userMessage:{loading:false,data:[...state.userMessage.data,action.payload]}
            }
        default:
            return state
    }
}

export default messageSection;