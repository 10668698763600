import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from './contextApi/themeContext';

export default function PaddFooter() {
  const navigate = useNavigate();
  const { theme } = useTheme();

  const onClickHandle = (num) => {
    const category = { 7: 'Author', 8: 'Writer', 9: 'Music', 10: 'Audio', 11: 'Music Video', 12: 'Film', 13: 'Television' };
    navigate(`Search/${num}/${category[num]}`);
  };

  const itemStyle = { cursor: 'pointer' };

  return (
    <div style={{ background: theme === 'dark' ? 'black' : 'white', color: theme === 'dark' ? 'white' : 'black' }} className='container-fluid '>
      <div className='mx-auto pt-3'  >
        <span className='row' style={{ fontSize: '13px' }}>
          {/* <div className="col-6 col-sm-3 col-md-3">
          <NavLink><p className='text-light'>Paid Stories</p></NavLink>
        </div> */}

          {/* <div className="col-6 col-sm-3 col-md-3 ">
          <NavLink><p className='text-light'>Get the App</p></NavLink>
        </div> */}
          {/* <div className="col-6 col-sm-3 col-md-3 ">
          <NavLink><p className='text-light'>Language</p></NavLink>
        </div> */}
          {/* <div className="col-6 col-sm-3 col-md-3 " style={itemStyle} onClick={(e) => onClickHandle(7)}>
            <p className='text-light'>Author</p>
          </div> */}
          {/* <div className="col-6 col-sm-3 col-md-3 " style={itemStyle} onClick={() => onClickHandle(8)}>
            <NavLink><p className='text-light'>Writer</p></NavLink>
          </div>
          <div className="col-6 col-sm-3 col-md-3 " style={itemStyle} onClick={() => onClickHandle(9)}>
            <NavLink><p className='text-light'>Music</p></NavLink>
          </div>
          <div className="col-6 col-sm-3 col-md-3 " style={itemStyle} onClick={() => onClickHandle(10)}>
            <NavLink><p className='text-light'>Audio</p></NavLink>
          </div> */}
          {/* <div className="col-6 col-sm-3 col-md-3 " style={itemStyle} onClick={() => onClickHandle(11)}>
            <NavLink><p className='text-light'>Music Video</p></NavLink>
          </div>
          <div className="col-6 col-sm-3 col-md-3 " style={itemStyle} onClick={() => onClickHandle(12)}>
            <NavLink><p className='text-light'>Film</p></NavLink>
          </div>
          <div className="col-6 col-sm-3 col-md-3 " style={itemStyle} onClick={() => onClickHandle(13)}>
            <NavLink><p className='text-light'>Television</p></NavLink>
          </div> */}
          <div className='flex items-center justify-center space-x-4 w-full'>
            <Link to="/tnc">
              <p className='cursor-pointer' >Terms & Conditions</p>
            </Link>
            <Link to={"/privacypolicy"}>
              <p className='cursor-pointer' >Privacy Policy</p>
            </Link>
          </div>
          {/* <div className="" style={itemStyle} >
            <p className='text-light'> <a style={{ color: "inherit" }} href="/tnc">Terms & Conditions</a></p>
          </div>
          <div className="col-6 col-sm-3 col-md-6 " style={itemStyle} >
            <p className='text-light'> <a style={{ color: "inherit" }} href="/privacypolicy">Privacy Policy</a></p>
          </div> */}

          {/* <div className="col-6 col-sm-3 col-md-3 " style={itemStyle} >
            <NavLink><p className='text-light'>Try Premium</p></NavLink>
          </div> */}
        </span>

        <span className='row d-flex align-items-center justify-content-center' style={{ fontSize: '13px' }}>

          {/* <div className="col-6 col-sm-6 col-md-12 " style={itemStyle} >
            <p className='text-light'> <a style={{ color:"inherit" }} href="/privacypolicy">Terms & Conditions</a></p>
          </div> */}
          {/* <div className="col-6 col-sm-3 col-md-3 " style={itemStyle}>
            <p className='text-light'>Privacy</p>
          </div> */}
          {/* <div className="col-6 col-sm-3 col-md-3 " style={itemStyle} >
            <NavLink><p className='text-light'>Accessibility</p></NavLink>
          </div> */}

        </span>

      </div >
      <p style={{ fontSize: '15px' }} className='text-center me-5 my-2'>© 2023 MeBookMeta</p>
      <p style={{ fontSize: '15px' }} className='text-center'>Maintained by Belforbes Communication</p>
    </div>
  );
}
