import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import image from "../../../../mepic.png";
import DoneIcon from '@mui/icons-material/Done';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './style.css';
import FilmProduction from '../../../../FilmProduction.png'
import { useTheme } from '../../../../contextApi/themeContext';
// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Avatar } from '@mui/material';

const Testimonial = () => {
  const { theme } = useTheme();
  return (
    <div  className='lg:px-8 xl:px-32'>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        // pagination={{
        //   type: 'progressbar',
        // }}
        // navigation={true}

        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >

        <SwiperSlide >
          <div className='flex flex-col w-full' style={{ background: theme === 'dark' ? 'black' : 'white' }}>
            <div className='flex flex-col space-y-4 items-center justify-center'>
              <img src={image} alt="me" style={{ width: "6rem" }} />
              <h2 className='text-2xl font-bold ' style={{ color: theme === 'dark' ? 'white' : 'black' }}>
                Simplify For More Success
              </h2>
              <h4 className='text-gray-600 text-md text-center tracking-widest' style={{ color: theme === 'dark' ? 'white' : 'black' }}>
                Let MeBookMeta Replace Your Website
              </h4>

              <div className='w-full flex lg:flex-row flex-col items-center justify-center lg:p-36 p-4 lg:space-y-0 space-y-4 lg:space-x-40 spcace-x-0 '>
                <div className='flex flex-col space-y-4' style={{ color: theme === 'dark' ? 'white' : 'black' }}>
                  <div className='flex  items-center justify-start  text-sm tracking-widest capitalize'>
                    <DoneIcon fontSize='small' className='mr-2 text-green-600' /> Save Money and Time
                  </div>
                  <div className='flex  items-center justify-start text-sm tracking-widest capitalize'>
                    <DoneIcon fontSize='small' className='mr-2 text-green-600' /> Built-in Traffic
                  </div>
                  <div className='flex  items-center justify-start text-sm tracking-widest capitalize'>
                    <DoneIcon fontSize='small' className='mr-2 text-green-600' /> Pitch Alley (Full Version)
                  </div>
                  <div className='flex  items-center justify-start text-sm tracking-widest capitalize'>
                    <DoneIcon fontSize='small' className='mr-2 text-green-600' />MeBookMetaAI (included)
                  </div>
                </div>


                <div className='flex flex-col space-y-4' style={{ color: theme === 'dark' ? 'white' : 'black' }}>
                  <div className='flex  items-center justify-start  text-sm tracking-widest capitalize'>
                    <DoneIcon fontSize='small' className='mr-2 text-green-600' /> Easy Changes and Updates
                  </div>
                  <div className='flex  items-center justify-start  text-sm tracking-widest capitalize'>
                    <DoneIcon fontSize='small' className='mr-2 text-green-600' /> Intra-Site Promotion
                  </div>
                  <div className='flex  items-center justify-start  text-sm tracking-widest capitalize'>
                    <DoneIcon fontSize='small' className='mr-2 text-green-600' /> Ask the Universe (Full Version)
                  </div>
                  <div className='flex  items-center justify-start  text-sm tracking-widest capitalize'>
                    <DoneIcon fontSize='small' className='mr-2 text-green-600' /> MeBookMeta Chat (included)
                  </div>
                </div>

              </div>
              <div className='w-full flex flex-col space-y-4'>
                <h2 className='  text-center text-2xl' style={{ color: theme === 'dark' ? 'white' : 'black' }}>
                  Privacy Secure
                </h2>
                <p className='text-gray-600 text-md text-center' style={{ color: theme === 'dark' ? 'white' : 'black' }}>
                  We Will Never Sell or Share Your Private Information. Our Team Is Also Developing MeBookMeta Mobile App For <br />Your Smartphones and Tablets
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>


        <SwiperSlide >
          <div className='flex flex-col w-full' style={{ background: theme === 'dark' ? 'black' : 'white' }}>
            <div className='flex flex-col space-y-4 items-center justify-center '>
              <img src={image} alt="me" style={{ width: "6rem" }} />
              <h2 className='text-2xl font-bold ' style={{ color: theme === 'dark' ? 'white' : 'black' }}>
                MeBookMeta Can Help Take Your Project from a Drawing Board <br /> Idea to a Major Film Enterprise
              </h2>
              <div className='flex lg:flex-row flex-col items-center justify-center  lg:px-20 px-4 '>
                <div>
                  <img src={FilmProduction} alt="image" className />
                </div>
                <p className='text-gray-600 text-md tracking-widest' style={{ color: theme === 'dark' ? 'white' : 'black' }}>
                  Only by putting so many artistic minds and categories together on a single platform, the MeBookMeta Universe, can we help creators realize goals and dreams never thought possible. As a user, you can gain access to other creators, industry professionals, directors, producers, project developers and providers across diverse disciplines to chart your path to unimagined ventures and financial success.
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>


        <SwiperSlide >
          <div className='flex flex-col w-full' style={{ background: theme === 'dark' ? 'black' : 'white' }}>
            <div className='flex flex-col space-y-4 items-center justify-center '>
              <img src={image} alt="me" style={{ width: "6rem" }} />
              <h2 className='text-2xl font-bold ' style={{ color: theme === 'dark' ? 'white' : 'black' }}>
                The Investment That Makes Sense: <br /> You and What You Do
              </h2>


              <p className='text-gray-600 text-md tracking-widest' style={{ color: theme === 'dark' ? 'white' : 'black' }}>
                When you come to MeBookMeta with work or a project to share, we know that you have already invested your days, months or even years, your efforts, inspiration, sacrifice and indeed your very soul in the process of creating what you have brought to share. For a prudent $35/month investment in your work, you can be assured that you and what you do are instantly available to potential customers, partners, collaborators across the meta universe who can discover you and help deliver returns, commercial and industry related, for all your hard work.
              </p>

            </div>
          </div>
        </SwiperSlide>



      </Swiper>
    </div>
  );
}

export default Testimonial;
