import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./Reducer";
import {StateLoader} from './Storage/storage'

const stateLoader = new StateLoader();
let store = createStore(rootReducer, stateLoader.loadState(),applyMiddleware(thunk));

store.subscribe(() => {
    stateLoader.saveState(store.getState());
});



// const store = createStore(rootReducer, applyMiddleware(thunk) )

export default store