import React, { useEffect, useState } from 'react'
import image from "../../mepic.png";
import Picture3 from "../../Picture3.jpg";
import "../../Padd.css";
import PaddCarou from "../../PaddCarou";
import PaddFooter from "../../PaddFooter";
import welcome from "../../components/voiceMessage/WelcomeVoice.mp3";
import { useNavigate } from "react-router-dom";
import useSound from "use-sound";
import Languages from "../../Languages.jpg";
import Paddcar from "../../Paddcar";
import FilmIMG2 from '../../FilmIMG2.jpg'
import { Box } from '@mui/material';
import NewBtn from '../../components/componentsB/btn/newBtn/NewBtn';
import { useDispatch } from 'react-redux';
import { callPopUp } from '../../Action';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import RedeemIcon from '@mui/icons-material/Redeem';
import NewCrousel from '../../NewCrousel/NewCrousel';
import LandingNavNew from './components/landingNavNew/LandingNavNew';
import PitchCraousel from '../../Component new/PitchCrousel/PitchCraousel';
import MyAskCraousel from '../../components/MyAskCraousel/MyAskCraousel';
import sptLight from '../../assets/SPOTlights.png'
import { array1, array2 } from './asset';
import MePic from '../../mepic.png';
import HeadingTag from '../../components/componentsB/text/heading/HeadingTag';


const Loading = () => {
  return (
    <div className="d-flex text-white align-items-center justify-content-center flex-column" style={{ minHeight: '100vh' }}>
        <img src={MePic} alt="image" width="300" height={300} />
      <div>
        <marquee behavior="scroll" direction="left">
          <h2>The Global Media Marketplace</h2>
        </marquee>
      </div>
    </div>
  );
};




export default function LandingPage() {
  const [loading, setLoading] = useState(()=>{
    if(sessionStorage.getItem('landing_count') < 1){
      return true
    }else{
      return false
    }
  })
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [play, { stop }] = useSound(welcome);
  const handleSignUp = (query) => {
    localStorage.setItem('userType', JSON.stringify(query))
    navigate('/Signup')
  }
  const handleDonate = () => {
    dispatch(callPopUp({ type: 'DonationForm' }))
    // dispatch(callPopUp({ type: 'SuccessDonation' }))
  }

  const handleSponsor = () => {
    dispatch(callPopUp({ type: "SponsershipForm" }))
  }

useEffect(()=>{
  const clr = setTimeout(()=>{
    setLoading(false)
    sessionStorage.setItem('landing_count', JSON.stringify(1))
  },8300)
  return () => clearTimeout(clr)
},[])

  useEffect(() => {
    // const delay = 4000; // 2 seconds delay
    // const timeoutId = setTimeout(() => {
    //   setLoading(false);
    // }, delay);

    if (window?.location?.search?.includes('?success=true')) {
      // console.log('donation success')
      dispatch(callPopUp({ type: 'SuccessDonation' }))

    }
    if (window?.location?.search?.includes('?sponsor=') && !window?.location?.search?.includes('?sponsor=false')) {
      dispatch(callPopUp({ type: 'SuccessSponsor' }))


    }
    // return () => clearTimeout(timeoutId);
  }, [])



  if(loading){
    return <Loading />
  }else{
    return (
      <>
        <div className="bgpadd text-light" >
              <LandingNavNew />
              <div className="container">
                <div className="row pb-5 pt-5 mb-3" id='topFirst'>
  
  
  
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-5 text-center ">
  
                    <div
                      className=" tagname text-center"
                      style={{ fontFamily: "Times New Roman", fontSize: "200%" }}
                    >
                      <span style={{ color: "rgb(187 11 11)" }}>M</span>
                      <span style={{ color: "#0a0a8a" }}>eBook</span>
                      <span style={{ color: "rgb(187 11 11)" }}>M</span>
                      <span style={{ color: "#0a0a8a" }}>eta</span>
                    </div>
                    <div className="d-flex justify-content-center">
                      <img
                        src={image}
                        alt="/"
                        style={{ width: "32%", height: "27%", margin: "5px 0px" }}
                      />
                    </div>
                    <h4 style={{ fontSize: "170%" }}>
                      <i>"It's All About You"</i>
                    </h4>
                    <span style={{ fontSize: "120%" }}>Share Your Work </span> <br />
                    <span style={{ fontSize: "120%" }}>
                      {" "}
                      Build Your Following{" "}
                    </span>{" "}
                    <br />
                    <span style={{ fontSize: "120%" }}>
                      {/* Discover Art, Books and Projects */}
                      Explore Art, in Every Form, Everywhere
                    </span>
                    <br />
                    <button
                      className=" btnClass mx-auto btn explorebtn text-white profile-card__button button--orange"
                      style={{ fontWeight: "bold", borderRadius: "5px" }}
                      onClick={() => {
                        play();
                        navigate("new");
                      }}
                    >
                      Explore now
                    </button>
                    <h6 style={{ fontSize: '.8rem' }}>To get the best MeBookMeta experience, we want to know a bit about you!</h6>
                    <div className=" d-flex justify-content-center">
  
                      <div className="mx-2">
  
                        <NewBtn
                          text={"I am a Creative"} className={'creatVist'} style={{}} onClick={() => handleSignUp('Creative')} />
  
                      </div>
                      <div className="mx-2">
  
                        <NewBtn
                          text={"I am a Visitor"} className={'creatVist'} style={{}} onClick={() => handleSignUp('Visitor')} />
  
                      </div>
                    </div>
                  </div>
  
  
  
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-5 d-flex flex-column justify-content-between align-items-center">
                    <video style={{ width: '100%', height: "80%" }} controlsList='nodownload' controls loop src='https://mebook-work.s3.ap-south-1.amazonaws.com/1688492047308-Mebookmeta+video.mp4' />
                    <div className=' d-flex align-items-center justify-content-center' style={{ width: '90%', gap: "8px" }}>
                      <NewBtn
                        text={"Donate"} className={'creatVist'} style={{}} onClick={handleDonate} comp={<MonetizationOnIcon sx={{ marginLeft: '1rem' }} />} />
                      <NewBtn
                        text={"Sponsorships"} className={'creatVist'} style={{}} onClick={handleSponsor} comp={<RedeemIcon sx={{ marginLeft: '1rem' }} />} />
                    </div>
                  </div>
                </div>
                <div>
                  <h4 className="text-center" style={{ fontSize: "150%" }}>Zoom Meeting Every Tuesday for Potential, New and Existing MeBookMeta Users</h4>
  
  
                  <h4 className="text-center">
                    <a href='https://us06web.zoom.us/j/89707399774?pwd=c0VYVnZUQVNLZGlGUTVKUEF4Ny9CZz09' target='_blank'>Zoom Meeting Link</a>
                  </h4>
                </div>
  
                <div className=" my-5">
                  <span className="text-center">
                    <marquee style={{ fontWeight: "bold", fontSize: "100%", paddingBottom: "7px", wordSpacing: "3px" }}>
                      <span style={{ color: "rgb(187 11 11)" }}>M</span>
                      <span style={{ color: "#0a0a8a" }}>eBook</span>
                      <span style={{ color: "rgb(187 11 11)" }}>M</span>
                      <span style={{ color: "#0a0a8a" }}>eta</span> Mobile Application
                      will be soon available in Android & IOS Stores.
                    </marquee>
  
  
                    <div className='col-md-12 col-sm-10 d-flex flex-column align-items-center justify-content-center'>
                      <h3>Introductory video for Creatives</h3>
                      <video width='360' controls src='https://mebook-img.s3.eu-west-1.amazonaws.com/1696969151821-MeBookMeta+Sign+Up+Video+V1.1.mp4' />
                    </div>
  
  
                    <h1 style={{ fontWeight: "bold", fontSize: "150%" }} id='tgmm'>
                      The Global Media Marketplace!
                    </h1>
                    <p style={{ fontSize: "105%" }} className="my-3">
                      MeBookMeta creates a sustainable community of creators,
                      collaborators, mentors, influencers, providers, consumers,
                      viewers, readers and customers – a true marketplace
                    </p>
  
                    <marquee style={{ fontWeight: "bold", fontSize: "100%", paddingBottom: "7px", wordSpacing: "3px" }}>
                      <span style={{ color: "rgb(187 11 11)" }}>M</span>
                      <span style={{ color: "#0a0a8a" }}>eBook</span>
                      <span style={{ color: "rgb(187 11 11)" }}>M</span>
                      <span style={{ color: "#0a0a8a" }}>eta</span> Multi Language coming soon
                    </marquee>
                  </span>
  
                  <div className="my-5">
  
                    {/* <div className="row  darkssSky">
              <span className="col-12 col-sm-12 col-md-12 col-lg-6">
                <img src={Picture4} alt="/" className="image" />
              </span>
              <span className="col-12 col-sm-12 col-md-12 col-lg-6 px-3">
                <h3 style={{ fontSize: "25px", marginTop: "-5px" }}>
                  How Does It Work?
                </h3>
                <p style={{ fontSize: "90%" }}>
                  MeBookMeta is the only place on Earth where you will have
                  access to so many other serious, professional and experienced
                  content creators. Sell your work directly to your audience,
                  connect with new friends and collaborators, and easily find
                  the answers to all your larger success-related questions. When
                  you share your work and ideas, you will become part of the
                  change that will revolutionize the global media industry!
                </p>
              </span>
            </div>
  
            <div className="row  darkssblack">
              <span className="col-12 col-sm-12 col-md-12 col-lg-6 px-3 ">
                <h3 style={{ fontSize: "25px" }}>Boost Your Connectivity</h3>
                <p style={{ fontSize: "90%" }}>
                  Upon enrollment, users will provide the background for “Your
                  Story,” and together with users created “Work Profile,” users
                  will provide the marketplace with a unique perspective, which
                  will include a video or written “Me Pitch,” describing “who
                  you are,” “what you do,” and “why you do it.”
                </p>
              </span>
              <span className="col-12 col-sm-12 col-md-12 col-lg-6">
                <img src={Picture2} alt="/" className="image" />
              </span>
            </div>
  
            <div className="row my-2">
              <span className="col-12 col-sm-12 col-md-12 col-lg-6">
                <img src={dollar} alt="/" className="image" />
              </span>
              <span
                className="col-12 col-sm-12 col-md-12 col-lg-6 px-3 "
                style={{ marginTop: "-4px" }}
              >
                <h3 style={{ fontSize: "25px" }}>
                  Save Your Money and it’s the Best Bank for the Buck
                </h3>
                <p style={{ fontSize: "90%" }}>
                  Includes On-Board Website Simple User-Maintained Dashboard
                  Easy Real-Time Profile Updates User-Focused Global Traffic
                  Collaboration and Networking Opportunities Algorithmic On-Site
                  Recommendations Add-Ons, including Payment Portal, Vetting,
                  Deep Searches Projected Cost for MeBookMeta Standard Profile :
                  Web App $35 A Monthly.
                </p>
              </span>
            </div> */}
  
  
                    <div className="row my-3">
                      <span className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <h3 style={{ fontSize: "25px" }}>
                          The Greatest Challenge for Creatives
                        </h3>
                        <p style={{ fontSize: "90%" }}>
                          It’s not content creation. Creating is literally what creators
                          do. No, the biggest challenge for creators is Sharing Work,
                          and specifically, sharing work with ideal audiences, creating
                          a following (a unique brand) and becoming profitable.
                        </p>
                      </span>
                      <span className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <img src={Picture3} alt="/" className="image" />
                      </span>
                    </div>
  
                    <div className="row my-3" style={{ textAlign: "center" }}>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 ">
                        <img src={Languages} alt="/" className="image" />
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                        <div>
                        <h3 style={{ fontSize: "25px", marginTop: "10px" }}>
                          MeBookMeta Universe – Languages
                        </h3>
                        </div>

                        <div>
                        <p style={{ fontSize: "90%" }}>
                          Within 90 days, MeBookMeta will be providing for additional
                          languages, including Spanish, French, German, Chinese, Arabic,
                          African languages, Dutch and Japanese with others to follow. Share, sell
                          and collaborate on your work or projects in an expanding
                          global marketplace.
                        </p>
                        </div>
                      </div>
                    </div>
  
  
                    <div className='row my-5'>
  
                      <div className='text-center mb-5 ' id='jbltq'>
                        <div className="d-flex justify-content-center" id='offers'>
                          <img
                            src={image}
                            alt="/"
                            style={{ width: "20%", height: "20%", margin: "5px 0px" }}
                          />
                        </div>
                        <h4 className="size">
                          <b>
                            MeBookMeta offers you the opportunity to bring your next big
                            story, song, creation or project to the Global Media
                            Marketplace. Bring your work and ideas to a revolution that
                            will become the world’s greatest talent pool, encompassing
                            not only the Hollywood movie and music industry, but a
                            global network of professionals who will redefine the
                            paradigm and create new forms of art unexplored until now.
                          </b>
                        </h4>
                      </div>
  
                      {/* <span className="my-2 col-12 col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-center">
                <img
                  src={Benefits}
                  alt="/"
                  className="EbookMeta_Studios"
                  style={{ width: "74%", borderRadius: "10px" }}
                  onClick={() => navigate(`Search/12`)}
                />
              </span> */}
                      {/* <span className="my-2 col-12 col-sm-12 col-md-6 col-lg-5  EbookStudioText">
                <span className="d-flex EbookStudio">
                  <h4 style={{ fontSize: "23px" }}>
                    <b>MeBookMeta Film</b>
                  </h4>
                </span>
                <h5 style={{ fontSize: "100%" }} className="my-3">
                  <b>Visit MeBookMeta Often and Watch For</b>
                </h5>
                <p style={{ fontSize: "90%" }}>
                  Additional benefits to Standard and Enhanced Profiles, a new
                  Premium Profile option, and Innovative Add-Ons and tools that
                  will help customize your brand to your unique targeted
                  audience.
                </p>
              </span> */}
  
  
  
                      <span className='my-2 col-12 col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-center'>
                        <img src={FilmIMG2} alt="/" style={{ width: '74%', borderRadius: '10px' }} className='EbookMeta_Studios' onClick={() => navigate(`Search/12`)} />
                      </span>
                      <span className='my-2 col-12 col-sm-12 col-md-6 col-lg-5  EbookStudioText'>
                        <span className='d-flex EbookStudio'>
                          <h4 style={{ fontSize: "23px" }}><b>MeBookMeta AI</b></h4>
                        </span>
                        <h5 style={{ fontSize: "100%" }} className="my-3">
                          <b>MeBookMeta Bleeding Edge Technologies</b>
                        </h5>
                        <p style={{ fontSize: "90%" }}>
                          With our newest and best AI Features, you can elevate your
                          work to new heights! Our advanced search capabilities will
                          allow you to find the most relevant content and collaborators
                          with ease, while language translation tools will enable
                          seamless communication across borders and cultures. We will
                          always seek out and create cutting-edge user tools to ensure
                          your promotions are both persuasive and effective.
                        </p>
                      </span>
  
                      {/* <span className="my-2 col-12 col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-center">
                <img
                  src={Studio}
                  alt="/"
                  className="EbookMeta_Studios"
                  style={{ width: "74%", borderRadius: "10px" }}
                  onClick={() => navigate(`Search/12`)}
                />
              </span>
              <span className='my-2 col-12 col-sm-12 col-md-6 col-lg-5 EbookStudioText'>
                <span className='d-flex EbookStudio'>
                  <h4 style={{ fontSize: "23px" }}><b>MeBookMeta Media</b></h4>
                </span>
                <h5 style={{ fontSize: "100%" }} className="my-3">
                  <b>Your original story could be the next big hit</b>
                </h5>
                <p style={{ fontSize: "90%" }}>
                  Mebook Studios discovers untapped, unsigned, and talented
                  creators on MeBookMeta and connects them to global multi-media
                  entertainment companies.
                </p>
              </span> */}
                    </div>
  
                    {/* EbookMeta Studios Film */}
  
                    {/* EbookMeta Music */}
  
                    {/* <div className="row d-flex flex-column justify-content-center align-items-center mb-3">
              <div className="d-flex justify-content-center mb-5 " id="musicStudios">
                <img
                  src={image}
                  alt="/"
                  style={{ width: "35%", height: "35%", margin: "5px 0px" }}
                />
              </div>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: {
                    xl: "row",
                    lg: "row",
                    md: "column",
                    sm: "column",
                    xs: "column",
                  },
                }}
              >
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-7 d-flex justify-content-center align-items-center mx-auto">
                  <span className="imga shadow-lg mx-auto">
                    <span className="d-flex justify-content-center">
                      <img className="img1" src={Justin} alt="/" />
                      <br />
                    </span>
                    <span>
                      <img className="img2" src={ChrisBrown} alt="/" />
                      <br />
                      <img className="img3" src={PostMalone} alt="/" />
                    </span>
                  </span>
                </div>
  
  
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5 d-flex justify-content-center align-items-center mb-5">
                  <span className=' p-3 EbookStudioText mx-auto mb-5'>
                    <h4 style={{ fontSize: "29px" }}><b>MeBookMeta Music Studios</b></h4>
  
                    <h5 style={{ fontSize: "20px" }} className='my-3'><b>Uploading samples of your Original or Latest song on this platform could help you become one of Billboard Magazine top musical artists over the next year.</b></h5>
                    <p style={{ fontSize: "18px" }}>MeBookMeta Music Studios discovers untapped, unsigned and talented musical performers and connects them to global multi-media entertainment players, personalities and companies.</p>
                  </span>
                </div>
              </Box>
            </div> */}
  
                    {/* EbookMeta Music */}
  
  
  
                    {/*  */}
                    <div style={{ marginBottom: "5rem" }}>
                      <h1 style={{ color: "white", textAlign: "center" }}>MeBookMeta Audiences</h1>
                      <NewCrousel array={array1} />
                    </div>
                    <div>
                      <h1 style={{ color: "white", textAlign: "center" }}>MeBookMeta Creatives</h1>
                      <NewCrousel array={array2} />
                    </div>
                    {/*  */}
  
                    {/*   */}
                    {/* <Stack direction={"row"} spacing={2} sx={{ justifyContent:"center"}} >
              <NewBtn
                text={"MeBookMeta Identities"} className={'creatVist'} onClick={() => navigate("/identities")}
              />
              <NewBtn
                text={"MeBookMeta Creatives"} className={'creatVist'} style={{}} onClick={() => navigate("/creatives")}
              />
              </Stack> */}
                    {/*  */}
  
                    <div className='d-flex justify-content-center align-items-center col-md-12 col-sm-6'>
                      <div className='col-md-12 mt-5'>
                        <marquee style={{ fontWeight: "bold", fontFamily: "italic", fontSize: "3rem", color: "red", paddingBottom: "7px", wordSpacing: "3px" }}>
                          MEBOOKMETA PITCH ALLEY PREVIEW
                        </marquee>
                      </div>
                    </div>
  
                    <div className='text-center m-0 p-0 col-12 d-flex' style={{ position: '' }}>
                      <div className='col-12'>
                        <div className='d-flex align-items-center justify-content-center '>
                          <Box
                            component="img"
                            sx={{
                              height: 300,
                              width: 500,
                              maxHeight: { xs: 233, md: 350 },
                              maxWidth: { xs: 350, md: 500 },
                            }}
                            alt="left_bulb"
                            src={sptLight}
                          />
                          {/* <Box sx={{ display: { xs: 'block', sm: 'none', md: 'flex', } }}>
                    <img id='pitchAlley_bgVdeo' src={sptLight} alt="left_bulb" />
                  </Box> */}
                          <div style={{ position: 'absolute', zIndex: "1", padding: "1rem", opacity: '0.9' }}>
                            <div style={{ top: "0%", left: "50%" }}><PitchCraousel /></div>
                          </div>
                        </div>
                      </div>
                    </div>
  
                    <div className='bgpadd' style={{ marginTop: "10rem" }}>
                      <Paddcar />
                    </div>
  
                    {/* myAsk Carousel */}
                    <div className='d-flex justify-content-center align-items-center col-md-12 col-sm-6'>
                      <div className='col-md-12 mt-5'>
                        <marquee style={{ fontWeight: "bold", fontFamily: "italic", fontSize: "3rem", color: "red", paddingBottom: "7px", wordSpacing: "3px" }}>
                          ASK THE MEBOOKMETA UNIVERSE
                        </marquee>
                      </div>
                    </div>
  
                    <div className='text-center m-0 p-0 col-12 d-flex' style={{ position: '' }}>
                      <div className='col-12'>
                        <div className='d-flex align-items-center justify-content-center '>
                          <Box
                            component="video"
                            autoPlay loop muted width={500} height={340}
                            sx={{
                              height: 300,
                              width: 500,
                              maxHeight: { xs: 233, md: 350 },
                              maxWidth: { xs: 350, md: 500 },
                            }}
                            src={"https://mebook-img.s3.eu-west-1.amazonaws.com/1703593695843-space_-_67382+%28540p%29+%281%29.mp4"} type="video/mp4"
                          />
                          {/* /////////////////////////// */}
                          {/* <Box sx={{ display: { xs: "block", md: "flex", width: { xs: "300px" } } }} >
                    <video autoPlay loop muted width={500} height={340}>
                      <source src={"https://mebook-img.s3.eu-west-1.amazonaws.com/1703593695843-space_-_67382+%28540p%29+%281%29.mp4"} type="video/mp4" />
                    </video>
                  </Box> */}
                          <div style={{ position: 'absolute', zIndex: "1", padding: "1rem", opacity: '0.6' }}>
                            <div style={{ top: "0%", left: "50%" }}><MyAskCraousel /></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* myAsk Carousel */}
  
                    {/* Box */}
  
                    <div className="my-5" style={{ height: "150px" }} id='trendingWork'>
                      <h5
                        className="text-center my-5 fs-2"
                        style={{ fontWeight: "bold" }}
                      >
                        Top Trending Works
                      </h5>
                      <PaddCarou />
                    </div>
                    <div className="headfooterpad">
                      <div className=" d-flex justify-content-center paddfooter">
                        <PaddFooter />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </>
  
    );
  }
}

// lazyloading code
{/* <div className="col-12 d-flex align-items-center justify-content-center flex-column" style={{ minHeight: '100vh' }}>
            <div id="handwriting">
                <span style={{ color: "rgb(187 11 11)" }}>M</span>
                <span style={{ color: "#0a0a8a" }}>eBook</span>
                <span style={{ color: "rgb(187 11 11)" }}>M</span>
                <span style={{ color: "#0a0a8a" }}>eta</span>
                <br/>
            </div>
            <HeadingTag className="text-white" level={3}>
            The Global Media Market Place
            </HeadingTag>
          </div> */}