

export const errorPage = (state = false, action) => {
    switch (action.type) {
        case "GOT_ERROR":
            return true
        case "NO_ERROR":
            return false
        default:
            return state
    }
}
