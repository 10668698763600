const initialState = {
    current: '',
    next: '',
    play: false,
    stateId: []
}

const MyAskCraousel = (state = initialState, action) => {
    function isLast(e) {
        if (e === state.stateId.length) {
            return state.stateId[0]
        } else if (e < 0) {
            return state.stateId[state.stateId.length - 1]

        } else {
            return state.stateId[e]
        }
    }

    switch (action.type) {
        case 'SET_MYASK_CRAOUSEL_DATA':
            console.log("my ask action.payload   ", action.payload )
            let { filteredByCat, resultWithUserId } = action.payload;
            console.log(filteredByCat, resultWithUserId)
            if (filteredByCat?.length>0) {
                resultWithUserId = resultWithUserId?.filter((item) => filteredByCat?.includes(item?.userId?._id))
            }

            const MyASkArray = resultWithUserId?.filter(
                (i) =>
                    i?.askFile !== "" &&
                    i?.askFile?.split(".")[
                    i?.askFile?.split(".")?.length - 1
                    ] !== ("JPG" || "jpg")
            )?.map((item) => {
                return(
                    {askFile:item.askFile,userId:item.userId._id,name:item.userId.name}

                )
            })

            return {
                ...state, stateId: MyASkArray,
                current: MyASkArray[0]
            }


        case 'SET_NEXT_TO_CURRENT_MYASK_CRAOUSEL':
            const findNext = state.stateId.findIndex((i) => i === state.current) + 1

            return {
                ...state, current: isLast(findNext)
            }
        case 'SET_PREV_TO_CURRENT_MYASK_CRAOUSEL':
            const findPrev = state.stateId.findIndex((i) => i === state.current) - 1

            return {
                ...state, current: isLast(findPrev)
            }

        case 'SET_TRUE_MYASK_CRAOUSEL':
            return {
                ...state, play: true
            }
        case 'SET_FALSE_MYASK_CRAOUSEL_DATA':
            return {
                ...state, play: false
            }
        case 'SET_MYASK_CRAOUSEL_CLEAR':
            return {
                ...state, play: false
            }
        default:
            return state
    }
}
export default MyAskCraousel