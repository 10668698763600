import React, { useEffect, useRef, useState } from "react";
import "./GlobalPop.css";
import Modal from "@mui/material/Modal";
import CloseIcon from '@mui/icons-material/Close';
import payment from '../../voiceMessage/HomePageVoice.mp3'
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { newButton } from "../../../Component new/background";
import axios from "axios";
import { toast } from "react-toastify";
import { SERVER } from "../../../server/server";
import { addMePost, aiAllChat, callPopUp, getAuth, handleFollow, userDashboardDetail, userProfile } from "../../../Action";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton, Typography } from '@mui/material';
import PaymentSuccess from "../../../Component new/PaymentSuccess/PaymentSuccess";
import useSound from "use-sound";
import { DeleteWork } from "../../../Component new/userWork/WorkCard";
import { DeletePitch } from "../../../Component new/WritePitch";
import { getImgURL } from "../../../util/getImgUrl";
import MeCircularProgress from "../../componentsC/meCircularProgress/MeCircularProgress";
import MuiAccordian from "./MuiAccordian";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import NewBtn from '../../componentsB/btn/newBtn/NewBtn'
import '../../PostSection/CreatePost/CreatePost.css'
import PermMediaIcon from '@mui/icons-material/PermMedia';

import Author from './new Images/Author.jpg';
import Writer from './new Images/Writer.jpg';
import Music from './new Images/Music.jpg';
import Sound from './new Images/Sound.jpg';
import MusicVideo from './new Images/Music Video.jpg';
import Film from './new Images/Film.jpg';
import Television from './new Images/Television.jpg';
import Print from './new Images/Media.jpg';
import VArts from './new Images/VArts.jpg';
import PArts from './new Images/PArts.jpg';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import CancelIcon from '@mui/icons-material/Cancel';
import { useOwnIdentity } from "../../../Hooks/useOwnIdentity";
import { FormControl, MenuItem, TextField } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import { BackGround } from "../../../Component new/background";
import { SOCKET } from "../../../App";
import { DeletePostByUserId } from "../../PostSection/PostCard/PostCard";
import { onUplProg } from "../../../assets/common/onUplProg";
import UplProgBar from "../../componentsC/uplProgBar/UplProgBar";
import { Select } from "antd";
import { descriptionFunction } from "../../../assets/descriptionFun";
import { FiDelete } from "react-icons/fi";

const styles = {
  position: "absolute",
  top: "10%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  color: "white",
  boxShadow: 24,
  borderRadius: "10px",
  padding: "22px 22px",
  fontSize: "2vmin",
};
export default function GlobalPop({ children }) {
  const [stage, setStage] = useState("login");
  const STORE = useSelector((state) => state?.callPopUp);
  const dispatch = useDispatch();
  const [popUp, setPopUp] = useState(false);
  const [play, { stop }] = useSound(payment);
  const popType = useSelector((state) => state.callPopUp.PopType);


  useEffect(() => {
    setPopUp(STORE?.showPop);
    setStage(popType);
  }, [STORE, popType]);

  //  useEffect(()=>{
  //     setStage(STORE?.callPopUp.PopType)
  //  },[STORE?.callPopUp.PopType])

  const handleModalClose = (type) => {
    if (type == "payment") {
      dispatch({ type: "REMOVE_POPUP" });
      return play()
    }
    dispatch({ type: "REMOVE_POPUP" });
  }

  var obj = {
    login: <SignUp handleModalClose={handleModalClose} />,
    signin: <SignIn handleModalClose={handleModalClose} />,
    SuccessPayment: <SuccessPayment handleModalClose={handleModalClose} />,
    makeSubscription: <MakeSubscription handleModalClose={handleModalClose} />,
    CouponCheck: <CouponCheck handleModalClose={handleModalClose} />,
    workDelete: <WorkDelete handleModalClose={handleModalClose} />,
    pitchDelete: <PitchDelete handleModalClose={handleModalClose} />,
    CancelSubscription: <CancelSubscription handleModalClose={handleModalClose} />,
    ShowProfile: <ShowProfile handleModalClose={handleModalClose} />,
    ShowPitch: <ShowPitch handleModalClose={handleModalClose} />,
    ShowFollowers: <ShowFollowers handleModalClose={handleModalClose} />,
    ShowFPTags: <ShowFPTags handleModalClose={handleModalClose} />,
    VisitorCatSelect: <VisitorCatSelect handleModalClose={handleModalClose} />,
    // askDelete: <AskDelete handleModalClose={handleModalClose} handleDelte={STORE?.callPopUp.handleDelete} askToDelete={STORE?.callPopUp.askToDelete} />,
    DonationForm: <DonationForm handleModalClose={handleModalClose} />,
    SuccessDonation: <SuccessDonation handleModalClose={handleModalClose} />,
    SuccessSponsor: <SuccessDonation handleModalClose={handleModalClose} />,
    SponsershipForm: <DonationForm handleModalClose={handleModalClose} />,
    sponsorCheck: <SponsorCheckModal handleModalClose={handleModalClose} />,
    ShowProfileError: <ShowProfileError handleModalClose={handleModalClose} />,
    CreatePost: <CreatePost handleModalClose={handleModalClose} />,
    SeeMorePost: <SeeMorePost handleModalClose={handleModalClose} />,
    DeletePost: <DeletePost handleModalClose={handleModalClose} />,
    AIModalOne: <AIModals handleModalClose={handleModalClose} />,
    AIModalTwo: <AIModals handleModalClose={handleModalClose} />,
    AskMeAi: <AskMeAi handleModalClose={handleModalClose} />,
  };

  return (
    <>
      <div>
        {children && children}
        <Modal
          open={popUp}
          // onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <motion.div
            initial={{ scale: 1, opacity: 0 }} // Set initial scale to 1 and opacity to 0 for a fade-down effect
            animate={{ scale: 1, opacity: 1 }} // Animate scale to 1 and opacity to 1 for normal visibility
            exit={{ scale: 1, opacity: 0 }} // Animate scale to 1 and opacity to 0 for a fade-down effect when closing
            transition={{ duration: 0.6 }}

          >
            {obj[stage]}
          </motion.div>
        </Modal>
      </div>
    </>
  );
}

// sign Up popUp Box

function SignUp({ handleModalClose }) {
  const dispatch = useDispatch();
  function handleModalClose() {
    dispatch({ type: "REMOVE_POPUP" });
  }

  return (
    <Box sx={{ ...styles, background: "#2f2841" }}>
      <div className='text-desc mx-auto my-auto' style={{ position: "absolute", right: 0, top: 0, cursor: "pointer" }}>
        <CloseIcon sx={{ ...newButton, fontSize: '2rem', borderRadius: '50%' }} onClick={() => handleModalClose()} />
      </div>
      <div>
        <Typography id="modal-modal-title">
          <p>Please Sign Up to Enjoy The Uninterrupted Services..</p>
          <Link to="/Signup" style={{ textDecoration: "none" }}>
            {" "}
            <Button
              variant="contained"
              size="large"
              sx={{
                ...newButton,
                py: "6px",
                width: "6rem",
                fontSize: "75%",
              }}
              onClick={handleModalClose}
            >
              Sign Up
            </Button>
          </Link>
        </Typography>
      </div>
    </Box>
  );
}

function SignIn({ handleModalClose }) {
  const dispatch = useDispatch();
  function handleModalClose() {
    dispatch({ type: "REMOVE_POPUP" });
  }

  return (
    <Box sx={{ ...styles, background: "#2f2841", top: '50%' }}>
      <div className='text-desc mx-auto my-auto' style={{ position: "absolute", right: 0, top: 0, cursor: "pointer" }}>
        <CloseIcon sx={{ ...newButton, fontSize: '2rem', borderRadius: '50%' }} onClick={() => handleModalClose()} />
      </div>
      <div></div>
      <div>
        <Typography id="modal-modal-title">
          <p>Please SignIn To Enjoy The Uninterrupted services</p>
          <Link to="/SignIn" style={{ textDecoration: "none" }}>
            {" "}
            <Button
              variant="contained"
              size="large"
              sx={{
                ...newButton,
                py: "6px",
                width: "6rem",
                fontSize: "75%",
              }}
              onClick={handleModalClose}
            >
              Sign In
            </Button>
          </Link>
        </Typography>
      </div>
    </Box>
  );
}

function SuccessPayment({ handleModalClose }) {
  return (
    <PaymentSuccess handleModalClose={handleModalClose} />
  );
}

function WorkDelete({ handleModalClose }) {
  return (
    <DeleteWork handleModalClose={handleModalClose} />
  );
}

function PitchDelete({ handleModalClose }) {
  return (
    <DeletePitch handleModalClose={handleModalClose} />
  );
}

function MakeSubscription({ handleModalClose }) {
  return (
    <Box sx={{ ...styles, background: "#2f2841", padding: "20px" }}>
      <div className='text-desc mx-auto my-auto' style={{ position: "absolute", right: 0, top: 0, cursor: "pointer" }}>
        <CloseIcon sx={{ ...newButton, fontSize: '2rem', borderRadius: '50%' }} onClick={() => handleModalClose()} />
      </div>
      <div></div>
      <div>
        <Typography id="modal-modal-title">
          <p>Please Make A Subscription To Enjoy Our Services...</p>
        </Typography>
      </div>
    </Box>
  );
}
function CouponCheck({ handleModalClose }) {
  const [code, setCode] = useState({ couponCode: '' })
  const [backCoupon, setBackCoupon] = useState('')
  // console.log('backCoupon',backCoupon)
  const handleChange = (e) => {
    setCode({
      ...code,
      [e.target.name]: e.target.value,
    });
  }
  function handlePayment(type, couponObj) {
    // console.log('chal raha')
    const paymentApi = `${SERVER}/payment/checkout`
    const method = {
      1: "standard",
      2: "enhanced"
    }
    // console.log('test',{ product: method[type],couponObj:backCoupon.find((item)=>item?.code==code?.couponCode)?.coupon })
    axios.post(paymentApi, { product: method[type], couponObj: backCoupon.find((item) => item?.code == code?.couponCode)?.coupon },
      {
        headers: { Authorization: `Bearer ${getAuth()}` }
      }).then((response) => {
        if (response.data.errorCode == 200) {
          // console.log("response", response?.data, response?.data?.sessionurl)
          window.location.href = response?.data?.sessionurl
        } else {
          toast.error(response.data?.message)
          // console.log("response", response)
        }
      }).catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    axios.get(`https://api.stripe.com/v1/promotion_codes`,
      {
        headers: { Authorization: `Bearer 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` }
      })
      .then((res) => {
        // console.log(res.data)
        setBackCoupon(res?.data?.data)
      })
  }, [])
  const checkCoupon = () => {
    // console.log('backCoupon?.map((item)=>item?.code)', backCoupon.find((item) => item?.code == code?.couponCode && item?.active))


    if (code?.couponCode == '' || code?.couponCode === backCoupon.find((item) => item?.code == code?.couponCode && item?.active)?.code && backCoupon.find((item) => item?.code == code?.couponCode && item?.active).active === true) {
      handlePayment(1, backCoupon.find((item) => item?.code == code?.couponCode && item?.active))

    } else if (code?.couponCode !== backCoupon.find((item) => item?.code == code?.couponCode && item?.active)?.code) {
      toast.error("Invalid Coupon Code")
    } else if (!backCoupon.find((item) => item?.code == code?.couponCode && item?.active).active) {
      toast.error("Coupon Code Expires")

    }

  }
  return (
    <Box sx={{ ...styles, background: "#2f2841", top: '50%', width: "23rem", height: "20rem", display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
      <div className='text-desc mx-auto my-auto' style={{ position: "absolute", right: 0, top: 0, cursor: "pointer" }}>
        <CloseIcon sx={{ ...newButton, fontSize: '2rem', borderRadius: '50%' }} onClick={() => handleModalClose()} />
      </div>
      <div></div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '12px', width: "350px", height: "250px" }}>
        <Typography id="modal-modal-title" align="center">
          Do You Have a Coupon Code?
        </Typography>
        <input name='couponCode' value={code?.couponCode} type="text" placeholder="Enter Coupon Code" onChange={handleChange} style={{ width: '15rem', height: '2rem', textAlign: 'center', margin: '15px auto' }} />
        <Typography id="modal-modal-title" align="center">
          No Coupon Code? <br /> Tap the box below to begin your free trial
        </Typography>
        <Button
          variant="contained"
          size="medium"
          // sx={{
          //   ...newButton,
          //   py: "6px",
          //   width: "100%",
          //   fontSize: "75%",
          //   my:2
          // }}
          className="mx-auto profile-card__button button--orange"
          onClick={checkCoupon}
        >START YOUR FREE TWO-WEEK TRIAL</Button>
      </div>
    </Box>
  )
}



function CancelSubscription({ handleModalClose }) {
  const STORE = useSelector((state) => state);
  const cancelApi = `${SERVER}/payment/stripe/cancelSubscription`
  const dispatch = useDispatch()
  const cancelSubs = async () => {
    // alert('meraj')
    axios.post(cancelApi, {}, {
      headers: { Authorization: `Bearer ${await getAuth()}` }
    })
      .then((response) => {
        console.log('response', response)
        if (response?.data?.errorCode == 404) {
          toast.error(response?.data?.message, { position: 'top-center', autoClose: 2000, pauseOnHover: false })
        } else if (response?.data?.errorCode == 200) {
          toast.success(response?.data?.message, { position: 'top-center', autoClose: 2000, pauseOnHover: false })
          dispatch(userDashboardDetail());
        }

      })
      .catch((error) => console.log('error in cancel subscription', error))
  }
  // otp verification

  const [openInput, setOpenInput] = useState(false)
  const [otp, setOtp] = useState("")
  const [flag, setFlag] = useState(false)
  const [CircularProgress, setCircularProgress] = useState(false)
  const handleSendOtp = () => {
    axios.post(`${SERVER}/SendOtp`, { email: STORE.getData.userData.data.email })
      .then((res) => {
        if (res.data.isSuccess) {
          toast.success("Otp has been sent to your email id");

        } else {
          toast.error(res.data.Error);
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const handleVarifyOtp = () => {
    setCircularProgress(true)
    axios.post(`${SERVER}/checkOtp`, { otp: otp })
      .then((res) => {
        setCircularProgress(false)
        if (res.data.success) {
          setFlag(true)
          toast.success("Otp matched successfully");
        } else {
          toast.error("Session Expired try again");
        }
      })
      .catch((err) => console.log(err));
  }
  return (

    <Box sx={{ ...styles, background: "#2f2841", top: '50%', width: "25rem", height: "20rem", display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <div className='text-desc mx-auto my-auto' style={{ position: "absolute", right: 0, top: 0, cursor: "pointer" }}>
        <CloseIcon sx={{ ...newButton, fontSize: '2rem', borderRadius: '50%' }} onClick={() => handleModalClose()} />
      </div>
      <div> {CircularProgress ? <MeCircularProgress /> : ''}</div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '12px', width: "350px", height: "20px" }}>
        <Typography id="modal-modal-title" align="center">
          Do You Want To Cancel Your Subscription?
        </Typography>
        <div className="d-flex justify-content-around">
          {!openInput ? <>
            <Button
              onClick={() => handleModalClose()}
              variant="contained"
              size="large"
              sx={{
                ...newButton,
                py: "6px",
                width: "6rem",
                fontSize: "75%",
                mx: '2rem',
                my: '2rem'
              }}
            >
              No
            </Button>
            <Button
              onClick={() => {
                setOpenInput(true)
                handleSendOtp()
              }}
              variant="contained"
              size="large"
              sx={{
                ...newButton,
                py: "6px",
                width: "6rem",
                fontSize: "75%",
                mx: '2rem',
                my: '2rem'
              }}
            >
              Yes
            </Button>
          </> : ""}
        </div>
        {openInput && !flag ?
          <>
            <input type="text" className="form-control" value={otp} onChange={(e) => setOtp(e.target.value)} placeholder="Enter your OTP here " />
            <small>Otp will expire in 5 minutes</small>
            <Button
              variant="contained"
              size="medium"
              className="mx-auto profile-card__button button--orange"
              onClick={handleVarifyOtp}
            >Varify</Button>
          </>
          : ''}
        <Button
          variant="contained"
          size="medium"
          disabled={flag ? false : true}
          className="mx-auto profile-card__button button--orange"
          onClick={cancelSubs}
        >Cancel Subscription</Button>
      </div>
    </Box>
  )
}


function ShowProfile({ handleModalClose }) {
  const STORE = useSelector((state) => state);

  return (
    <Box sx={{ ...styles, top: '46%' }}>
      <div className='text-desc mx-auto my-auto' style={{ position: "absolute", right: 0, top: 0, cursor: "pointer" }}>
        <CloseIcon sx={{ ...newButton, fontSize: '2rem', borderRadius: '50%' }} onClick={() => handleModalClose()} />
      </div>
      <div></div>
      {STORE.userProfile?.userProfile?.meProfile?.userId?.pdf ? (
        <img style={{ height: 300, width: 300, borderRadius: "50%" }}
          src={getImgURL(STORE.userProfile?.userProfile?.meProfile?.userId?.pdf)}
          alt="User-profile"
        />
      ) : (
        <img style={{ height: 300, width: 300, borderRadius: "50%" }}
          src="https://icon-library.com/images/default-user-icon/default-user-icon-13.jpg"
          alt="profile picture"
        />
      )}
    </Box>
  )
}

function ShowPitch({ handleModalClose }) {
  const STORE = useSelector((state) => state);
  const AccordianBackground = {

    color: "black",
    margin: "10px 0 10px 0",
    border: "1px solid white",
    width: 'auto',
    marginBottom: '1rem',
    overflowY: 'auto'
  };
  return (
    <Box className='col-lg-8 col-md-8 col-sm-11' sx={{ ...styles, top: '50%', left: '50%', height: '30rem', boxShadow: "black 5px 5px 10px 5px", overflowY: 'scroll' }}>
      <div className='text-desc mx-auto my-2' style={{ position: "absolute", right: 0, top: 5, cursor: "pointer" }}>
        <CloseIcon sx={{ ...newButton, fontSize: '2rem', borderRadius: '50%' }} onClick={() => handleModalClose()} />
      </div>
      {(STORE?.getPitch?.userPitch?.message?.pitchVdo !== "" || STORE?.getPitch?.userPitch?.message?.pitch?.length > 0) ?

        <div className='col-12' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
          <h3 style={{ color: '#fff', textAlign: 'center' }}>My Pitch</h3>

          {STORE?.getPitch?.userPitch?.message?.pitchVdo ?
            <video className="col-11" style={{
              boxShadow: "black 5px 5px 10px 5px",
              borderRadius: "10px",
              maxHeight: '20rem',
              background: 'white'
            }}

              src={getImgURL(
                STORE?.getPitch?.userPitch?.message?.pitchVdo
              )}
              controlsList="nodownload"
              autoPlay
              controls
              muted
              loop
            ></video>
            : ""
          }
          {STORE?.getPitch?.userPitch?.message?.pitch ?
            <div className="col-11 mt-2 p-0">

              <Accordion sx={AccordianBackground}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography sx={{ color: 'black', textAlign: 'center' }}>Click here to see my personal pitch to you and global media market place</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ color: 'black' }}>
                    {descriptionFunction(STORE?.getPitch?.userPitch?.message?.pitch)?.map((item, index) => (

                      <p style={{ fontSize: "80%", color: 'black' }}>{item}</p>
                    ))

                    }
                  </Typography>
                </AccordionDetails>

              </Accordion>



              {/* <MuiAccordian accBg={AccordianBackground} iconColor={{ color: 'black' }} accSummary={'Click here to see my personal pitch to you and global media market place'} accDetails={STORE?.getPitch?.userPitch?.message?.pitch?.join(' ')} accSumTypo={{ color: 'black', textAlign: 'center' }} accDetailTypo={{ color: 'black' }} /> */}
            </div>
            : ""
          }
        </div>
        : <div className='col-12' style={{ minWidth: '100%', minHeight: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
          <h3 style={{ color: '#fff', textAlign: 'center', textTransform: 'uppercase' }}> user has not uploaded any pitch</h3>
        </div>
      }
    </Box>
  )
}

function ShowFollowers({ handleModalClose }) {

  const [value, setValue] = useState('1');
  const [users, setUsers] = useState({})
  const STORE = useSelector((state) => state)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  useEffect(() => {
    // below line is commented because we set these data using api here name getFolData(). if we uncomment below lines then we will get data from profile page through colpopup dispatch.


    // setUsers({ allFollowers: STORE?.callPopUp?.data?.allFollowers, allFollowings: STORE?.callPopUp?.data?.allFollowings })
    getFolData()
    setValue(`${STORE?.callPopUp?.data?.query}`)
  }, [STORE?.userProfile?.userFollower?.allFollowing])

  function getFolData() {
    if (getAuth()) {
      axios.get(`${SERVER}/users/handleFollowers`,
        {
          headers: { authorization: `Bearer ${getAuth()}` }
        }).then((res) => {
          setUsers({ allFollowers: res?.data?.allFollowers, allFollowings: res?.data?.allFollowing })
        })
        .catch((err) =>
          console.log(err)
        )
    }
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TableList({ data }) {
    const [isFollow, setIsFollow] = useState(true);
    const handleFollowings = () => {
      dispatch(handleFollow(data?.id, STORE?.getProfileData?.userData?._id))
      setIsFollow(false)
    }
    return (
      <div className="col-12 p-0 d-flex align-items-center" style={{ minHeight: '3rem', justifyContent: 'space-between' }}>
        <div className="col-md-1 p-0 d-flex align-items-center justify-content-center" style={{ height: '100%', cursor: 'pointer' }}>
          <img style={{ width: '2rem', height: '2rem', borderRadius: '50%' }} src={data?.profile ? `${data?.profile}` : `https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-PNG-Photos.png`} />
        </div>
        <div className="col-md-6 p-0 d-flex align-items-center " style={{ height: '100%', textTransform: 'capitalize', cursor: 'pointer' }}
          onClick={() => {
            navigate(`/MainDashboard/UserProfile/${data?.name}/${data?.id}`)
            handleModalClose()
          }
          }
        >
          <h6>{data?.name}</h6>
        </div>
        <div className="col-md-2 p-0 d-flex align-items-center justify-content-center" style={{ height: '100%', }}>
          <button className="new_prof_btn" style={{ background: 'white', color: 'black', borderRadius: '20px', padding: '5px 20px', outline: 'none', border: 'none', fontSize: '.8rem', letterSpacing: '1px' }}
            onClick={() => {
              navigate(`/MainDashboard/UserProfile/${data?.name}/${data?.id}`)
              handleModalClose()
            }
            }
          >
            View
          </button>
        </div>
        {!data?.followersView ?
          <div className="col-md-3 p-0 d-flex align-items-center justify-content-center" style={{ height: '100%', }}>
            <button className="new_prof_btn" style={{ background: 'black', color: 'white', borderRadius: '20px', padding: '5px 20px', outline: 'none', border: 'none', fontSize: '.8rem', letterSpacing: '1px' }}
              onClick={() => handleFollowings()}
            >
              {isFollow ? `Following` : `Follow`}
            </button>
          </div>

          : ''
        }
      </div>
    )
  }
  return (
    <Box className='col-lg-8 col-md-8 col-sm-12 bg-dark' sx={{ ...styles, top: '50%', left: '50%', height: '20rem', boxShadow: "black 5px 5px 10px 5px", overflowY: 'auto' }}>
      <div className='text-desc mx-auto my-1' style={{ position: "absolute", right: 0, top: 5, cursor: "pointer" }}>
        <CloseIcon sx={{ ...newButton, fontSize: '2rem', borderRadius: '50%' }} onClick={() => handleModalClose()} />
      </div>
      <Box className='col-12 my-4 p-0' sx={{ typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', bgcolor: 'background.paper' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example" centered textColor="secondary">
              <Tab label="FOLLOWINGS" value="1" />
              <Tab label="FOLLOWERS" value="2" />

            </TabList>
          </Box>
          <TabPanel className='col-12 p-0' value="1">

            {
              users?.allFollowings && users?.allFollowings?.length > 0 ? (
                users?.allFollowings?.map((item, index) => (
                  <TableList key={index} data={{ name: item?.follow?.name, profile: item?.follow?.pdf, id: item?.follow?._id, followersView: false }} />
                ))
              )
                :
                <div className="col-12 d-flex align-items-center justify-content-center" style={{ height: '25vh' }}>
                  <MeCircularProgress />
                </div>

            }
          </TabPanel>
          <TabPanel className='col-12 p-0' value="2">
            {
              users?.allFollowers && users?.allFollowers?.length > 0 ? (
                users?.allFollowers?.map((item, index) => (
                  <TableList key={index} data={{ name: item?.userId?.name, profile: item?.userId?.pdf, id: item?.userId?._id, followersView: true }} />
                ))
              )
                :
                <div className="col-12 d-flex align-items-center justify-content-center" style={{ height: '25vh' }}>
                  <MeCircularProgress />
                </div>
            }

          </TabPanel>


        </TabContext>
      </Box>

    </Box>
  )
}

function ShowFPTags({ handleModalClose }) {
  const STORE = useSelector((state) => state)
  const { userRole } = useOwnIdentity()

  return (
    <Box className='col-md-6 bg-dark' sx={{ ...styles, top: '50%', left: '50%', height: '20rem', boxShadow: "black 5px 5px 10px 5px", overflowY: 'auto' }}>
      <div className='text-desc mx-auto my-2' style={{ position: "absolute", right: 0, top: 5, cursor: "pointer", }}
        onClick={() => handleModalClose()}
      >
        <CloseIcon sx={{ ...newButton, fontSize: '2rem', borderRadius: '50%' }} />
      </div>
      <div style={{ width: '100%', minHeight: '50%', padding: '1rem' }}>
        <h6 className='profLable'>
          {
            userRole === 'creator' ?
              'MEBOOKMETA FINGERPRINT TAGS:'
              :
              'MY INTERESTS:'
          }
        </h6>
        <span style={{ display: "flex", flexWrap: "wrap" }}>
          {STORE?.callPopUp?.data?.fptags && STORE?.callPopUp?.data?.fptags?.length ? (
            STORE?.callPopUp?.data?.fptags?.map((item, index) => (
              <span
                key={index}
                style={{
                  color: "rgb(252,252,252)",
                  background: "#2B3467",
                  margin: ".2rem .2rem",
                  padding: "4px 8px 4px 8px",
                  borderRadius: "10px",
                  fontSize: "100%",
                }}
              >
                <span>{item?.name}</span>
              </span>
            ))

          ) : <span style={{ display: "" }}>This Profile has not Completed yet</span>
          }

        </span>
      </div>
    </Box>
  )

}

function VisitorCatSelect({ handleModalClose }) {
  const [cat, setCat] = useState([])
  const [copyCat, setCopyCat] = useState([])
  const [loading, setLoading] = useState([false])
  const [catArray, setCatArray] = useState([])
  const [bredcrum, setBredcrum] = useState(['Categories'])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const STORE = useSelector((state) => state)
  const getcat = () => {
    setLoading(true)
    axios.get(`${SERVER}/admin/getUsersCount`)
      .then((response) => {
        setCat(response?.data?.message)
        setCopyCat(response?.data?.message?.map((item) => item?._id))
        setLoading(false)
      })
      .catch((error) => console.log('error in get category in VisitorCatSelect', error))
  }
  useEffect(() => {
    getcat()
    if (STORE?.userProfile?.userProfile?.visitorInterest?.subcategories) {
      setCatArray(STORE?.userProfile?.userProfile?.visitorInterest?.subcategories)
    }
  }, [])

  function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  function getImageByIndex(index) {
    const images = [Author, Writer, Music, Sound, MusicVideo, Film, Television, Print, VArts, PArts];
    return images[index % images.length];
  }
  const getChildCategory = (name) => {
    setLoading(true)
    try {
      axios.get(`${SERVER}/admin/getSubCategoryForAdmin?id=${name}`)
        .then((res) => {
          setCat(res?.data)
          setLoading(false)
        })
    } catch (error) {
      setLoading(false)
      console.log('error in child category', error)
    }
  }
  const removeCategory = (index) => {
    const updateCategory = [...catArray]
    updateCategory?.splice(index, 1)
    setCatArray(updateCategory)

  }
  const handleCatSelect = (arg) => {

    if (copyCat?.includes(arg._id) || arg?.haveSubCategory) {
      setBredcrum([...bredcrum, arg.name])

      getChildCategory(arg?.name)
    } else if (!catArray?.map((item) => item?._id)?.includes(arg._id)) {
      setCatArray([...catArray, arg])
    }

  }


  const bredcrumHandler = (name, index) => {
    bredcrum.splice(index + 1, bredcrum.length - 1)
    if (name === 'Categories') {
      getcat()
    }
    else {
      getChildCategory(name)
    }
  }

  const handleSubmit = () => {

    if (catArray?.length > 2) {
      axios.post(`${SERVER}/dashboard/visitorsProfile`, { subCategories: catArray?.map((item) => item?._id) }, {
        headers: { Authorization: `Bearer ${getAuth()}` }
      })
        .then((response) => {


          if (response?.data?.errorCode === 200) {
            dispatch(userDashboardDetail());
            dispatch(userProfile());
            handleModalClose();
            dispatch({ type: 'REMOVE_POPUP' })
            localStorage.setItem('visitorInterest', JSON.stringify(true))
            toast.success(response?.data?.message, { position: 'top-center', autoClose: 1500, pauseOnHover: 'false' })
            navigate('/MainDashboard')
          }
        })
        .catch((error) => console.log('error in post of visitors interest', error))
    }
    else {
      toast.error('select atleast three')
    }
  }


  return (
    <Box className='col-lg-10 col-md-10 col-sm-10 d-flex flex-column align-items-center justify-content-between' sx={{ ...styles, top: '50%', left: '50%', height: '30rem', boxShadow: "black 5px 5px 10px 5px", background: '#61677A', border: '2px solid white', overflowY: 'scroll' }}>
      {
        STORE?.userDashboardDetail?.userWorkDetail?.interestProfile ?
          <div className='' style={{ cursor: "pointer", }}
            onClick={() => {
              handleModalClose();
              dispatch({ type: 'REMOVE_POPUP' })
            }}
          >
            <CloseIcon sx={{ ...newButton, fontSize: '2rem', borderRadius: '50%' }} />
          </div>
          : ''
      }
      <div className="" style={{ position: 'absolute', top: 10, right: 10 }}>
        <NewBtn className={'creatVist'} text={'Submit'} onClick={handleSubmit} style={{ letterSpacing: '2px', fontSize: '1rem' }} />
      </div>

      <div className='d-flex flex-column align-items-center justify-content-center my-4'>
        <h5>Let's define your interests</h5>
        <h5>You can select multiple categories to define your interests</h5>

        {/* <h6 style={{color:'red'}}>* select atleast three</h6> */}
        <h6>
          {bredcrum && bredcrum?.map((item, index) => (
            <span style={{ cursor: 'pointer', color: 'yellow' }} key={index} onClick={bredcrum[bredcrum?.length - 1] === item ? null : () => bredcrumHandler(item, index)}>{item} {index !== bredcrum.length - 1 ? <DoubleArrowIcon sx={{ color: 'blue' }} /> : ''} &nbsp;</span>
          ))}
        </h6>
        <div className="col-11">
          <h6 style={{ display: 'flex', flexWrap: 'wrap' }}>{
            catArray && catArray.map((item, index) =>
              <>
                <span
                  key={item?._id}
                  style={{
                    // color: "rgb(252,252,252)",
                    color: 'white',
                    background: "#2B3467",
                    margin: ".2rem .2rem",
                    padding: "4px 8px 4px 8px",
                    borderRadius: "10px",
                    fontSize: "70%",

                  }}
                >
                  <span >{item?.name}</span>
                  <CancelIcon sx={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => removeCategory(index)} />
                </span>

              </>
            )
          }
          </h6>
        </div>
      </div>

      <div className="col-md-12 d-flex align-items-center justify-content-around flex-wrap" style={{}}>
        {
          loading ?
            <MeCircularProgress />
            :
            (cat && cat?.map((item, index) => (item?.name !== "ALL USER" ?
              <div key={item?._id} className="col-lg-3 col-md-3 col-sm-4 bg-dark text-center d-flex align-items-center  p-3 m-1" style={{ borderRadius: '15px', border: catArray?.map((item) => item?._id)?.includes(item?._id) ? '2px solid white' : '', cursor: 'pointer', justifyContent: (!item?.haveSubCategory || !copyCat?.includes(item?._id)) ? 'center' : 'space-between' }}
                onClick={() => handleCatSelect(item)}

              >

                <p className="" style={{ textTransform: 'capitalize', margin: 0 }} >{item?.name}</p>

                {(item?.haveSubCategory || copyCat?.includes(item?._id)) ?
                  <DoubleArrowIcon />
                  : ''
                }


              </div>
              : ""
            )))
        }
      </div>

    </Box >
  )
}


function DonationForm({ handleModalClose }) {
  const accDetailsContent = `
  1. The money that you donate will be used to promote a Global Media Marketplace for Creatives and to provide Creatives more ways to get compensated for their work.

  2. Fifteen percent (15%) of all donations will go to our Needy Creatives Fund to pay subscriptions for talented persons who cannot afford to pay.

  3. Connectivity – Your donations will help increase global connectivity, the ability of Creatives around the world to share, collaborate and profit regardless of borders, language or economic disparity.

  4. Mentoring/Expert Advice – Ten percent (10%) of all donations will be dedicated to the Mentoring/Expert Fund on MeBookMeta. While we consider gratis mentoring and advice from experts as a donation, yet associated costs will be used to post information on the site in a format most beneficial to all users.

  5. Your donations will help provide opportunities for many Creatives in third-world countries and depressed economies who have no viable way of putting their work in front of global decision-makers who can help make them successful.

  6. Creatives today are facing many challenges related to corporate greed and new technologies. Your donations will help them find viable ways to survive and thrive in future markets.

  7. Your donations will help pay for Creative and practical online tools for users that will be free to everyone on the site.

  8. By donating to MeBook Meta, you also become a Creative, a valued contributor in the Global Creative Process.

  9. Your donations can help make that “Next Best Thing” a reality.

  10. In most cases, your donations will be tax deductible.
`;

  const paragraphs = accDetailsContent.split('\n').map((paragraph, index) => (
    <p key={index}>{paragraph.trim()}</p>
  ));

  const creativeDefinition = `
  What exactly is A Creative?
  At MeBookMeta, we recognize that, in addition to creators, there are many valuable contributors and aspects to the Creative Process, composing an important, more comprehensive group, which is comprised of collaborators, influencers, providers, project developers, consumers and sponsors. Thus a “Creative” can be anyone involved in the Creative process, from an initial idea to final presentation, and beyond.
`;

  const creativeParagraphs = creativeDefinition.split('\n').map((paragraph, index) => (
    <p key={index}>{paragraph.trim()}</p>
  ));

  const creativeSposorDefination = `
  Do you have a sibling, child, relative or friend who is bursting at the seams with creativity? 
  Is there a prodigy in your life, an unrecognized genius? Perhaps the next big thing?
  What exactly is A Creative?
  At MeBookMeta, we recognize that, in addition to creators, there are many valuable contributors and aspects to the Creative Process, composing an important, more comprehensive group, comprised of collaborators, influencers, providers, project developers and consumers. Thus a “Creative” can be anyone involved in the Creative process, from an initial idea to final presentation.
  Sometimes, Creatives are so focused on what they do that they never put forth the effort to effectively share it out. As an agent of change, MeBookMeta is purposed to provide better outcomes for Creatives by helping to instantly share out work and build connectivity for success now rather than when we are long gone. As a Sponsor, you too can be an agent of change in the life of a Creative.`
  const creativeSposorParagraph = creativeSposorDefination.split('\n').map((paragraph, index) => (
    <p key={index}>{paragraph.trim()}</p>
  ));

  const creativenextPara = `
  How Can I Sponsor A Creative?
  While visitors can create profiles on MeBookMeta for free, there are costs and extra benefits associated with the profiles of Creatives, and thus a monthly subscription fee. A Sponsor is someone willing to pay A Creative’s monthly subscription fee for a period of time, or on an ongoing basis.
  If there is A Creative you would like to sponsor on MeBookMeta, you will only need that person’s name and email address. The “Sponsor A Creative” button below will take you to an information and secure payment page, where you will provide your information and the Creative’s information. Upon payment, you and your Creative will receive confirmation emails, which will include a coupon code and instructions to help set up a profile as A Creative.
  As a Sponsor on MeBookMeta, you will join an important and esteemed group of altruistic Patrons of the Arts, who have worked behind the scenes to bring light to the world. Thank you!
`
  const creativenextFun = creativenextPara.split('\n').map((paragraph, index) => (
    <p key={index}>{paragraph.trim()}</p>
  ));

  const STORE = useSelector((state) => state)

  const popupType = STORE?.callPopUp?.PopType;
  // console.log(popupType, "popuptype")

  const [inpData, setInpData] = useState({
    email: '',
    name: "",
    amount: 35,
    sponToName: "",
    sponToEmail: "",
    sponToName_conf: "",
    sponToEmail_conf: ""

  })
  // console.log('inpData', inpData)
  const [validEmail, setValidEmail] = useState(true);

  const handleChange = (e) => {
    console.log("sponser dara", e.target)
    setInpData({
      ...inpData,
      [e.target.name]: e.target.value
    })
  }
  console.log("input data", inpData)

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return regex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let urlDonation = `${SERVER}/payment/donation`
    let urlSponsor = `${SERVER}/payment/sponsorship`

    const donationData = {
      name: inpData.name,
      email: inpData.email,
      amount: inpData.amount
    };
    const sponserData = {
      sponByName: inpData.name,
      sponByEmail: inpData.email,
      sponToEmail: inpData.sponToEmail,
      sponToEmail_conf: inpData.sponToEmail_conf,
      sponToName: inpData.sponToName,
      sponToName_conf: inpData.sponToName_conf,
      amount: parseInt(inpData.amount),
    };


    if (popupType === 'DonationForm') {
      if (donationData.email && donationData.name && donationData.amount) {
        const isValidEmail = validateEmail(donationData.email);
        setValidEmail(isValidEmail);
        if (isValidEmail) {
          if (donationData.amount >= 10) {
            // console.log(donationData, "donation data")
            axios.post(urlDonation, donationData)
              .then((response) => {
                // console.log(response);
                if (response.data.errorCode == 200) {
                  handleModalClose()
                  window.location.href = response?.data?.sessionurl
                } else {
                  toast.error(response.data?.message)
                }
              })
              .catch((error) => console.log(error))
          } else {
            toast.error("Amount must be greater than $10")
          }
        } else {
          toast.error("Invalid email")
        }
      } else {
        toast.error("All fields are required")
      }
    } else if (popupType === 'SponsershipForm') {
      if (sponserData.sponByEmail && sponserData.sponByName && sponserData.amount && sponserData.sponToEmail && sponserData.sponToName && sponserData.sponToEmail_conf && sponserData.sponToName_conf) {
        const isValidEmail = validateEmail(sponserData.sponByEmail);
        const isSponserCandidateEmail = validateEmail(sponserData.sponToEmail);
        setValidEmail(isValidEmail);
        setValidEmail(isSponserCandidateEmail)
        if (isValidEmail && isSponserCandidateEmail) {
          if (sponserData.sponToEmail_conf === sponserData.sponToEmail && sponserData.sponToName_conf === sponserData.sponToName) {
            console.log("sponserData 1065", sponserData)
            axios.post(urlSponsor, sponserData)
              .then((response) => {
                // console.log(response);
                if (response.data.errorCode == 200) {
                  handleModalClose()
                  window.location.href = response?.data?.sessionurl
                } else {
                  toast.error(response.data?.message)
                }
              })
              .catch((error) => console.log(error))
          } else {
            toast.error("Confirm Creative name & email")
          }
        } else {
          toast.error("Invalid email")
        }
      } else {
        toast.error("All fields are required")
      }
    }
  }

  let text;
  if (popupType === 'SponsershipForm') {
    text = "Sponsor"
  } else if (popupType === 'DonationForm') {
    text = "Donate"
  }
  const InputFieldMui = ({ value, label, name, handleChange }) => {
    return (
      <TextField
        fullWidth variant="filled" label={label}
        name={name}
        value={value} onChange={handleChange}
        sx={{ input: { color: 'black', }, background: 'white', borderRadius: "6px" }}
      />
    )
  }

  return (
    <>
      <Box className='col-md-6' sx={{ ...styles, top: '50%', left: '50%', height: '35rem', boxShadow: "black 5px 5px 10px 5px", overflowY: 'auto', marginX: "10px", background: "#2f2841" }}>
        <div className='text-desc mx-auto my-2' style={{ position: "absolute", right: 0, top: 5, cursor: "pointer", }}
          onClick={() => handleModalClose()}
        >
          <CloseIcon sx={{ ...newButton, fontSize: '2rem', borderRadius: '50%' }} />
        </div>
        {/* accordion */}
        <Box style={{ marginTop: "10px", padding: "1rem", textAlign: 'center' }}>
          {
            popupType === 'DonationForm' ? <h4>Donate to MeBookMeta</h4> : <h4>Sponsor a Creative</h4>
          }
          {
            popupType === 'DonationForm' ? <MuiAccordian iconColor={{ color: "white" }} accSumTypo={{ color: "white" }} accBg={BackGround} accDetailTypo={{ color: "white" }} accSummary={`Ten Excellent Reasons Why You Should Donate to MeBookMeta`} accDetails={paragraphs}
              accDetailsExtra={creativeParagraphs} /> :
              <MuiAccordian iconColor={{ color: "white" }} accSumTypo={{ color: "white" }} accBg={BackGround} accDetailTypo={{ color: "white" }} accSummary={`Do you know a highly talented person who the world really should know about?`}
                accDetails={creativeSposorParagraph}
                accDetailsExtra={creativenextFun}
              />
          }
        </Box>
        {/* form start */}
        <Box component='form' onSubmit={handleSubmit}>
          <div className="d-flex flex-column justify-content-center align-items-start" style={{ gap: "1rem", margin: "1rem" }}>
            {/* <InputFieldMui value={inpData.name} label={"Enter your name*"} name={"name"} handleChange={handleChange} /> */}

            <TextField
              fullWidth variant="filled" label='Enter your name*'
              name='name'
              value={inpData.name} onChange={handleChange}
              sx={{ input: { color: 'black', }, background: 'white', borderRadius: "6px" }}
            />
            {/* <InputFieldMui value={inpData.email} label={"Enter your email*"} name={"email"} handleChange={handleChange} /> */}

            <TextField
              fullWidth variant="filled" label="Enter your email*"
              name='email'
              value={inpData.email} onChange={handleChange}
              sx={{ input: { color: 'black', }, background: 'white', borderRadius: "6px" }} />


            {
              popupType === 'SponsershipForm' ? <>
                {/* <InputFieldMui value={inpData.sponToName} label={"Enter name of Creative you are sponsoring*"} name={"sponToName"} handleChange={handleChange} /> */}

                <TextField
                  fullWidth variant="filled" label="Enter name of Creative you are sponsoring*"
                  name='sponToName'
                  value={inpData.sponToName} onChange={handleChange}
                  sx={{ input: { color: 'black', }, background: 'white', borderRadius: "6px" }} />


                {/* <InputFieldMui value={inpData.sponToName_conf} label={"Confirm name of Creative you are sponsoring*"} name={"sponToName_conf"} handleChange={handleChange} /> */}

                <TextField
                  fullWidth variant="filled" label="Confirm name of Creative you are sponsoring*"
                  name="sponToName_conf"
                  value={inpData.sponToName_conf} onChange={handleChange}
                  sx={{ input: { color: 'black', }, background: 'white', borderRadius: "6px" }} />


              </> : ""
            }
            {
              popupType === 'SponsershipForm' ? <>
                {/* <InputFieldMui value={inpData.sponToEmail} label={"Enter email of Creative you are sponsoring*"} name={"sponToEmail"} handleChange={handleChange} /> */}

                <TextField
                  fullWidth variant="filled" label="Enter email of Creative you are sponsoring*"
                  name="sponToEmail"
                  value={inpData.sponToEmail} onChange={handleChange}
                  sx={{ input: { color: 'black', }, background: 'white', borderRadius: "6px" }} />

                {/* <InputFieldMui value={inpData.sponToEmail_conf} label={"Confirm email of Creative you are sponsoring*"} name={"sponToEmail_conf"} handleChange={handleChange} /> */}

                <TextField
                  fullWidth variant="filled" label="Confirm email of Creative you are sponsoring*"
                  name="sponToEmail_conf"
                  value={inpData.sponToEmail_conf} onChange={handleChange}
                  sx={{ input: { color: 'black', }, background: 'white', borderRadius: "6px" }} />

                {/* <div class="dropdown">
                  <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                   Amount*
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" href="#">Action</a>
                    <a class="dropdown-item" href="#">Another action</a>
                    <a class="dropdown-item" href="#">Something else here</a>
                  </div>
                </div> */}

                <select id="mySelect" value={inpData.amount} name="amount" onChange={handleChange} style={{ width: '12rem', borderRadius: '6px', fontSize: '1rem',color:'black',padding:'1rem' }}>
                  <option value={35}>$35 for 1 month</option>
                  <option value={105}>$105 for 3 months</option>
                  <option value={210}>$210 for 6 months</option>
                </select>


              </> : ""
            }
            {/* {
              popupType === 'SponsershipForm' ?
                <InputFieldMui value={inpData.amount} label={"Amount*"} name={"amount"}  disabled={"disabled"} />
                :
                // <InputFieldMui value={inpData.amount} label={"Enter amount**"} name={"amount"} handleChange={handleChange} />

                <TextField
              fullWidth variant="filled" label="Enter amount**"
              name="amount"
              value={inpData.amount} onChange={handleChange}
              sx={{ input: { color: 'black', }, background: 'white', borderRadius: "6px" }}/>
              } */}
            <div className="d-flex justify-content-end">
              <NewBtn className={'creatVist'} text={text} style={{ background: { BackGround }, letterSpacing: '2px', fontSize: '1rem', padding: "0.6rem", outline: "none", appearance: "button", backfaceVisibility: "hidden", borderRadius: "6px", borderWidth: 0, lineeight: 1.15, overflow: "hidden" }} />
            </div>
          </div>
        </Box>
      </Box >
    </>

  )

}

function SuccessDonation({ handleModalClose }) {
  const navigate = useNavigate()
  const STORE = useSelector((state) => state)
  const [code, setCode] = useState('')
  const popupType = STORE?.callPopUp?.PopType;
  useEffect(() => {
    if (popupType === 'SuccessSponsor') {


      const urlParams = new URLSearchParams(window.location.search);
      const sponsorParam = urlParams.get('sponsor');
      // Display the sponsor parameter value in the console
      // console.log('Sponsor:', sponsorParam);
      setCode(sponsorParam)
    }
  }, [])
  return (
    <Box className='col-md-6 bg-dark' sx={{ ...styles, top: '50%', left: '50%', height: '20rem', boxShadow: "black 5px 5px 10px 5px", overflowY: 'auto' }}>
      <div className='text-desc mx-auto my-2' style={{ position: "absolute", right: 0, top: 5, cursor: "pointer", }}
        onClick={() => {
          handleModalClose()
          navigate('/')
        }}
      >
        <CloseIcon sx={{ ...newButton, fontSize: '2rem', borderRadius: '50%' }} />
      </div>
      <div className="d-flex flex-column align-items-center justify-content-around" style={{ width: '100%', height: '80%' }}>

        {popupType === 'SuccessDonation' ?
          <h6>Thank You for Your Support of MeBookMeta!</h6>
          : ""}
        {popupType === 'SuccessSponsor' ?
          <h6>Thank you very much for your sponsorship!</h6>
          : ""}
        {popupType === 'SuccessSponsor' ?
          <>
            <h6>Your Sponsorship Code</h6>
            <h5>{code}</h5></>
          : ""}

        <h6>
          We want to express our deepest gratitude for your generous {popupType === 'SuccessDonation' ? 'donation' : 'sponsorship'} to our web application project. Your contribution is an invaluable vote of confidence in our vision and mission.</h6>
      </div>
    </Box>
  )
}

function SponsorCheckModal({ handleModalClose }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [code, setCode] = useState({
    couponCode: '',
    email: ""
  });
  const [promoCode, setPromoCode] = useState("")
  const handleChange = (e) => {
    setCode({
      ...code,
      [e.target.name]: e.target.value,
    });
  }

  const sponsorHandleSubmit = () => {
    const sponsorCouponCheck = {
      sponToEmail: code.email,
      sponsorId: code.couponCode
    }
    let sponserApi = `${SERVER}/validSponsor`
    if (sponsorCouponCheck.sponToEmail && sponsorCouponCheck.sponsorId) {
      axios.post(sponserApi, sponsorCouponCheck,
        {
          headers: { Authorization: `Bearer ${getAuth()}` }
        }).then((response) => {
          // console.log('response', response)
          if (response.data.errorCode == 200) {
            handleModalClose()
            navigate(`/MainDashboard`)
            // setTimeout(() => {
            //   dispatch(callPopUp({ type: "successSponsor" }))
            // }, 3000);
          } else {
            toast.error(response.data?.message)
            // console.log("response", response)
          }
        }).catch((error) => {
          console.log(error)
        })
    } else {
      toast.error("All fields are required")
    }
  }

  return (
    <Box sx={{ ...styles, background: "#2f2841", top: '50%', width: "23rem", height: "20rem", display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', background: "#2f2841" }}>
      <div className='text-desc mx-auto my-auto' style={{ position: "absolute", right: 0, top: 0, cursor: "pointer" }}>
        <CloseIcon sx={{ ...newButton, fontSize: '2rem', borderRadius: '50%' }} onClick={() => handleModalClose()} />
      </div>
      <div></div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '12px', width: "350px", height: "250px" }}>
        <Typography id="modal-modal-title" align="center">
          Do You Have a Sponsored Code ?
        </Typography>
        <input name='couponCode' value={code?.couponCode} type="text" placeholder="Enter Coupon Code" onChange={handleChange} style={{ width: '15rem', height: '3rem', textAlign: 'center', margin: 'auto' }} />
        <input name='email' value={code?.email} type="text" placeholder="Enter email" onChange={handleChange} style={{ width: '15rem', height: '3rem', textAlign: 'center', margin: 'auto' }} />
        <Typography id="modal-modal-title" align="center">
          Tap the box below to begin your MeBookMeta journey
        </Typography>
        <Button
          variant="contained"
          size="medium"
          className="mx-auto profile-card__button button--orange"
          onClick={sponsorHandleSubmit}
        >Submit</Button>
      </div>
    </Box>
  )
}

function ShowProfileError({ handleModalClose }) {
  let { ownId, userRole, interestProfile } = useOwnIdentity();
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const logOut = () => {
    localStorage.removeItem("token");
    SOCKET.emit("remove-user", ownId);
    // global.SOCKET.disconnect();
    ownId = null
    dispatch({ type: "USER_LOGOUT" });
    dispatch({ type: "CALLING__PROGRESS", payload: 10 });
    setTimeout(() => {
      handleModalClose()
      dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
      navigate("/");
    }, 1500);
  };
  return (
    <Box className='col-md-6 bg-light' sx={{ ...styles, top: '50%', left: '50%', height: '20rem', boxShadow: "black 5px 5px 10px 5px", overflowY: 'auto' }}>
      {/* <div className='text-desc mx-auto my-2' style={{ position: "absolute", right: 0, top: 5, cursor: "pointer", }}
      onClick={() => handleModalClose()}
      >
        <CloseIcon sx={{ ...newButton, fontSize: '2rem', borderRadius: '50%' }} />
      </div> */}
      <div className="d-flex flex-column align-items-center justify-content-center" style={{ width: '100%', minHeight: '50%', padding: '1rem' }}>
        <h5 className='profLable text-dark'>
          Your MeBookMeta profile setup is not completed yet
        </h5>
        <h6 className=' text-primary'>Login again and setup your MeBookMeta profile</h6>
        <NewBtn className={'creatVist'} text={'Okay and Logout'} onClick={logOut} style={{ letterSpacing: '2px', fontSize: '1rem', background: '#fff', color: 'black' }} />

      </div>
    </Box>
  )

}

function CreatePost({ handleModalClose }) {
  const STORE = useSelector((state) => state)
  const [image, setImage] = useState('')
  const [image1, setImage1] = useState('')
  const [uploadBtnView, setUploadBtnView] = useState(false)
  // const [random, setrandom] = useState({})
  const EditPostId = STORE?.callPopUp?.data?._id;
  const EditPostData = STORE?.getMePost?.userMePost?.data?.filter((i) => i?._id === EditPostId)
  const process = STORE?.callPopUp?.data?.process;
  // console.log("1322", EditPostId, EditPostData,)
  const [mediaPreview, setMediaPreview] = useState(false)
  const processType = { Add: "Add", Edit: "Edit" }
  const [editData, setEdit] = useState()
  const [postData, setPostData] = useState({
    description: (process === processType?.Edit) ? EditPostData[0]?.description?.join(" ") : "",
    file: (process === processType?.Edit) ? EditPostData[0]?.file : ""
  })

  const [progress, setProgress] = useState(false)
  const [btnDisable, setBtnDisable] = useState(false)

  const inputRef = useRef(null)
  const userProfie = STORE?.getProfileData?.userData?.pdf;
  const dispatch = useDispatch()
  const userName = STORE?.getProfileData?.userData?.name;
  const userId = STORE?.getProfileData?.userData?._id;

  const handleImageClick = () => {
    inputRef.current.click();
  }
  // file: event.target.files[0],
  // filepreview: URL.createObjectURL(event.target.files[0]),
  const imageUpload = () => {
    setProgress(true)
    const formdata = new FormData();
    formdata.append("profiles", image1);
    axios
      .post(
        `${SERVER}/coverPicture`, formdata, { onUploadProgress: onUplProg }
      )
      .then((res) => {
        setProgress(false)
        setPostData({ ...postData, file: res?.data?.file })
        toast.success(res?.data?.success, { autoClose: 2000 })
        setBtnDisable(false)
        // setMediaLink(res?.data?.file)
        // setbtnUpdate(true)

      })
      .catch((error) => {
        console.log('error', error)
        setProgress(false)
        toast.error('Something Error', { autoClose: 2000 })

      })

  }

  const handleImageChange = (event) => {
    setBtnDisable(true)
    const file = event.target.files[0]
    const filePrev = URL.createObjectURL(event.target.files[0])
    setImage(filePrev)
    setImage1(file)
    setUploadBtnView(true)
    setMediaPreview(true)

  }

  const handlePostChange = (e) => {
    setPostData({
      ...postData,
      [e.target.name]: e.target.value
    })
  }

  const handlePostSubmit = (e) => {
    e.preventDefault();
    const postFormData = {
      description: postData.description,
      file: postData.file,
      _id: EditPostData ? EditPostData[0]?._id : "",
    }
    // console.log("postdata", postFormData,)
    process === processType?.Add ?
      dispatch(addMePost(postFormData, userId, process,))
      :
      dispatch(addMePost(postFormData, userId, process))
  }

  return (
    <>

      <div className="create_post_modal_mainDiv">


        <div className="create_post_modal_container" >
          {/* <div style={{position:'absolute', top:'30%'}}> */}
          {
            progress &&
            <UplProgBar variant="determinate" />
          }
          {/* </div> */}
          <div className="create_post_modal_container_wrapper">

            <section className="create_post_modal_post">
              <header>
                <CloseIcon onClick={() => handleModalClose()} sx={{ marginLeft: '90%' }} />
                {/* Create Post */}
              </header>

              <form onSubmit={(e) => handlePostSubmit(e)}>
                <div className="create_post_modal_content">
                  <img src={userProfie ? userProfie : 'https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-PNG-Photos.png'} />
                  <div className="create_post_modal_details">
                    <p>{userName}</p>
                    <span>Author</span>
                  </div>
                </div>

                <textarea value={postData.description} name={"description"} onChange={(e) => handlePostChange(e)} placeholder={`What's on you mind, ${userName} ?`} spellCheck="true" ></textarea>

                {
                  process === processType?.Add || mediaPreview ?
                    (image && <div className="image-container">< embed src={image} /></div>)
                    :
                    (postData?.file && <div className="image-container"><embed src={postData?.file} /></div>)
                }
                {/* {image ? <div className="image-container"><embed src={image} /></div> : ""} */}
                {/* <div className="image-container"><img src={"https://www.freecodecamp.org/news/content/images/size/w2000/2022/11/What-is.png"} /></div> */}
                <div onClick={handleImageClick} className="create_post_modal_media_inpit">
                  <input ref={inputRef} type="file" accept="image/*,video/*" onChange={handleImageChange} style={{ display: 'none' }} />
                  <PermMediaIcon style={{ fontSize: "2rem", color: "#2196f3" }} />
                </div>

                <div style={{ display: 'flex', gap: '5px' }}>
                  {uploadBtnView ?
                    <Button variant="contained" onClick={imageUpload}>Upload File</Button>
                    : ""
                  }


                  <Button type="submit" variant="contained" disabled={btnDisable}>Post</Button>

                </div>

              </form>

            </section>
          </div>
        </div>
      </div >
    </>
  )
}

function DeletePost({ handleModalClose }) {
  const postId = useSelector((state) => state?.callPopUp?.postId)
  const deletePost = async () => {

    try {
      const res = await axios.post(`${SERVER}/mePost/deletePost`, { _id: postId }, {
        headers: { authorization: `Bearer ${getAuth()}` }
      })
      if (res?.data?.errorCode === 200) {
        toast.success(res?.data?.message, {
          position: 'top-center',
          autoClose: 2000,
          pauseOnHover: false
        })
        handleModalClose();
      } else {
        toast.success("Something went wrong", {
          position: 'top-center',
          autoClose: 2000,
          pauseOnHover: false
        })
        handleModalClose();
      }
    } catch (error) {
      console.error('An error occurred while deleting the post:', error);
      toast.error("An error occurred while deleting the post", {
        position: 'top-center',
        autoClose: 2000,
        pauseOnHover: false
      })
    }

  }
  return (
    <Box className='col-md-6 bg-light' sx={{ ...styles, top: '50%', left: '50%', height: '15rem', boxShadow: "black 5px 5px 10px 5px", overflowY: 'auto' }}>

      <div className="d-flex flex-column align-items-center justify-content-center" style={{ width: '100%', minHeight: '50%', padding: '1rem', gap: '2rem' }}>

        <h4 style={{ color: 'black' }}>Are you sure want to delete this post ?</h4>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '1rem' }}>
          <NewBtn className={'creatVist'} text={'Cancel'} style={{ letterSpacing: '2px', fontSize: '1rem', background: '#fff', color: 'black' }} onClick={() => handleModalClose()} />
          <NewBtn className={'creatVist'} text={'Delete'} style={{ letterSpacing: '2px', fontSize: '1rem', background: '#fff', color: 'black' }} onClick={deletePost} />
        </Box>

      </div>
    </Box>
  )
}

function AIModals({ handleModalClose }) {
  const STORE = useSelector((state) => state)
  const popupType = STORE?.callPopUp?.PopType;

  const WhatisAi = [
    {
      content: "  ARTIFICIAL INTELLIGENCE, OFTEN CALLED AI, REFERS TO THE SIMULATION OF HUMAN INTELLIGENCE PROCESSES BY MACHINES, ESPECIALLY COMPUTER SYSTEMS. THESE PROCESSES GENERALLY INCLUDE LEARNING, REASONING, PROBLEM-SOLVING, PERCEPTION, AND LANGUAGE UNDERSTANDING."
    },
    {
      content: "  IN THE FIELD OF COMPUTER SCIENCE, AI RESEARCH IS DEFINED AS THE STUDY OF “INTELLIGENT AGENTS,” WHICH ARE DEVICES THAT PERCEIVES ITS ENVIRONMENT AND TAKES ACTIONS THAT MAXIMIZE ITS CHANCE OF SUCCESSFULLY ACHIEVING ITS GOALS. AI CAN BE CATEGORIZED INTO TWO TYPES: WEAK AI, WHICH IS DESIGNED AND TRAINED FOR A PARTICULAR TASK, AND STRONG AI, THAT MIMICS HUMAN INTELLECT AND CAN SOLVE ANY TASK IT ENCOUNTERS."
    }
  ]

  const AIBenefits = [
    {
      title: "INSPIRATION AND IDEAS: ",
      content: "AI-BASED APPLICATIONS CAN GENERATE IDEAS AND DESIGNS, AIDING INSPIRATION. IT CAN CREATE MUSIC, VISUAL ART, POETRY AND PROSE FROM A SET OF INPUTS AND GUIDELINES."
    },
    {
      title: "EFFICIENCY: ",
      content: "AI CAN AUTOMATE MUNDANE, REPETITIVE TASKS, ALLOWING CREATIVES MORE TIME TO FOCUS ON THE INVENTIVE ASPECTS OF THEIR WORK"
    },
    {
      title: "PERSONALIZATION: ",
      content: "AI CAN BE USED TO TAILOR AND REFINE CONTENT TO MATCH INDIVIDUAL USER PREFERENCES, ENHANCING THE IMPACT OF CREATIVE OUTPUT."
    },
    {
      title: "COLLABORATION: ",
      content: "AI TOOLS CAN FACILITATE VIRTUAL COLLABORATION, HELP MANAGE TASKS AND STREAMLINE CREATIVE PROCESSES."
    },
    {
      title: "LEARNING: ",
      content: "AI-GUIDED LEARNING PLATFORMS CAN HELP CREATIVES ENHANCE THEIR SKILLS AND LEARN NEW ONES BY PROVIDING CUSTOMIZED LEARNING PATHS."
    }
  ]

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", minHeight: "100vh", width: "100wh", }}>
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", height: "auto", width: "75%", background: "#fff", padding: "2rem", borderRadius: "6px" }}>
          {
            popupType === 'AIModalOne' ?
              <><h4 style={{ fontWeight: 700, marginBottom: '15px' }} >What is AI?</h4>
                {
                  WhatisAi?.map((i) => {
                    return (
                      <p>{i?.content}</p>
                    )
                  })
                }
              </>
              :
              <>
                <h4 style={{ fontWeight: 700, marginBottom: '15px' }}>How Can AI Benefit Creatives?</h4>
                <div>
                  {
                    AIBenefits?.map((i, index) => {
                      return (

                        <p key={index + 'meraj'}><span ><b>{`${index + 1}.`} {i?.title}</b></span>{i?.content}</p>

                      )
                    })
                  }
                </div>
              </>
          }
          <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
            <p style={{ order: 1 }}>Quoted by Chat Smith (AI)</p>
          </div>
          <Button onClick={handleModalClose} variant="contained" >Close</Button>
        </div>
      </div >
    </>
  )
}


function SeeMorePost({ handleModalClose }) {
  const Description = useSelector((state) => state?.callPopUp?.data?.text)
  const { userRole } = useOwnIdentity()
  const regex = /(https?:\/\/[^\s]+)/g;

  const Text = ({ text }) => {
    const seprateLink = (props) => {
      const propsSplit = props?.split("\n");
      return propsSplit;
    };
    const dispatch = useDispatch()

    return (
      <p style={{ fontSize: '1rem' }}>
        {text?.map((item) => {
          return item.match(regex) ? (
            <>
              <span>
                {seprateLink(item)?.length > 1 ? seprateLink(item)?.[0] : ""}{" "}
              </span>
              <a
                style={{
                  color: "blue",
                  width: "100%",
                  display: "inline-block",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  wordWrap: "break-word",
                  // wordBreak:"break-all"
                }}
                href={
                  seprateLink(item)?.length > 1
                    ? seprateLink(item)?.[1]
                    : seprateLink(item)?.[0]
                }
                target="_blank"
                rel="noreferrer"
              >
                {seprateLink(item)?.length > 1
                  ? seprateLink(item)?.[1]
                  : seprateLink(item)?.[0]}
              </a>{" "}
            </>
          ) : (
            item + " "
          );
        })}
        {/* <span style={{ color: 'yellow', fontSize: '.9rem' }} onClick={() => dispatch(callPopUp({ type: 'SeeMorePost',data:{text} }))
             }>see more</span> */}

      </p>
    );
  };
  return (
    <Box className='col-md-6 bg-dark' sx={{ ...styles, top: '50%', left: '50%', height: '20rem', boxShadow: "black 5px 5px 10px 5px", overflowY: 'auto' }}>
      <div className='text-desc mx-auto my-2' style={{ position: "absolute", right: 0, top: 5, cursor: "pointer", }}
        onClick={() => handleModalClose()}      >
        <CloseIcon sx={{ ...newButton, fontSize: '2rem', borderRadius: '50%' }} />
      </div>
      <div style={{ width: '100%', minHeight: '50%', padding: '1rem' }}>
        <Text text={Description} />

      </div>
    </Box>
  )

}

function AskMeAi({ handleModalClose }) {

  const [userInput, setUserInput] = useState({
    question: "",
    dataLoading: false,
  })

  const dispatch = useDispatch()

  const handleEnter = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      // e.preventDefault();
      handleSubmit(e)
    }

  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInput({
      ...userInput,
      [name]: value
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const query = userInput?.question
    console.log("first query", query)

    if (query) {
      dispatch(aiAllChat(query))
      handleModalClose()

    }
  }
  return (
    <>
      <div className="w-full h-screen flex justify-center items-center">
        <div className=" lg:w-5/12 md:w-8/12 w-10/12   p-4 rounded-lg shadow bg-gray-100 relative">

          <div className="flex justify-center items-center">
            <p className="text-xl">Ask MeBookMeta AI</p>
          </div>

          <div className="flex justify-center items-center ">
            <form onSubmit={handleSubmit} className="w-full">
              <textarea className="w-full border border-gray-600 rounded-lg p-2 mb-2" name={"question"} value={userInput?.question} onChange={(e) => handleChange(e)} onKeyDown={(e) => handleEnter(e)} placeholder="Ask MeBookMetaAI" required rows="1"></textarea>
              <div className="w-full flex justify-end items-center ">
                <div>
                  <Button variant="contained" color="warning" type='submit' style={{ width: '80px', height: '40px' }}>ask</Button>
                </div>
              </div>
            </form>
          </div>

          <div className="mt-2">
            {/* <Button onClick={handleModalClose} variant="contained" >Close</Button> */}
          </div>

          <div className="absolute top-0 right-0">
            <IconButton>
              <FiDelete onClick={handleModalClose} />
            </IconButton>
          </div>
        </div>
      </div >
    </>
  )
}