// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.playerIcon_main {
    position: fixed;
    bottom: 12rem;
    right: 1.5rem;
    z-index: 999;
}

.playerIcon_player {
    background-color: #FFFDD7;
    /* opacity: 0.5; */
    padding: 10px;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 300px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    transition: 3s ease;
}



.playerIcon_icon_div {
    width: 100%;
}

.playerIcon_icon {
    font-size: 2rem;
    color: red;
    cursor: pointer;
}


@media screen and (max-width: 480px) {
    .playerIcon_player {

        width: 350px;

    }
}`, "",{"version":3,"sources":["webpack://./src/Component new/widget/music/PlayerIcon.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,YAAY;IACZ,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,mBAAmB;IACnB,4BAA4B;IAC5B,gCAAgC;IAChC,mBAAmB;AACvB;;;;AAIA;IACI,WAAW;AACf;;AAEA;IACI,eAAe;IACf,UAAU;IACV,eAAe;AACnB;;;AAGA;IACI;;QAEI,YAAY;;IAEhB;AACJ","sourcesContent":[".playerIcon_main {\n    position: fixed;\n    bottom: 12rem;\n    right: 1.5rem;\n    z-index: 999;\n}\n\n.playerIcon_player {\n    background-color: #FFFDD7;\n    /* opacity: 0.5; */\n    padding: 10px;\n    position: absolute;\n    bottom: 0;\n    right: 0;\n    width: 300px;\n    height: 100px;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    align-items: center;\n    border-top-left-radius: 20px;\n    border-bottom-right-radius: 20px;\n    transition: 3s ease;\n}\n\n\n\n.playerIcon_icon_div {\n    width: 100%;\n}\n\n.playerIcon_icon {\n    font-size: 2rem;\n    color: red;\n    cursor: pointer;\n}\n\n\n@media screen and (max-width: 480px) {\n    .playerIcon_player {\n\n        width: 350px;\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
