import React, { useState, useEffect } from 'react'
import './NewAskAI.css'
import './AskAI.css';
import me from '../../../mepic.png'
import { useDispatch, useSelector } from 'react-redux'
import { aiAllChat, callPopUp } from '../../../Action'
import MePic from '../../../mepic.png';
import TypingAnimation from './TypingAnimation'

/// chat section 
const ChatMessage = ({ data, profilePicture, query, timestamp }) => {
  return (

    <>
      {data &&
        <div className='askai_chat col-11 mt-2 '>
          <div className='askai_chat_img col-1 '>
            <img src={profilePicture} alt="AI Bot" />
          </div>
          <div className='askai_chat_desc col-11  m-0 pl-3  '>
            {query && <p style={{ fontSize: '.6rem' }}>{timestamp}</p>}
            <p className='d-flex flex-column' style={{ gap: '10px', color: 'white', }}>{
              data?.split('\n')?.map((item, idx) => (
                <span key={idx} >
                  {query || !item?.includes(':') ?
                    <>
                      <span>{item}</span>
                    </>
                    :
                    <>
                      <span style={{ fontWeight: '700' }}>{item?.split(':')[0]} {item && ':'}</span>
                      <span>{item?.split(':')[1]}</span>
                    </>
                  }

                </span>
              ))

            }</p>
          </div>
        </div>
      }
    </>
  );
};

const NewAskAI = () => {
  const [userInput, setUserInput] = useState({
    question: "",
    dataLoading: false,
  })
  const [viewSidebar, setViewSidebar] = useState(false)
  const [currentDateItem, setCurrentDateItem] = useState(null)
  const [showSideBar, setShowSideBar] = useState(true)
  const userProfie = useSelector((state) => state?.getProfileData?.userData?.pdf)
  const STORE = useSelector((state) => state)
  const { allChat, lastChat, loadingLastChat } = STORE?.aiAllChat
  const aiChatData = useSelector((state) => state?.aiAllChat?.allChat);
  const dispatch = useDispatch()

// function to get date in date-month-year 
function dateFormat(currentDate) {
  const getDate = currentDate;
  const year = getDate.getFullYear();
  const month = (getDate.getMonth() + 1).toString().padStart(2, '0');
  const date = getDate.getDate().toString().padStart(2, '0');
  const formattedDate = `${date}-${month}-${year}`;
  return formattedDate;
}

  // convert aichatdata into norrmal date formate data
  const formattedAiAllChatData  = aiChatData?.map((item) => {
    const currentTimestamp = new Date(item.timestamp);
    const year = currentTimestamp.getFullYear();
    const month = (currentTimestamp.getMonth() + 1).toString().padStart(2, '0');
    const date = currentTimestamp.getDate().toString().padStart(2, '0');
    const formattedTimestamp = `${date}-${month}-${year}`;
    return {
      ...item,
      timestamp: formattedTimestamp,
    };
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInput({
      ...userInput,
      [name]: value
    })
  }

  const handleSubmit = async (e) => {
    console.log("question",userInput?.question)
    e.preventDefault();
    const query = userInput?.question
    console.log("first query", query)

    if (query) {
      dispatch(aiAllChat(query))

    }
  }

  const handleEnter = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      // e.preventDefault();
      handleSubmit(e)
    }

  }

  /// set the chat and date acc to sidebar history
  const handleDateClick = (selectDate) => {
    setCurrentDateItem(selectDate);
  }

  const handleViewSidebar = () => {
    setViewSidebar((prevState) => !prevState)
  }

  const handleStartChat = () => {
    dispatch(callPopUp({ type: "AskMeAi"}))
  }

  useEffect(() => {
    setCurrentDateItem(formattedAiAllChatData?.reverse()?.[0]?.timestamp);
  }, []);

  console.log("currentDateItem useEffect", showSideBar)

  // function to get object of unique dates. First search of the day.
  const uniqueDatesSet = new Set();
  const uniqueObjects = formattedAiAllChatData?.filter(item => {
    if (!uniqueDatesSet?.has(item?.timestamp)) {
      uniqueDatesSet?.add(item?.timestamp);
      return true;
    }
    return false;
  });
  
  useEffect(() => {
    if (allChat?.length > 0) {
        window.scrollTo(0, document.body.scrollHeight)
    }

    setUserInput({ question: "" })
    window.scrollTo(0, document.body.scrollHeight);


}, [allChat])


useEffect(() => {
    if (lastChat !== '') {
        window.scrollTo(0, document.body.scrollHeight)

    }
}, [lastChat])
useEffect(() => {
  const handleResize = () => {
    if (window.innerWidth <= 600) {
      setShowSideBar(false);
    } else {
      setShowSideBar(true);
    }
  };
  // Add event listener to handle window resize
  window.addEventListener('resize', handleResize);

  // Initial check for screen size
  handleResize();

  // Cleanup the event listener
  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);
  return (
    <>
      <div className="flex h-[90vh]" style={{overflow:'hidden'}}>
        <nav id="sidebar" className="max-h-screen">
          {/* show Sidebar content */}
          {showSideBar && <div className="float-top">
            <div className="sidebar-controls">
              <button onClick={() => handleStartChat()} className="new-chat"><i className="fa fa-plus"></i>Ask MeBookMeta AI</button>
              {/* <button className="new-chat">
                <img  src={me} style={{width:"100%", height:"100%"}} alt='meaipic'/>
              </button> */}
              <button onClick={() => setShowSideBar(false)} className="hide-sidebar"><i className="fa fa-chevron-left"></i></button>
            </div>
             
            <div className="sidebar-controls">{!viewSidebar && <button onClick={() => handleViewSidebar()} className="new-chat"><i className="fa fa-plus"></i>View Chats</button>}</div> 

            <ul className="conversations">
              {
              viewSidebar &&  uniqueObjects && uniqueObjects?.reverse()?.map((item) => {
                  return (
                    <>
                      <li className="grouping">{item?.timestamp}</li>
                      {/* <li className="grouping">{dateType(item?.timestamp)}</li> */}
                      <li className="active">
                        <button className="conversation-button" onClick={() => handleDateClick(item?.timestamp)} ><i className="fa fa-message fa-regular"></i>{item?.query}</button>
                        {/* <div className="fade"></div>
                        <div className="edit-buttons">
                          <button><i className="fa fa-trash"></i></button>
                        </div> */}
                      </li>
                    </>
                  )
                })
              }
              {uniqueObjects.length === 0 && <li className="active text-center text-white">No chats available</li>}
            </ul>

          </div>}
          {!showSideBar && <div className="" style={{ height: "100vh", width: "40px" }}>
            <div className="">
              <button onClick={() => setShowSideBar(true)} className="hide-sidebar"><i className="fa fa-chevron-right"></i></button>
            </div>
          </div>}
        </nav>
        <main>
          {formattedAiAllChatData?.length === 0 && <>
            <div style={{ height: "6rem", width: "10rem", margin: "auto" }}>
              <img style={{ height: "100%", width: "100%", }} src={MePic} alt='mePic' />
            </div>
            <h1 className='text-center text-white'>Start asking MeBookMetaAI</h1>
          </>}
          <div id="message_container" style={{backgroundColor:'black'}}>
            {
              formattedAiAllChatData?.length > 0 &&
              <section className='col-12 askai_maindiv  pt-0 pb-0'>
                <div className="chat_container  p-0" >
                  {
                    formattedAiAllChatData && formattedAiAllChatData?.filter((item) => item?.timestamp === currentDateItem)?.map((item, index) => (
                      <>
                        <ChatMessage data={item?.query} profilePicture={userProfie ? userProfie : 'https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-PNG-Photos.png'} query={true} timestamp={item?.timestamp} />
                        <ChatMessage data={item?.chatResponse} profilePicture={MePic} query={false} />
                      </>
                    ))
                  }
                  {
                    lastChat &&
                    <ChatMessage data={lastChat} profilePicture={'https://is3-ssl.mzstatic.com/image/thumb/Purple116/v4/9e/4a/8d/9e4a8d9d-d550-a46b-1db1-8a87a18c62f3/AppIcon-0-0-1x_U007emarketing-0-0-0-7-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/256x256bb.jpg'} query={false} />
                  }
                  <div className='col-9'>
                    {loadingLastChat ? <TypingAnimation /> : ''}
                  </div>
                  { (currentDateItem === dateFormat(new Date())) && ( 
                    <div >
                        <form className='flex pe-4 pb-4'>
                            <div className='flex items-center w-full px-4 me-4'>
                              <input
                                className='rounded-lg w-full px-4 py-2 focus:outline-none'
                                name="question"
                                value={userInput.question}
                                onChange={handleChange}
                                onKeyDown={handleEnter}
                                id="aiUserInput"
                                placeholder="Ask MeBookMetaAI"
                                required
                                readOnly={loadingLastChat}
                              />
                              <span type='submit' className='border-none text-blue-500 hover:text-blue-600 p-2 rounded-full'>
                                <i className="fa fa-paper-plane fa-lg"></i>
                              </span>
                            </div>
                        </form>
                  </div>)
                  }
                </div>
              </section>
            }
          </div>
        </main>
      </div>
    </>
  )
}

export default NewAskAI