import React from 'react'
import "../AI/AskAI/NewAskAI.css"
import { suggestionCategoryItem } from '../../Component new/User Dashboard/sideBarItem/SideBar'
import axios from 'axios'
import { SERVER } from '../../server/server'
import WorkCard from '../../Component new/userWork/WorkCard'
import MusicWorkCard from '../../Component new/userWork/MusicWorkCard/MusicWorkCard'
import { getAuth } from '../../Action'
import AudioPlayer from '../../Component new/userWork/workDisplay/audioPlayer/AudioPlayer'
import WorkPdf from '../../Component new/userWork/workDisplay/WorkPdf'
import MusicList from './components/musicList/MusicList'


function renderWorkComponent(work){
   if(work){
  //   if(work?.workDetails?.category === "Music"){
  //     return <AudioPlayer file={work?.workDetails?.userId?.pdf} coverPicture={work?.workDetails?.coverPicture} title={work?.workDetails?.title} name={work?.workDetails?.userId?.name} />

  //   }else if(work?.workDetails?.category === "pdf"){
  //    return <WorkPdf file={work?.workDetails?.userId?.pdf} coverPicture={work?.workDetails?.coverPicture} title={work?.workDetails?.title} name={work?.workDetails?.userId?.name} />
  //   }else if(work?.workDetails?.category === "mp4"){
  //     return <WorkPdf file={work?.workDetails?.userId?.pdf} coverPicture={work?.workDetails?.coverPicture} title={work?.workDetails?.title} name={work?.workDetails?.userId?.name} />
  //   }else{
  //   return <div style={{display:"flex", justifyContent:"center", alignItems:"center", color:"white"}}>
  //     <h1>No favorite available </h1>
  //   </div>
  //  }
   if(work?.workDetails[0]?.category=== "Music"){
    return <>
    <div style={{display:"flex", justifyContent:"center", alignItems:"center", padding:"2rem",}}>
    <AudioPlayer file={work?.workDetails[0]?.userId?.pdf} coverPicture={work?.workDetails[0]?.coverPicture} title={work?.workDetails[0]?.title} name={work?.workDetails[0]?.userId?.name} />
    </div>
    </>
  }else{
  return <div style={{display:"flex", justifyContent:"center", alignItems:"center", color:"white"}}>
    <h1>No favorite available </h1>
  </div>
  }
  }else{
    return <div style={{display:"flex", justifyContent:"center", alignItems:"center", color:"white"}}>
    <h1>Select the category</h1>
  </div>
  }
}

const Whistlist = () => {
    const [showSideBar, setShowSideBar] = React.useState(true)
    const [catData, setCatData] = React.useState(null)

   const handleGetCat = (category) => {
     axios.get(`${SERVER}/favorite/get-all-favorite/${category}`,  {
      headers: { Authorization: `Bearer ${getAuth()}` },
     })
     .then((response) => {
      console.log(response.data)
      setCatData(response.data?.data);
     })
     .catch((error) => {
         console.error(error);
     })
   }

  console.log("catData", catData)
  return (
    <>
      <div className='row d-flex m-0 p-2 pt-5'
      style={{background:'black',
      minHeight:'100vh'
    
    }}
      >
        {/* <nav id="sidebar">
          {showSideBar && <div className="float-top">
            <div className="sidebar-controls">
            <button className="hide-sidebar"><i className="">Favorite works</i></button>
              
              <button onClick={() => setShowSideBar(false)} className="hide-sidebar"><i className="fa fa-chevron-left"></i></button>
            </div>
            <ul className="conversations">
              {
                suggestionCategoryItem && suggestionCategoryItem?.map((item,idx) => {
                  if(idx===2){

                    return (
                      <div className="sidebar-controls" key={item.title}>
                        <button onClick={() => handleGetCat(item?.title)} className="new-chat">{item.title}</button>
                      </div>
                    )
                  }
                })
              }

            </ul>

          </div>}
          {!showSideBar && <div className="" style={{ height: "100vh", width: "40px" }}>
            <div className="">
              <button onClick={() => setShowSideBar(true)} className="hide-sidebar"><i className="fa fa-chevron-right"></i></button>
            </div>
          </div>}
        </nav> */}
        <div className='col-md-2'>
        {
                suggestionCategoryItem && suggestionCategoryItem?.map((item,idx) => {
                  if(idx===2){

                    return (
                      <div className="sidebar-controls" key={item.title}>
                        <button onClick={() => handleGetCat(item?.title)} className="new-chat">{item.title}</button>
                      </div>
                    )
                  }
                })
              }
        </div>
        
          <div className='col-md-10 col-sm-12'>
            {/* {renderWorkComponent(catData)} */}
            <MusicList/>
          </div>
        
      </div>  
    </>
  )
}

export default Whistlist;