const initialState = {
    userIdentity: false,
    loading: false
}

export const userIdentity = (state = initialState, action) => {
    switch (action.type) {
        case "CALLING_IDENTITY":
            return {
                ...state,
                loading: true
            }
        case "GETTING_IDENTITY":
            return {
                ...state,
                loading: false,
                userIdentity: action.payload,
            }
        default:
            return state
    }
}
