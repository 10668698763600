const initialState = {
    allChat: [],
    lastChat: '',
    loadingAllChat: false,
    loadingLastChat: false

}
const aiAllChat = (state = initialState, action) => {
    switch (action.type) {
        case 'CALLING_AI_ALL_CHAT':
            return {
                ...state,
                lastChat: '',
                loadingAllChat: true,
                loadingLastChat: true


            }

        case 'GET_AI_LAST_CHAT':
            // window.scrollTo({ bottom: 0, behavior: 'smooth' });
            return {
                ...state,
                lastChat: state?.lastChat?.concat(action.payload),
                loadingAllChat: false,
                loadingLastChat: false


            }


        case 'SET_AI_LAST_CHAT_QUERY':
            return {
                ...state,
                allChat: [...state.allChat, { query: action.payload,timestamp:Date.now() }],
                loadingAllChat: false

            }

        case 'GET_AI_ALL_CHAT':
            state?.allChat?.pop()
            return {
                ...state,
                // allChat: [...state.allChat, { query: action.payload, chatResponse: state.lastChat }],
                allChat:action.payload,
                lastChat: '',
                loadingAllChat: false


            }

        case 'FAILED_AI_CHAT':
            return {
                ...state,
                loadingAllChat: false,
                loadingLastChat: false
            }

        default:
            return state
    }
}

export default aiAllChat