const initialState = {
    data: [],
    unseenNotification:0
}

export const notificationRequest = (state = initialState, action) => {
    switch (action.type) {
        case "GETTING_NOTIFICATION_REQ":
            let temp = [...action.payload.message,...state.data]
            let newArr = temp.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t._id === value._id
            )))
            return {
                ...state,
                data:[...newArr],
                unseenNotification:action.payload.unSeenNotification,
                unSeenMessage:action.payload.unSeenMessage
            }
        default:
            return state
    }
}
