import React from 'react';
import './Padd.css';
import Slider from "react-slick";
import MePic from './assets/MePic';
import FilmProduction from './FilmProduction.png'



// import "~slick-carousel/slick/slick.css"; 
// import "~slick-carousel/slick/slick-theme.css";

export default function PaddCarou() {



  const settings = {

    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className='bgpadd' id='comingSoon'>
      <Slider {...settings}>

        <div className=' bgpadd'>
          <div className="col-12 col-sm-12 col-md-11 mx-auto LandingBox p-3">
            <div className='d-flex justify-content-center align-items-center'>
              <MePic style={{ width: '8.5rem', height: '4.5rem' }} />
            </div>
            <h2 className='text-center mb-4'><b>Simplify for More Success</b></h2>
            <h5 className='text-center'>Let MeBookMeta Replace Your Website</h5>
            <ul className='px-4 mx-5' style={{ marginLeft: '20px' }}>
              <li>Save Money and Time</li>
              <li>Easy Changes and Updates</li>
              <li>Built-in Traffic</li>
              <li>Intra-Site Promotion</li>
              <li>Pitch Alley (Full Version)</li>
              <li>Ask the Universe (Full Version)</li>
              <li>MeBookMetaAI (included)</li>
              <li>MeBookMeta Chat (included)</li>
              {/* <li><b>Privacy Secure</b> </li>
              <li>We Will Never Sell or Share Your Private Information</li> */}


              {/* <li>Visual and Graphic Arts, Photography, Film Art</li>
              <li>Drawing, Painting, and 3-Dimensional Art</li>
              <li>Dance Performance, World Dance, Styles, Performers</li>
              <li>Theatrical Work, Production, Performers and Performances</li>
              <li>Spoken Word, Live Poetry, Oration, Stand-up Comedy</li>
              <li>Education and Learning Related, Educators, Schools, Industry</li>
              <li>Computer Design, Hardware and Software Development, Apps</li>
              <li>Readers, Viewers, Listeners, Reviewers, Critics, General Audience</li> */}
            </ul>
            <span className='text-center'>
           
              <h4><b>Privacy Secure</b></h4>
              <h4>We Will Never Sell or Share Your Private Information.</h4>
              <h5>Our Team Is Also Developing MeBookMeta Mobile App For Your Smartphones and Tablets</h5>
            </span>
          </div>
        </div>

        <div className='bgpadd d-flex justify-content-center align-items-center '>
          <div className="col-12 col-sm-12 col-md-11 mx-auto LandingBox BoxPadding my-auto p-3" >
            <div className='d-flex justify-content-center align-items-center'>
              <MePic style={{ width: '8.5rem', height: '4.5rem' }} />
            </div>
            <h3 className='text-center mb-4'><b>MeBookMeta Can Help Take Your Project from
              a Drawing Board Idea to a Major Film Enterprise</b></h3>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-5 col-lg-5 my-auto"><img className='cardIMG' style={{ width: '100%' }} src={FilmProduction} alt="/" /></div>
              <div className="col-12 col-sm-12 col-md-7 col-lg-7 my-auto">
                <p>Only by putting so many artistic minds and categories together on a single
                  platform, the MeBookMeta Universe, can we help creators realize goals and
                  dreams never thought possible. As a user, you can gain access to other creators,
                  industry professionals, directors, producers, project developers and providers
                  across diverse disciplines to chart your path to unimagined ventures and
                  financial success.
                </p>
              </div>
            </div>

          </div>
        </div>

        <div className='bgpadd '>
          <div className="col-12 col-sm-12 col-md-11 mx-auto LandingBox BoxPadding p-3" >
            <div className='d-flex justify-content-center align-items-center'>
              <MePic style={{ width: '8.5rem', height: '4.5rem' }} />
            </div>
            <h3 className='text-center '><b>The Investment That Makes Sense:</b></h3>
            <h3 className='text-center mb-4'><b>You and What You Do</b></h3>
            <p>When you come to MeBookMeta with work or a project to share, we know that
              you have already invested your days, months or even years, your efforts,
              inspiration, sacrifice and indeed your very soul in the process of creating what
              you have brought to share. For a prudent $35/month investment in your work,
              you can be assured that you and what you do are instantly available to potential
              customers, partners, collaborators across the meta universe who can discover you
              and help deliver returns, commercial and industry related, for all your hard work.
            </p>
          </div>
        </div>

      </Slider>
    </div>
  )
}
