import axios from "axios";
import { SERVER } from "../server/server";
import { toast } from "react-toastify";

export const getAuth = () => JSON.parse(localStorage.getItem("token"));

export const getUserId = async () => {
    let result = await axios.get(`${SERVER}/getUserId`, {
        headers: { Authorization: `Bearer ${getAuth()}` },
    });
    // console.log("action", result.data)
    return await result.data;
};

export const getUserData = (token) => {
    return async (dispatch, getState) => {
        if (getAuth() || token) {
            dispatch({ type: "CALLING__PROGRESS", payload: 10 });
            axios
                .get(`${SERVER}/getuserById`, {
                    headers: { Authorization: `Bearer ${token ? token : getAuth()}` },
                })
                .then((response) => {
                    dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
                    dispatch({ type: "NO_ERROR" });
                    dispatch({ type: "UPDATED_USER_DATA", payload: response.data });
                })
                .catch((error) => {
                    dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
                    dispatch({ type: "GOT_ERROR" });
                    console.log(error);
                });
        }
    };
};
// update user detail
export const updateUserDetail = (data, id, res) => {
    return async (dispatch, getState) => {
        dispatch({ type: "CALLING__PROGRESS", payload: 10 });
        axios
            .post(`${SERVER}/userDetail`, { userData: data, userId: id })
            .then((response) => {
                dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
                dispatch({ type: "NO_ERROR" });
                dispatch(userProfile(id));
                toast.success(response.data.message, {
                    position: "top-center",
                    autoClose: 2000,
                    pauseOnHover: false,
                });
                res.send({ code: 200 });
                dispatch({ type: "UPDATED_USER_DETAIL", payload: response.data });
            })
            .catch((error) => {
                dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
                dispatch({ type: "GOT_ERROR" });
                console.log(error);
                res.send({ code: 400 });
            });
    };
};

export const writerWork = (process, UserData, res) => {
    return async (dispatch, getState) => {
        dispatch({ type: "CALLING__PROGRESS", payload: 10 });
        axios
            .post(
                `${SERVER}/work_1/author_work1/${await getUserId()}/?process=${process}`,
                UserData,
                {
                    headers: { Authorization: `Bearer ${getAuth()}` },
                }
            )
            .then((response) => {
                dispatch({ type: "NO_ERROR" });
                dispatch(userWorkDetail());
                toast.success(response.data.message, {
                    position: "top-center",
                    autoClose: 2000,
                    pauseOnHover: false,
                });
                res.send({ code: 200 });
                dispatch({ type: "WRITER_ID", payload: response.data.workId });
            })
            .catch((error) => {
                dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
                dispatch({ type: "GOT_ERROR" });
                res.send({ code: 400 });
                console.log(error);
            });
    };
};

// path:/CreatorNew1 api integration

export const getCreatorData = () => {
    return async (dispatch, getState) => {
        if (getAuth()) {
            dispatch({ type: "CALLING__PROGRESS", payload: 10 });
            axios
                .get(`${SERVER}/admin/getCategory`, {
                    headers: { Authorization: `Bearer ${getAuth()}` },
                })
                .then((response) => {
                    dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
                    dispatch({ type: "NO_ERROR" });
                    dispatch({ type: "GET_CREATOR_DATA", payload: response.data });
                })
                .catch((error) => {
                    dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
                    dispatch({ type: "GOT_ERROR" });
                    console.log(error);
                });
        }
    };
};

// get creator sub catergory
export const getSubCategory = (id) => {
    return async (dispatch, getState) => {
        if (id !== "Music") {
            dispatch({ type: "CALLING__PROGRESS", payload: 10 });
            dispatch({ type: "CALLING_SUBCATEGORY" });
            axios
                .get(`${SERVER}/admin/getSubCategory?id=${id}`)
                .then((response) => {
                    dispatch({ type: "NO_ERROR" });
                    dispatch({ type: "GET_SUBCATEGORY", payload: response.data.dummy });
                    setTimeout(() => {
                        dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
                    }, 500);
                })
                .catch((error) => {
                    dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
                    dispatch({ type: "GOT_ERROR" });
                    console.log(error);
                });
        }
    };
};

// get subcategory for music
export const getMusicSubCategory = (id) => {
    return async (dispatch, getState) => {
        dispatch({ type: "CALLING__PROGRESS", payload: 10 });
        dispatch({ type: "CALLING_SUBCATEGORY" });
        axios
            .get(`${SERVER}/admin/getSubCategory?id=${id}`)
            .then((response) => {
                localStorage.setItem("Music", JSON.stringify(response.data.dummy));
                dispatch({ type: "NO_ERROR" });

                dispatch({ type: "COMPLETE_SUBCATEGORY" });
                dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
            })
            .catch((error) => {
                dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
                dispatch({ type: "GOT_ERROR" });
                console.log(error);
            });
    };
};

// metaProfile setUp
export const mebookProfile = (UserData) => {
    return async (dispatch, getState) => {
        dispatch({ type: "CALLING__PROGRESS", payload: 10 });
        dispatch({ type: "CALLING_METAPROFILE" });
        axios
            .post(`${SERVER}/mebookProfile`, UserData, {
                headers: { Authorization: `Bearer ${getAuth()}` },
            })
            .then((response) => {
                dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
                dispatch({ type: "GET_SUBCATEGORY", payload: false });
                if (response.data.errorCode === 200) {
                    dispatch({ type: "NO_ERROR" });
                    toast.success(response.data.message, {
                        position: "top-center",
                        autoClose: 2000,
                        pauseOnHover: false,
                    });
                    dispatch({ type: "GETTING_METAPROFILE", payload: response.data });
                } else if (response.data.errorCode === 400) {
                    toast.error(response.data.message, {
                        position: "top-center",
                        autoClose: 2000,
                        pauseOnHover: false,
                    });
                    dispatch({ type: "GETTING_METAPROFILE", payload: response.data });
                } else if (response.data.errorCode === 300) {
                    toast.success(response.data.message, {
                        position: "top-center",
                        autoClose: 2000,
                        pauseOnHover: false,
                    });

                    dispatch({ type: "GETTING_METAPROFILE", payload: response.data });
                } else {
                    toast.error(response.data.message, {
                        position: "top-center",
                        autoClose: 2000,
                        pauseOnHover: false,
                    });
                }
            })
            .catch((error) => {
                dispatch({ type: "GOT_ERROR" });
                console.log(error);
                dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
            });
    };
};
// Identity SetUp
export const userIdentity = (UserData, callBack) => {
    return async (dispatch, getState) => {
        dispatch({ type: "CALLING__PROGRESS", payload: 10 });
        dispatch({ type: "CALLING_IDENTITY" });
        axios
            .post(`${SERVER}/userIdentity`, UserData, {
                headers: { Authorization: `Bearer ${getAuth()}` },
            })
            .then((response) => {
                dispatch(getUserData());
                callBack();
                dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
                if (response.data.errorCode === 200) {
                    dispatch({ type: "NO_ERROR" });
                    toast.success(response.data.message, {
                        position: "top-center",
                        autoClose: 2000,
                        pauseOnHover: false,
                    });
                    dispatch({ type: "GETTING_IDENTITY", payload: response.data });
                } else if (response.data.errorCode === 400) {
                    toast.error(response.data.message, {
                        position: "top-center",
                        autoClose: 2000,
                        pauseOnHover: false,
                    });
                    dispatch({ type: "GETTING_IDENTITY", payload: response.data });
                } else {
                    toast.error(response.data.message, {
                        position: "top-center",
                        autoClose: 2000,
                        pauseOnHover: false,
                    });
                }
            })
            .catch((error) => {
                dispatch({ type: "GOT_ERROR" });
                console.log(error);
                dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
            });
    };
};
// getuserProfile
export const userProfile = (userId) => {
    return async (dispatch, getState) => {
        dispatch({ type: "CALLING_USERPROFILE" });
        dispatch({ type: "CALLING__PROGRESS", payload: 10 });
        axios
            .get(`${SERVER}/users/getProfileDetails/${userId ? userId : 0}`, {
                headers: { authorization: `Bearer ${getAuth()}` },
            })
            .then((response) => {
                // console.log('my response',response)
                dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
                if (response.data.errorCode === 200) {
                    // localStorage.setItem("subCategory", JSON.stringify(response.data.meProfile.subCategory))
                    dispatch({ type: "NO_ERROR" });
                    dispatch({ type: "GET_USERPROFILE", payload: response.data });
                } else if (response.data.errorCode === 400) {
                    dispatch({ type: "GOT_ERROR" });
                    dispatch({ type: "GET_USERPROFILE", payload: response.data });
                } else {
                    dispatch({ type: "GOT_ERROR" });
                    toast.error(response.data.message, {
                        position: "top-center",
                        autoClose: 2000,
                        pauseOnHover: false,
                    });
                }
            })
            .catch((error) => {
                dispatch({ type: "GOT_ERROR" });
                console.log("error", error);
                dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
            });
    };
};

// getting followers
// user/getFollowers/:id
export const getFollowers = (userId) => {
    return async (dispatch, getState) => {
        axios
            .get(`${SERVER}/users/getFollowers/${userId ? userId : 0}`, {
                headers: { authorization: `Bearer ${getAuth()}` },
            })
            .then((response) => {
                if (response.data.errorCode === 200) {
                    dispatch({ type: "NO_ERROR" });
                    dispatch({ type: "GET_USERFOLLOWER", payload: response.data });
                    // res.send(response.data.allFollower)
                } else {
                    dispatch({ type: "GOT_ERROR" });
                    toast.error(response.data.message, {
                        position: "top-center",
                        autoClose: 2000,
                        pauseOnHover: false,
                    });
                }
            })
            .catch((error) => {
                dispatch({ type: "GOT_ERROR" });
                console.log("error", error);
                dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
            });
    };
};

// handle follow request
// user/handleFollow/:id

export const handleFollow = (userId, myId) => {
    // myId recieved if I want to update my followings detail just after follow or unfollow anyone by me. I will pass my userId to getFollowers so that i can update my data after follow or unfollow anyone
    // console.log('hey',myId)
    return async (dispatch, getState) => {
        axios
            .get(`${SERVER}/users/handleFollow/${userId}`, {
                headers: { authorization: `Bearer ${getAuth()}` },
            })
            .then((response) => {
                if (response.data.errorCode === 200) {
                    // follower added
                    // dispatch(getFollowers(userId))
                    dispatch(getFollowers(myId ? myId : userId));
                    //      {
                    //     send: (currentFollower) => {
                    //         // cookies.set('currentFollowState', currentFollower, { path: "/" })
                    //     }
                    // }))
                    // console.log("res",response.data)
                    // res.send(response.data.follow)
                } else if (response.data.errorCode === 300) {
                    // follower removed
                    // dispatch(getFollowers(userId))
                    dispatch(getFollowers(myId ? myId : userId));
                    //  {
                    //     send: (currentFollower) => {
                    //         // cookies.set('currentFollowState', currentFollower, { path: "/" })
                    //     }
                    // }))
                    // console.log("res",response.data)
                    // res.send(response.data.follow)
                } else {
                    dispatch({ type: "GOT_ERROR" });
                    toast.error(response.data.message, {
                        position: "top-center",
                        autoClose: 2000,
                        pauseOnHover: false,
                    });
                }
            })
            .catch((error) => {
                dispatch({ type: "GOT_ERROR" });
                console.log("error", error);
            });
    };
};

// user work detail
export const userWorkDetail = (id) => {
    return async (dispatch, getState) => {
        dispatch({ type: "CALLING__PROGRESS", payload: 10 });
        dispatch({ type: "CALLING_USER_WORK_DETAIL" });
        axios
            .get(
                `${SERVER}/work_1/getIndividualWork/${id ? id : await getUserId()}`,
                {
                    headers: { Authorization: `Bearer ${getAuth()}` },
                }
            )
            .then((response) => {
                dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
                dispatch({ type: "NO_ERROR" });
                if (response.data.errorCode === 200) {
                    dispatch({
                        type: "GETTING_USER_WORK_DETAIL",
                        payload: response.data,
                    });
                } else {
                    toast.success(response.data.message, {
                        position: "top-center",
                        autoClose: 2000,
                        pauseOnHover: false,
                    });
                }
            })
            .catch((error) => {
                dispatch({ type: "GOT_ERROR" });
                console.log(error);
                dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
            });
    };
};

export const delUserWork = (id) => {
    return async (dispatch, getState) => {
        dispatch({ type: "CALLING__PROGRESS", payload: 10 });
        axios
            .post(
                `${SERVER}/work_1/delIndividualWork/${id}`,
                { body: "body" },
                {
                    headers: { Authorization: `Bearer ${getAuth()}` },
                }
            )
            .then((response) => {
                dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
                dispatch({ type: "NO_ERROR" });
                if (response.data.errorCode === 200) {
                    dispatch(userWorkDetail());
                    toast.success(response.data.message, {
                        position: "top-center",
                        autoClose: 2000,
                        pauseOnHover: false,
                    });
                } else {
                    toast.error("token expire", {
                        position: "top-center",
                        autoClose: 2000,
                        pauseOnHover: false,
                    });
                }
            })
            .catch((error) => {
                dispatch({ type: "GOT_ERROR" });
                console.log(error);
                dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
            });
    };
};

// get pitch
export const getPitch = (process, id, call, body, vdo) => {
    // console.log("getPitch",process,id,call,body,vdo)
    return async (dispatch, getState) => {
        if (getAuth() || id) {
            dispatch({ type: "CALLING__PROGRESS", payload: 10 });
            dispatch({ type: "CALLING_PITCH" });
            axios
                .post(
                    `${SERVER}/users/user_pitch/${id ? id : await getUserId()
                    }/?process=${process}`,
                    { pitch: body, pitchVdo: vdo },
                    {
                        headers: { Authorization: `Bearer ${getAuth()}` },
                    }
                )
                .then((response) => {
                    dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
                    dispatch({ type: "NO_ERROR" });
                    switch (response.data.errorCode) {
                        case 200:
                            toast.success(response.data.message, {
                                position: "top-center",
                                autoClose: 2000,
                                pauseOnHover: false,
                            });
                            dispatch(getPitch("get", false, () => console.log("getting...")));
                            break;
                        case 600:
                            toast.success(response.data.message, {
                                position: "top-center",
                                autoClose: 2000,
                                pauseOnHover: false,
                            });
                            dispatch(getPitch("get", false, () => console.log("getting...")));
                            break;
                        case 300:
                            dispatch({ type: "GET_CREATOR_PITCH", payload: response.data });
                            // call()
                            break;
                        case 404:
                            dispatch({ type: "NO_PITCH" });
                            // toast.error("no pitch yet")
                            break;
                        default:
                            break;
                    }
                })
                .catch((error) => {
                    dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
                    dispatch({ type: "GOT_ERROR" });
                    console.log(error);
                });
        }
    };
};

// Post Pitch
export const postPitch = (UserData, res) => {
    return async (dispatch, getState) => {
        dispatch({ type: "CALLING__PROGRESS", payload: 10 });
        axios
            .post(`${SERVER}/${await getUserId()}`, UserData)
            .then((response) => {
                dispatch({ type: "NO_ERROR" });
                alert(response.data.message);
                dispatch({ type: "WRITTING_PITCH", payload: response.data.workId });
                res.send({ code: 200 });
            })
            .catch((error) => {
                dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
                dispatch({ type: "GOT_ERROR" });
                res.send({ code: 400 });
                console.log(error);
            });
    };
};

// Get all MePost by userId

export const getAllMePostByUserId = (userId) => {
    // console.log("userId", userId);
    return async (dispatch) => {
        if (userId) {
            dispatch({ type: "CALLING__PROGRESS", payload: 10 });
            dispatch({ type: "CALLING_ME_POST" });
            axios
                .get(`${SERVER}/mePost/getMePostByUserId/${userId}`)
                .then((response) => {
                    dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
                    dispatch({ type: "NO_ERROR" });
                    dispatch({ type: "GET_ME_POST", payload: response.data });
                })
                .catch((error) => {
                    dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
                    dispatch({ type: "NOT_ME_POST" });
                    toast.error('Something Error', { autoClose: 2000 })
                    console.log("error", error);
                });
        }
    };
};


// Get single mePost by a postId unauth

export const getSingleMePostByPostId = (postId) => {
    // console.log("userId", userId);
    return async (dispatch) => {
        if (postId) {
            dispatch({ type: "CALLING__PROGRESS", payload: 10 });
            // dispatch({ type: "CALLING_ME_POST" });
            axios
                .get(`${SERVER}/mePost/getMePostByPostId/${postId}`)
                .then((response) => {
                    console.log('rees562', response)
                    dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
                    dispatch({ type: "NO_ERROR" });
                    // dispatch({ type: "GET_ME_POST", payload: response.data });
                    dispatch({ type: "GET_ME_POST_UPDATE", payload: response.data });

                })
                .catch((error) => {
                    dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
                    // dispatch({ type: "NOT_ME_POST" });
                    toast.error('Something Error 571 line', { autoClose: 2000 })
                    console.log("error", error);
                });
        }
    };
};

// handle post sentiments

export const postSentiment = (postUserId, postId, type, res) => {
    // console.log("dashboard")
    return async (dispatch, getState) => {
        axios
            .post(
                `${SERVER}/mePost/sentiment?type=${type}`, { postId: postId },
                {
                    headers: { authorization: `Bearer ${getAuth()}` },
                }
            )
            .then((response) => {
                console.log('res', response)
                // if (response.data.errorCode === 200) res.send(200);
                dispatch(getAllMePostByUserId(postUserId))
                // dispatch(getSingleMePostByPostId(postId))

                dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });

                // if (response.data.errorCode === 300) res.send(200);
            })
            .catch((error) => {
                dispatch({ type: "GOT_ERROR" });
                console.log(error);
                dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
            });
    };
};


// Add user Mepost
export const addMePost = (data, userId, process) => {
    return async (dispatch) => {
        if (getAuth()) {
            dispatch({ type: "CALLING__PROGRESS", payload: 10 });
            axios
                .post(`${SERVER}/mePost/addMePost?process=${process}`, data, {
                    headers: { Authorization: `Bearer ${getAuth()}` }
                })
                .then((response) => {
                    dispatch({ type: "NO_ERROR" });
                    dispatch(getAllMePostByUserId(userId))
                    toast.success(response?.data?.message)
                    dispatch(callPopUp({ type: "REMOVE_POPUP" }))
                })
                .catch((error) => {
                    dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
                    dispatch({ type: "GOT_ERROR" });
                    console.log(error);
                });
        } else {
            toast.error("User not authenticated", {
                position: "top-center",
                autoClose: 2000,
                pauseOnHover: false,
            });
            // console.log("user not authenticated");
        }
    }
}


//  Media Upload

export const mediaUpload = (media) => {
    console.log("media", media)
    return async (dispatch) => {
        if (getAuth()) {
            dispatch({ type: "CALLING__PROGRESS", payload: 10 });
            axios
                .post(`${SERVER}/coverPicture`, media, {
                    headers: { Authorization: `Bearer ${getAuth()}` }
                })
                .then((response) => {
                    dispatch({ type: "NO_ERROR" });
                    toast.success(response?.data?.success, { autoClose: 2000 })
                })
                .catch((error) => {
                    dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
                    dispatch({ type: "GOT_ERROR" });
                    console.log(error);
                });
        } else {
            toast.error("User not authenticated", {
                position: "top-center",
                autoClose: 2000,
                pauseOnHover: false,
            });
            console.log("user not authenticated");
        }
    }
}

// Dashboard card

export const userDashboardDetail = (token) => {
    // console.log("userDashboardDetail",getAuth())
    // console.log("dashboard")
    return async (dispatch, getState) => {
        dispatch({ type: "CALLING__PROGRESS", payload: 10 });
        dispatch({ type: "CALLING_USER_DASHBOARD_DETAIL" });
        axios
            .get(`${SERVER}/dashboard/mainDashboard`, {
                headers: { Authorization: `Bearer ${token ? token : getAuth()}` },
            })
            .then((response) => {
                dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
                dispatch({ type: "NO_ERROR" });
                if (response.data.errorCode === 200) {
                    dispatch({
                        type: "GETTING_USER_DASHBOARD_DETAIL",
                        payload: response.data,
                    });
                    dispatch({
                        type: "GETTING_NOTIFICATION_REQ",
                        payload: response.data.notificationData,
                    });
                } else {
                    toast.error(response.data.message, {
                        position: "top-center",
                        autoClose: 2000,
                        pauseOnHover: false,
                    });
                }
            })
            .catch((error) => {
                dispatch({ type: "GOT_ERROR" });
                console.log(error);
                dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
            });
    };
};

// handle work like

export const handleWorkLikes = (userId, workId, likeType, res) => {
    // console.log("dashboard")
    return async (dispatch, getState) => {
        axios
            .get(
                `${SERVER}/dashboard/mainDashboard/handleWorkLikes/${userId}?workId=${workId}&type=${likeType}`,
                {
                    headers: { authorization: `Bearer ${getAuth()}` },
                }
            )
            .then((response) => {
                if (response.data.errorCode === 200) res.send(200);
                if (response.data.errorCode === 300) res.send(200);
            })
            .catch((error) => {
                dispatch({ type: "GOT_ERROR" });
                console.log(error);
                dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
            });
    };
};

//  notificationRequest

export const notificationRequest = (debaunceStage, res) => {
    // console.log("dashboard")
    return async (dispatch, getState) => {
        axios
            .get(
                `${SERVER}/dashboard/notificationRequest?debaunceStage=${debaunceStage}`,
                {
                    headers: { authorization: `Bearer ${getAuth()}` },
                }
            )
            .then((response) => {
                if (!response.data.message.length) res.send(404);
                if (response.data.errorCode === 200)
                    dispatch({
                        type: "GETTING_NOTIFICATION_REQ",
                        payload: response.data,
                    });
                if (response.data.errorCode === 404)
                    dispatch(notificationRequest(0, { send: () => false }));
            })
            .catch((error) => {
                dispatch({ type: "GOT_ERROR" });
                console.log(error);
                dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
            });
    };
};

//handleNotification

export const handleNotification = (notificationId) => {
    // console.log("dashboard")
    return async (dispatch, getState) => {
        axios
            .get(`${SERVER}/dashboard/handleNotification/${notificationId}`, {
                headers: { authorization: `Bearer ${getAuth()}` },
            })
            .then((response) => {
                if (response.data.errorCode === 200)
                    dispatch(notificationRequest(0, { send: () => false }));
            })
            .catch((error) => {
                dispatch({ type: "GOT_ERROR" });
                console.log(error);
                dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
            });
    };
};

// global popUp

export const callPopUp = (PopUPName) => {
    // console.log(signup);
    let obj = { type: "CALL_POPUP", payload: PopUPName };
    return obj;
};

//  Music player
export const openMusicPlayer = (isOpen, data) => ({
    type: "OPEN_MUSIC_PLAYER",
    payload: { isOpen, data },
});

// Form global Modal
export const openGlobalFormModal = (isOpen, data) => ({
    type: "OPEN_FORM_MODAL",
    payload: { isOpen, data },
});

// getMessage of chatting
export const getMessage = (senderId, recieverId) => {
    console.log("id", senderId, recieverId);
    return async (dispatch, getState) => {
        axios
            .post(`${SERVER}/messages/getMsg`, {
                from: senderId ? senderId : await getUserId(),
                to: recieverId,
            })
            .then((response) => {
                console.log("message data", response);
                dispatch({ type: "GET_MESSAGE", payload: response.data });
            })
            .catch((error) => {
                dispatch({ type: "GOT_ERROR" });
                console.log(error);
                dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
            });
    };
};

// getUserList of chatting
export const getUserList = () => {
    return async (dispatch, getState) => {
        dispatch({ type: "CALL_GET_USER_LIST" });
        axios
            .get(`${SERVER}/messages/getUserList`, {
                headers: { authorization: `Bearer ${getAuth()}` },
            })
            .then((response) => {
                if (response.data.errorCode === 200) {
                    global.USER_LIST = response.data?.data?.getAllUserList;
                    dispatch({
                        type: "GET_USER_LIST",
                        payload: response.data?.data?.getAllUserList,
                    });
                }
            })
            .catch((error) => {
                dispatch({ type: "GOT_ERROR" });
                console.log(error);
                dispatch({ type: "COMPLETE__PROGRESS", payload: 100 });
            });
    };
};






export const homeSearch = (str, searchRoles) => {
    console.log('str 860', str, searchRoles)
    return async (dispatch) => {
        if (str) {
            dispatch({ type: 'CALLING_HOME_SEARCH' })
            try {

                axios.post(`${SERVER}/searchUser/${str}`, searchRoles)
                    .then((res) => {
                        console.log('res865', res)
                        dispatch({ type: 'GET_HOME_SEARCH', payload: res?.data?.data })

                    })

            } catch (error) {
                console.log('error while search in home', error)
                dispatch({ type: 'FAILED_HOME_SEARCH' })

            }
        }

    }
}



export const aiAllChatDetails = () => {
    return async (dispatch) => {
        try {
            axios.get(`${SERVER}/get_all_chat_with_Ai`, {
                headers: { Authorization: `Bearer ${await getAuth()}` }
            }).then(res => {
                dispatch({ type: 'GET_AI_ALL_CHAT', payload: res?.data?.data[0]?.messages })


            })

        } catch (error) {
            console.log('error', error)
        }

    }
}



export const aiAllChat = (query) => {
    return async (dispatch) => {
        if (query) {
            dispatch({ type: 'SET_AI_LAST_CHAT_QUERY', payload: query })
            dispatch({ type: 'CALLING_AI_ALL_CHAT' })

            try {

                fetch(`${SERVER}/meAi`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${getAuth()}`
                            // Add any additional headers as needed
                        },
                        body: JSON.stringify({ query: query }),
                    }


                ).then((res) => {
                    let lastChat = ''
                    const reader = res.body.getReader()
                    const read = () => {
                        reader.read().then(({ done, value }) => {
                            if (done) {
                                // console.log('end end')
                                // dispatch({type:'GET_AI_ALL_CHAT',payload:query})
                                dispatch(aiAllChatDetails())
                                return;
                            }
                            const decoder = new TextDecoder();
                            // console.log('recieved', decoder.decode(value))

                            dispatch({ type: 'GET_AI_LAST_CHAT', payload: decoder.decode(value) })

                            // console.log('value 107', value)
                            lastChat = lastChat.concat(decoder.decode(value))
                            // console.log('lastChat', lastChat)

                            read()
                        })
                    }
                    read()


                })



            } catch (error) {
                console.log('error in meAi', error)
                dispatch({ type: 'FAILED_AI_CHAT' })

            }


        }

    }
}

//get work favorite api
export const getAllFavWork = (category) => {
    return async (dispatch) => {
        try {
            axios.get(`${SERVER}/favorite/get-all-favorite/${category}`, {
                headers: { Authorization: `Bearer ${await getAuth()}` }
            }).then(res => {
                dispatch({ type: 'GET_ALL_FAV_WORK', payload: res?.data })
            }) 
        } catch (error) {
            
        }
    }
} 


