import React from 'react'
import './WVSocial.css'
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import RedditIcon from "@mui/icons-material/Reddit";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramIcon from "@mui/icons-material/Telegram";
import { useSelector } from 'react-redux';
const WVSocial = () => {
  const social = useSelector((state) => state?.getProfileData?.userData);
  const socialHandler = {
    Facebook: <FacebookIcon color="white" className="socialIcon" />,
    Twitter: <TwitterIcon color="white" className="socialIcon" />,
    Instagram: <InstagramIcon color="white" className="socialIcon" />,
    Pinterest: <PinterestIcon color="white" className="socialIcon" />,
    LinkedIn: <LinkedInIcon color="white" className="socialIcon" />,
    Youtube: <YouTubeIcon color="white" className="socialIcon" />,
    Reddit: <RedditIcon color="white" className="socialIcon" />,
    WhatsApp: <WhatsAppIcon color="white" className="socialIcon" />,
    Telegram: <TelegramIcon color="white" className="socialIcon" />,
    0: "link1",
    1: "link2",
    2: "link3",
  };
  const getLink = (a) => {
    let b = {
      0: `https://${social?.link1}`,
      1: `https://${social?.link2}`,
      2: `https://${social?.link3}`,
      3: `https://${social?.link4}`,
      4: `https://${social?.link5}`,
    };
    return b[a];
  };
  return (
    <div className="icon-bar">
      {social?.socialMedia && social?.socialMedia?.map(
        (icon_name, index) => (
          <a
            key={"link" + index}
            href={getLink(index)}
            target="_blank"
            className="profile-card-social__item instagram"
          >
            {socialHandler[icon_name]}
          </a>
        )
      )
      }
    </div>
  )
}

export default WVSocial