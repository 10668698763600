// export const SERVER = "http://34.242.111.55:3002"
// export const SERVER = "http://34.242.111.55:3007"
// export const SERVER = "http://34.242.111.55:3007"
export const SERVER = "https://app.mebookmeta.com"
// export const SERVER = "http://54.246.61.54:3002"  old ip
//export const SERVER = "http://192.168.29.54:3002" // for belforbes office   
// export const SERVER = "http://192.168.29.174:3002" // for gaurav sir 
// export const SERVER = "http://localhost:3007"
  // export const SERVER = "http://localhost:3002" //local backend port is 3002 not 3007
// 
// checked 20 jan


// export const CLIENTSERVER = "http://34.242.111.55:3000"
export const CLIENTSERVER=   'https://mebookmeta.com'
