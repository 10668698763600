let initialState = {
    userFavWork : []
}

const getAllFavWork = (state=initialState, action) =>{
    switch (action.type) {
        case "GET_ALL_FAV_WORK":
            return {
                ...state,
                userFavWork:action.payload
            }
    
        default:
            return state
    }
}

export default getAllFavWork;