import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '../../../contextApi/themeContext'; // Import the useTheme hook from your context
import { FaExternalLinkAlt } from "react-icons/fa";

const Zoom = () => {
    const { theme } = useTheme(); // Use the useTheme hook to access the theme from your context
    const [isPlaying, setIsPlaying] = useState(false); // Track video playback state

    

    return (
        <div className="w-full mb-6 flex lg:flex-row md:flex-row flex-col items-center justify-center lg:space-x-12 space-x-0 lg:space-y-0 space-y-4">
            <div className="w-full md:w-[80vw] lg:w-[80vw]">
                <video
                    src={'https://mebook-work-v2.s3.us-east-2.amazonaws.com/1726321479769-Welcome.mov'}
                    poster='/mepic.png'
                    controls
                    muted
                    loop
                    autoPlay
                    width="100%"
                    className="rounded-xl"
                    controlsList="nodownload"
                />
            </div>
            <div className="text-center md:w-[70vw] lg:w-[70vw]">
                <img 
                    className="rounded-xl hover:scale-95 duration-300 mx-auto w-full h-[40vh]" 
                    src="/zoom.png" 
                    alt="Zoom" 
                />
                <h2 className="text-3xl font-bold tracking-widest" style={{ color: theme === 'dark' ? 'white' : 'black' }}>
                    Zoom Meeting
                </h2>
                <p className="text-lg tracking-widest" style={{ color: theme === 'dark' ? 'white' : 'black' }}>
                    Zoom Meeting Every Tuesday for Potential, New and Existing MeBookMeta Users
                </p>
                <a 
                    href='https://us06web.zoom.us/j/89707399774?pwd=c0VYVnZUQVNLZGlGUTVKUEF4Ny9CZz09' 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="px-4 py-2 w-44 flex items-center justify-center text-white bg-gradient-to-r from-[#3279F5] to-black duration-300 cursor-pointer hover:rounded-md"
                >
                    Meeting Link <FaExternalLinkAlt className="ml-2" fontSize="14px" />
                </a>
            </div>
        </div>
    );
};

export default Zoom;
