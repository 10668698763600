// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prog_div {
        position: fixed;
        right: 0%;
        top: 0%;
        /* left:; */
        z-index: 9999999999999999;
        /* transition: all 0.8s; */
        width: 100%;
        height: 20px;
        /* transition: all 0.2s; */
        opacity: 1;
        /* background-color: blue; */


}


.progress {
        height: 10px;
        min-width:  100%;

}`, "",{"version":3,"sources":["webpack://./src/components/componentsC/uplProgBar/UplProgBar.css"],"names":[],"mappings":"AAAA;QACQ,eAAe;QACf,SAAS;QACT,OAAO;QACP,WAAW;QACX,yBAAyB;QACzB,0BAA0B;QAC1B,WAAW;QACX,YAAY;QACZ,0BAA0B;QAC1B,UAAU;QACV,4BAA4B;;;AAGpC;;;AAGA;QACQ,YAAY;QACZ,gBAAgB;;AAExB","sourcesContent":[".prog_div {\n        position: fixed;\n        right: 0%;\n        top: 0%;\n        /* left:; */\n        z-index: 9999999999999999;\n        /* transition: all 0.8s; */\n        width: 100%;\n        height: 20px;\n        /* transition: all 0.2s; */\n        opacity: 1;\n        /* background-color: blue; */\n\n\n}\n\n\n.progress {\n        height: 10px;\n        min-width:  100%;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
