// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back{
    background-color: rgb(193, 162, 162);
}


.css-ahj2mt-MuiTypography-root {
    margin: 0;
    color: whitesmoke;
}`, "",{"version":3,"sources":["webpack://./src/components/componentsA/muiAccordian/GlobalPop.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;AACxC;;;AAGA;IACI,SAAS;IACT,iBAAiB;AACrB","sourcesContent":[".back{\n    background-color: rgb(193, 162, 162);\n}\n\n\n.css-ahj2mt-MuiTypography-root {\n    margin: 0;\n    color: whitesmoke;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
