const initialState = {
    subCategory: false,
    loading: false
}

export const getSubCategory = (state = initialState, action) => {
    switch (action.type) {
        case "GET_SUBCATEGORY":
            return {
                ...state,
                subCategory: action.payload,
                loading: false
            }
        case "CALLING_SUBCATEGORY":
            return {
                ...state,
                loading: true
            }
            case "COMPLETE_SUBCATEGORY":
                return {
                    ...state,
                    loading: false
                }
       
        default:
            return state
    }
}
