import React from 'react'
import './NewBtn.css'
const NewBtn = ({onClick,text,className,style,comp}) => {
  return (
    
        <button className={className} style={{...style,display:'flex',alignItems:'center',justifyContent:'center',whiteSpace:'nowrap'}}
                  onClick={onClick}
                >
                  {text}
                  {comp}
                  
                </button>
   
  )
}

export default NewBtn