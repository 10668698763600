const initialState = {
    userWorkDetail: false,
    loading: false
}

export const userDashboardDetail = (state = initialState, action) => {
    switch (action.type) {
        case "CALLING_USER_DASHBOARD_DETAIL":
            return {
                ...state,
                loading: true
            }
        case "GETTING_USER_DASHBOARD_DETAIL":
            return {
                ...state,
                loading: false,
                userWorkDetail: action.payload,
            }
        case "CLEAR_DASHBOARD":
        return {
            ...state,
            loading: false,
            userWorkDetail:false,
        }
        default:
            return state
    }
}
