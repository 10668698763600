
const initialState = 1

export const sideBar = (state=initialState,action)=>{
    switch (action.type) {
        case "increament":
            return state=action.payload
    
        default:
            return state
    }
}