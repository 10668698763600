export const getDate = (str) => {
    // var str = "Fri Feb 08 2013 09:47:57 GMT +0530 (IST)";
    var date = new Date(str);
    var day = date.getDate(); //Date of the month: 2 in our example
    var month = date.getMonth(); //Month of the Year: 0-based index, so 1 in our example
    var year = date.getFullYear() //Year: 2013
    return `${day}/${month + 1}/${year}`;

}
export function formatTimestamp(timestamp) {
    const now = new Date();
    const providedDate = new Date(timestamp);

    const isToday = now.toDateString() === providedDate.toDateString();
    const isYesterday =
      new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 1
      ).toDateString() === providedDate.toDateString();

    let formattedTime = "";

    if (isToday) {
      formattedTime = providedDate.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
      });
      formattedTime += " today";
    } else if (isYesterday) {
      formattedTime = providedDate.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
      });
      formattedTime += " yesterday";
    } else {
      formattedTime = providedDate.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        day: "numeric",
        month: "numeric",
        year: "numeric",
      });
    }

    return formattedTime;
  }