import React from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";

function MainModal({ open, handleClose, Component  }) {
    const widthInRem = 22;
    // Calculate the width value in pixels
    const widthInPixels = `${widthInRem * parseFloat(getComputedStyle(document.documentElement).fontSize)
      }px`;
    const MianModalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: widthInPixels,
        bgcolor: "#2f2841",
        border: 'none',
        borderRadius: '5px',
        boxShadow: 24,
        padding: '1.5rem 1.5rem 0rem 1.5rem',
      };
      // console.log("mainModalref",ref)
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {
          Component
        }
          {/* <Component props={props} ref={ref}/> */}
      </Modal>
    </div>
  );
}

export default MainModal;
