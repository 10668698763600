const initialState = {
    isOpen: false,
    data: null,
};

const musicPlayer = (state = initialState, action) => {
    switch (action.type) {
        case "OPEN_MUSIC_PLAYER":
            return {
                ...state,
                isOpen: action.payload.isOpen,
                data: action.payload.data,
            };
        default:
            return state;
    }
};

export default musicPlayer;