import React, { useEffect, useState } from 'react'
import './ChatBox.scss'
import { Tooltip } from 'antd';
import axios from 'axios'
import { toast } from "react-toastify";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { getAuth, getUserData, getUserId } from '../../Action';
import { SERVER } from '../../server/server';
import { useDispatch, useSelector } from "react-redux";
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import { BackGround, newButton } from '../background';
// import MePic from '../../assets/MePic';
import image from '../../mepic.png';
import SendIcon from '@mui/icons-material/Send';
import ChatIcon from '@mui/icons-material/Chat';
//style
const DrawerStyle = { width: "21rem", ...BackGround, height: "100vh", color: "#fff", margin: "auto 0" };

// const mepicstyle = { height: '7rem', width: '7rem', display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 'auto', padding: '7px', marginTop: "1rem" };

const layoutStyle = { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"};

const textareastyle = { border: "2px solid #1C6DD0", borderRadius: "5px", paddingLeft: "10px" , color:'black'};

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: "15rem", color: "blue",
        }}
        spin
    />
);

const spinStyle = { display: 'flex', justifyContent: "center", alignItems: "center", minHeight: "100vh", minWidth: "100wh", position: "absolute", left: 0, right: 0, top: 0, bottom: 0, margin: "auto", zIndex: 9999, };


const ChatBox = () => {
    const [state, setState] = useState({
        right: false,
    });

    const [help, setHelp] = useState('')
    const [loading, setLoading] = useState(false)
    const STORE = useSelector((state) => state?.getProfileData);
    // console.log('STORE', STORE)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUserData())
    }, [])
    // console.log('e.target.help', help, STORE?.getProfileData?.userData?.email)

    const handleSubmit = async (event) => {
        event.preventDefault();
        setHelp(help)
        setLoading(true)
        if (getAuth()) {
            axios.post(`${SERVER}/supportEngine/${await getUserId()}`, { data: help, mail: STORE?.userData?.email })
                .then((res) => {
                    toast.success(res.data.message, {
                        position: 'top-center',
                        autoClose: 2000,
                        pauseOnHover: false
                    })
                    setLoading(false)
                    event.target.reset();
                }).catch((e) => {
                    console.log(e)
                })
        }
        else {
            toast.success('Please Sign In first', {
                position: 'top-center',
                autoClose: 2000,
                pauseOnHover: false
            })
            setLoading(false)
        }
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };


    return (
        <>
            <div>
                {
                    loading ? <Box sx={spinStyle}><Spin indicator={antIcon} /></Box> : ""
                }

                <div className='float-btn' onClick={toggleDrawer('right', true)}>
                    <Tooltip title="Help Desk" color='#1C6DD0'>
                        <Fab size="medium" sx={{ background: "#1C6DD0", '&:hover': { background: '#19A7CE' } }}>
                            <ChatIcon sx={{ color: "#fff" }} />
                        </Fab>
                    </Tooltip>
                </div>
                <Drawer
                    anchor={'right'}
                    open={state['right']}
                    onClose={toggleDrawer('right', false)}
                >
                    <Box sx={DrawerStyle}>
                        <div className='text-desc mx-auto my-auto' style={{ position: "absolute", right: 0, cursor: "pointer" }}>
                            <CloseIcon onClick={toggleDrawer("right", false)} />
                        </div>
                        <div className='layout' style={layoutStyle}>
                            <div className="mt-5 mx-auto d-flex justify-content-center align-items-center">
                                <img
                                    src={image}
                                    alt="image"
                                    style={{
                                        width: "40%",
                                        height: "30%",
                                        margin: 'auto',
                                    }}
                                />
                            </div>

                            <div className=' mt-3 text-desc mx-auto d-flex justify-content-center align-items-center' style={{ padding: '1rem', textAlign: 'justify', }}>
                                <h6>
                                    Hi, this is <span style={{ color: "red" }}><b>Ron</b></span> from the <span style={{ color: "red" }}><b>M</b></span><span style={{ color: "#1C6DD0" }}><b>ebook</b></span><span style={{ color: "red" }}><b>M</b></span><span style={{ color: "#1C6DD0" }}><b>eta</b></span> support team. Please explain
                                    your current issue to me or ask me any questions you have. I will work to resolve it as soon as
                                    possible. 👋
                                </h6>
                            </div>
                            <div
                            >
                                <form
                                    onSubmit={e => {
                                        handleSubmit(e)
                                    }}
                                >
                                    <div style={{ marginTop: '0px', padding:'1rem', display: "flex", justifyContent: 'center', alignItems: "center", flexDirection: 'column', gap: "1rem" }}>

                                        <textarea style={textareastyle} value={help} name="help" id="" cols="35" rows="7" placeholder="Enter your text here"
                                            onChange={e => setHelp(e.target.value)}></textarea>
                                            <div>
                                                <button style={{...newButton,textAlign:'end',width:'7rem'}} type='submit'>
                                                    Send <SendIcon sx={{ color: "#fff" }} />
                                                </button>
                                            </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </Box>
                </Drawer>
            </div>
        </>
    )
}

export default ChatBox