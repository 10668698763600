const initialState = {
    subCategory: false,
    loading: false
}

export const mebookProfile = (state = initialState, action) => {
    switch (action.type) {
        case "CALLING_METAPROFILE":
            return {
                ...state,
                loading: true
            }
        case "GETTING_METAPROFILE":
            return {
                ...state,
                loading: false,
                subCategory: action.payload,
            }
        default:
            return state
    }
}
