import React, { useEffect, useRef, useState } from 'react'
import './PlayerIcon.css'
import { FaPlayCircle } from "react-icons/fa";
import { MdLibraryMusic } from "react-icons/md";

import { FaPlay } from "react-icons/fa6";
import { TbPlayerTrackNextFilled } from "react-icons/tb";
import { BiSolidSkipPreviousCircle } from "react-icons/bi";
import { FaForward } from "react-icons/fa";
import { FaBackward } from "react-icons/fa";
import Fab from '@mui/material/Fab';
import { MdCancel } from "react-icons/md";
import { FaPause } from "react-icons/fa";

import { useDispatch, useSelector } from 'react-redux';
import { getWishlistByCategoryAction } from '../../../Action/wishlist/getWishlistByCategoryAction';
import ReactPlayer from 'react-player';


const PlayerIcon = () => {
    const [show, setShow] = useState(false)
    const dispatch = useDispatch()
    const musicList = useSelector((state) => state?.musicList)
    const playerRef = useRef(null);
    const [progress, setProgress] = useState(0);
    const [duration, setDuration] = useState(0);
    const handleProgress = (state) => {
        if (!state) return;
        const { played } = state;
        // setProgress(played * 100);
        dispatch({ type: 'MUSIC_PROGRESS', payload: { progress: played * 100 } })

    };

    const handleDuration = (duration) => {
        // setDuration(duration);
        dispatch({ type: 'MUSIC_DURATION', payload: { duration } })

    };
    const handleOnEnded = () => {
        dispatch({ type: 'MUSIC_SET_NEXT' })
    }
    useEffect(() => {
        dispatch(getWishlistByCategoryAction('Music'))
    }, [])
    return (
        <>
            {localStorage.getItem('token') && musicList?.count > 0 &&
                <div className='playerIcon_main'>

                    {!show ?

                        <Fab size="medium" sx={{ background: "#fff", '&:hover': { background: 'none' } }}
                            onClick={() => setShow(!show)}
                        >
                            <MdLibraryMusic
                                style={{ fontSize: '2.5rem', color: 'red', cursor: 'pointer' }}
                            />
                        </Fab>
                        :
                        <Fab size="small" sx={{ background: "none", '&:hover': { background: 'none' }, boxShadow: 'none' }}
                            onClick={() => setShow(!show)}
                        >
                            <MdCancel
                                style={{ fontSize: '1rem', color: 'red', cursor: 'pointer' }}
                            />
                        </Fab>
                    }


                    {show &&

                        <div className='playerIcon_player'>
                            <div className='d-flex align-items-center justify-content-around'
                                style={{
                                    width: '100%'
                                }}
                            >
                                <div>
                                    <img
                                        src={musicList?.musicArray[musicList?.currentIndex]?.coverPicture}
                                        style={{
                                            width: '30px',
                                            height: '30px'
                                        }}
                                    />
                                </div>

                                <div>
                                    <marquee behavior="scroll" direction="left">
                                        {musicList?.musicArray[musicList?.currentIndex]?.title}
                                    </marquee>
                                </div>


                            </div>
                           <div className='d-flex align-items-start justify-content-start' style={{width:'100%'}}>
                           <div
                                style={{
                                    width: `${musicList?.progress}%`, height: '2px',
                                    background: 'linear-gradient(to bottom right, red, blue)',
                                    // position: 'absolute', top: 0, left: 0,
                                    borderRadius: '5px',
                                    textAlign:'left'

                                }}
                            ></div>
                           </div>
                            <div className='playerIcon_icon_div d-flex align-items-center justify-content-around'>
                                <div>
                                    <FaBackward className='playerIcon_icon' onClick={() => dispatch({ type: 'MUSIC_SET_PREVIOUS' })}
                                    />
                                </div>
                                <div>
                                    {musicList?.play ?
                                        <FaPause className='playerIcon_icon' onClick={() => dispatch({ type: 'MUSIC_STATUS_TOGGLE' })} />
                                        :
                                        <FaPlay className='playerIcon_icon' onClick={() => dispatch({ type: 'MUSIC_STATUS_TOGGLE' })} />
                                    }
                                </div>
                                <div>
                                    <FaForward className='playerIcon_icon' onClick={() => dispatch({ type: 'MUSIC_SET_NEXT' })}
                                    />
                                </div>
                            </div>

                        </div>
                    }
                    <ReactPlayer
                        ref={playerRef}

                        url={musicList?.musicArray[musicList?.currentIndex]?.userWork}
                        controls={true}
                        playing={musicList?.play}
                        width='0px'
                        height='0px'
                        onProgress={handleProgress}
                        onDuration={handleDuration}
                        onEnded={handleOnEnded}
                    />
                </div>
            }
        </>
    )
}

export default PlayerIcon