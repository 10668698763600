const initialState = {
    current: '',
    next: '',
    play: false,
    stateId: []
}

const pitchAlley = (state = initialState, action) => {
    function isLast(e) {
        if (e === state.stateId.length) {
            return state.stateId[0]
        } else if (e < 0) {
            return state.stateId[state.stateId.length - 1]

        } else {
            return state.stateId[e]
        }
    }

    switch (action.type) {
        case 'SET_PITCH_ALLEY_DATA':
            // console.log(" pl red", action.payload)
            // let { filteredByCat, users } = action.payload
            // if (filteredByCat.length>0) {
            //     users = users?.filter((item) => filteredByCat?.includes(item?._id))
            // }


            // return {
            //     users: users,
            //     loading: false
            // }
            let { filteredByCat, resultWithUserId } = action.payload;
            // console.log(filteredByCat, resultWithUserId)
            if (filteredByCat?.length>0) {
                resultWithUserId = resultWithUserId?.filter((item) => filteredByCat?.includes(item?.userId?._id))
            }

            const pitchArray = resultWithUserId?.filter(
                (i) =>
                    i?.pitchVdo !== "" &&
                    i?.pitchVdo?.split(".")[
                    i?.pitchVdo?.split(".")?.length - 1
                    ] !== "jpg"
            )?.map((item) => {
                return(
                    {pitchVdo:item.pitchVdo,userId:item.userId._id,name:item.userId.name}
                )
            })

            return {
                ...state, stateId: pitchArray,
                current: pitchArray[0]
            }


        case 'SET_NEXT_TO_CURRENT_PITCH_ALLLEY':
            const findNext = state.stateId.findIndex((i) => i === state.current) + 1

            return {
                ...state, current: isLast(findNext)
            }
        case 'SET_PREV_TO_CURRENT_PITCH_ALLLEY':
            const findPrev = state.stateId.findIndex((i) => i === state.current) - 1

            return {
                ...state, current: isLast(findPrev)
            }

        case 'SET_TRUE_PITCH_ALLEY':
            return {
                ...state, play: true
            }
        case 'SET_FALSE_PITCH_ALLEY':
            return {
                ...state, play: false
            }

        // case 'SET_NEXT_PITCH_ALLLEY':
        //     const findNext=state.stateId.findIndex((i)=>i===state.current)+1
        //     return{
        //         ...state,current:isLast(findNext)
        //     }

        // case 'SET_CURRENT_PLAY_ID':
        //     const nextId = state.stateId.findIndex((i) => i === action.payload) + 1

        //     return {
        //         ...state, play: true, current: action.payload, next: isLast(nextId)

        //     }

        // case 'SET_CURRENT_PLAY_NEXT':
        //     const nextIdNext = state.stateId.findIndex((i) => i === state.next) + 1

        //     return {
        //         ...state, current: state.next, next: isLast(nextIdNext)

        //     }

        case 'SET_PITCH_ALLEY_CLEAR':
            return {
                ...state, play: false
            }

        default:
            return state
    }

}
export default pitchAlley