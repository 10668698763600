import React from 'react'
import './AskAI.css'

const TypingAnimation = () => {
    return (
        <>
            <div class="chat-bubble">
                <div class="typing">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
            </div>
        </>
    )
}

export default TypingAnimation