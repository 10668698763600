import React from "react";
function MainIcon({ top, bottom, left, right, width, zIndex }) {
  return (
    <>
      <div>
        <img
          src="https://mebookmeta.com/static/media/mepic.cd7e3c61af1b2437a59d.png"
          alt="hospital-icon"
          style={{
            // position: "absolute",
            top: top,
            bottom: bottom,
            left: left,
            right: right,
            width: width,
            zIndex: zIndex,
          }}
        />
      </div>
    </>
  );
}

export default MainIcon;
