import React from 'react'
import pic from '../mepic.png'
const MePic = ({style}) => {
  return (
    <div style={style}>
        <img src={pic} style={{height:'80%',width:'90%'}}/>
    </div>
  )
}

export default MePic