import React from 'react';
import './Gpp.css';
import Player from './player/Player';
// import { songsdata } from './player/audios';
import { useRef, useState, useEffect } from 'react';
import { SERVER } from '../../../../server/server';
import { getImgURL } from '../../../../util/getImgUrl';


const AudioPlayer = ({ file, coverPicture, title, name }) => {



    const songsdata = [
        {
            "title": `${title}`,
            "url": getImgURL(file),
            "coverPicture": getImgURL(coverPicture),
            "name": `${name}`
        },
        {
            "title": `${title}`,
            "url": getImgURL(file),
            "coverPicture": getImgURL(coverPicture),
            // "url": `${SERVER}/uploads/${file}`,
            // "coverPicture": `${SERVER}/uploads/${coverPicture}`,
            "name": `${name}`

        },
        {
            "title": `${title}`,
            "url": getImgURL(file),
            "coverPicture": getImgURL(coverPicture),
            // "url": `${SERVER}/uploads/${file}`,
            // "coverPicture": `${SERVER}/uploads/${coverPicture}`,
            "name": `${name}`

        }
    ]
    const [songs, setSongs] = useState({
        "title": `${title}`,
        "url": getImgURL(file),
        "coverPicture": getImgURL(coverPicture),
        // "url": `${SERVER}/uploads/${file}`,
        // "coverPicture": `${SERVER}/uploads/${coverPicture}`,
        "name": `${name}`

    });
    const [isplaying, setisplaying] = useState(false);
    const [currentSong, setCurrentSong] = useState({
        "title": `${title}`,
        "url": getImgURL(file),
        "coverPicture": getImgURL(coverPicture),
        // "url": `${SERVER}/uploads/${file}`,
        // "coverPicture": `${SERVER}/uploads/${coverPicture}`,
        "name": `${name}`

    });

    const audioElem = useRef();

    useEffect(() => {
        if (isplaying) {
            audioElem.current.play();
        }
        else {
            audioElem.current.pause();
        }
    }, [isplaying])

    const onPlaying = () => {
        const duration = audioElem.current.duration;
        const ct = audioElem.current.currentTime;
        setCurrentSong({ ...currentSong, "progress": ct / duration * 100, "length": duration, 'currentTime': ct })
        if (ct === duration) {
            setisplaying(false)
        }
    }



    useEffect(() => {
        setisplaying(true)
        onPlaying()
    }, [])
    return (
        <>
            <style>{
                `.Gpp:before,
            .Gpp:after{
                display:${!isplaying ? 'none' : 'flex'}
            }
            
            `}
            </style>

            <div className="Gpp" >
                <audio src={currentSong.url} ref={audioElem} onTimeUpdate={onPlaying} />
                <Player songs={songs} setSongs={setSongs} isplaying={isplaying} setisplaying={setisplaying} audioElem={audioElem} currentSong={currentSong} setCurrentSong={setCurrentSong} />
            </div>
        </>
    );
}


export default AudioPlayer