let initialState = {
    progress:0,
    display:"none"
}

const globalProgress = (state=initialState,action) =>{
    switch (action.type) {
        case "CALLING__PROGRESS":
            return {
                ...state,
                progress:action.payload,
                display:"block"
            }
        case "COMPLETE__PROGRESS":
            return {
                ...state,
                progress:action.payload?action.payload:0,
                display:action.payload?'block':'none'
            }
        default:
            return state
    }
}

export default globalProgress;