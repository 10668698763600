import React, { useState } from 'react';
import { useTransition, animated } from '@react-spring/web';
import { useTheme } from '../../../contextApi/themeContext';

const ImageToVideo = ({
    videoUrl,
    imageUrl,
    heading,
    description
}) => {
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const { theme } = useTheme();
  // Transition for image to video
  const transitions = useTransition(isVideoVisible, {
    from: { opacity: 0, transform: 'translateX(100px)' },
    enter: { opacity: 1, transform: 'translateX(0)' },
    config: { duration: 800 },
  });

  const handleImageClick = () => {
    setIsVideoVisible((prev)=>!prev);
  };

  return (
    <div id="tab1" className={`tab-content`}>
      <div className="space-y-2 text-center">
        <h2 className="text-2xl text-black font-bold" style={{ color: theme === 'dark' ? 'white' : 'black' }}>
          {heading}
        </h2>
        <h4 className="text-lg text-gray-600 tracking-widest lg:px-8 xl:px-32" style={{ color: theme === 'dark' ? 'white' : 'black' }}>
          {description}
        </h4>

        {transitions((style, item) =>
          !item ? (
            <animated.div
                className="relative mx-auto w-[70vw] h-[70vh] cursor-pointer group"
                style={style}
                onClick={handleImageClick}
                >
                <img
                    src={imageUrl}
                    alt="image"
                    className="mx-auto w-[70vw] h-[70vh] rounded-lg"
                />

                {/* Dark overlay with opacity transition */}
                <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-40 transition-opacity duration-300 rounded-lg flex items-center justify-center">
                    <span className="text-white text-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    Click to watch video 
                    </span>
                </div>
                </animated.div>
          ) : (
            <animated.div
                className="relative mx-auto w-[70vw] h-[70vh] cursor-pointer group"
                style={style}
                >
                <video
                    src={videoUrl}
                    controls
                    autoPlay
                    controlsList="nodownload"
                    className="mx-auto w-[70vw] h-[70vh] rounded-lg"
                    style={style}
                />

                {/* Back button placed in the top-right corner */}
                <div onClick={handleImageClick} className="absolute top-0 right-0 m-4 bg-black bg-opacity-60 hover:bg-opacity-80 transition-opacity duration-300 rounded-full p-2 cursor-pointer">
                    <span className="text-white text-sm">Back</span>
                </div>
            </animated.div>
          )
        )}
      </div>
    </div>
  );
};

export default ImageToVideo;
