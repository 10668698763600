import DashboardIcon from '@mui/icons-material/Dashboard';
import CampaignIcon from '@mui/icons-material/Campaign';
import Person3Icon from '@mui/icons-material/Person3';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import LogoutIcon from '@mui/icons-material/Logout';
import InfoIcon from '@mui/icons-material/Info';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LanguageIcon from '@mui/icons-material/Language';
import MainIcon from '../../../components/componentsC/mainIcon/MainIcon';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import PublicIcon from '@mui/icons-material/Public';
import FavoriteIcon from '@mui/icons-material/Favorite';

export const sidebarItem = [
    {
        id: 1,
        listItem: 'Home',
        link: '',
        icon: <DashboardIcon sx={{ mr: 1, width: 30, height: 30, color: "#1C6DD0" }} />

    },
    {
        id: 13,
        listItem: 'Dashboard',
        link: 'AboutMe',

        icon: <InfoIcon sx={{ mr: 1, width: 30, height: 30, color: "#1C6DD0" }} />


    },
    {
        id: 2,
        listItem: 'My Profile',
        link: 'UserProfile',
        icon: <Person3Icon sx={{ mr: 1, width: 30, height: 30, color: "#1C6DD0" }} />


    },
    {
        id: 4,
        listItem: 'My Work',
        link: 'WriterStoryDetails',
        icon: <ChromeReaderModeIcon sx={{ mr: 1, width: 30, height: 30, color: "#1C6DD0" }} />


    },
    {
        id: 4,
        listItem: 'My Posts',
        link: 'post',
        icon: <ChromeReaderModeIcon sx={{ mr: 1, width: 30, height: 30, color: "#1C6DD0" }} />


    },
    {
        id: 3,
        listItem: 'My Pitch',
        link: 'MyPitch',
        icon: <CampaignIcon sx={{ mr: 1, width: 30, height: 30, color: "#1C6DD0" }} />

    },
    
    {
        id: 14,
        listItem: 'My Ask',
        link: 'MyAsk',
        icon: <AssignmentIcon sx={{ mr: 1, width: 30, height: 30, color: "#1C6DD0" }} />

    },

   
    
    {
        id: 15,
        listItem: 'MeBookMetaAI',
        link: 'chat-with-me',
        icon: <MainIcon width={'40px'} style={{ marginRight: '8px', width: 30, height: 30, color: "#1C6DD0" }} />


    },
    {
        id: 16,
        listItem: 'Pitch Alley',
        link: 'pitchAlley',
        icon: <VideoLibraryIcon sx={{ mr: 1, width: 30, height: 30, color: "#1C6DD0" }} />

    },
    {
        id: 17,
        listItem: 'Ask The Universe',
        link: 'myask-universe',
        icon: <PublicIcon sx={{ mr: 1, width: 30, height: 30, color: "#1C6DD0" }} />

    },
    {
        id: 18,
        listItem: 'Wishlist',
        link: 'Wishlist',
        icon: <FavoriteIcon sx={{ mr: 1, width: 30, height: 30, color: "red" }} />

    },
    {
        id: 11,
        listItem: 'Feedback',
        link: 'feedback',
        icon: < FeedbackIcon sx={{ mr: 1, width: 30, height: 30, color: "#1C6DD0" }} />


    },
    {
        id: 12,
        listItem: 'LogOut',
        link: 'LogOut',
        icon: < LogoutIcon sx={{ mr: 1, width: 30, height: 30, color: "#1C6DD0" }} />
    }
]
export const sidebarItemVis = [
    {
        id: 1,
        listItem: 'Home',
        link: '',
        icon: <DashboardIcon sx={{ mr: 1, width: 30, height: 30, color: "#1C6DD0" }} />

    },
    {
        id: 13,
        listItem: 'Dashboard',
        link: 'AboutMe',

        icon: <InfoIcon sx={{ mr: 1, width: 30, height: 30, color: "#1C6DD0" }} />


    },
    // {
    //     id: 3,
    //     listItem: 'My Pitch',
    //     link: 'MyPitch',
    //     icon: <CampaignIcon sx={{ mr: 1, width: 30, height: 30, color: "#1C6DD0" }} />

    // },
    {
        id: 2,
        listItem: 'My Profile',
        link: 'UserProfile',
        icon: <Person3Icon sx={{ mr: 1, width: 30, height: 30, color: "#1C6DD0" }} />


    },
    {
        id: 15,
        listItem: 'MeBookMetaAI',
        link: 'chat-with-me',
        icon: <MainIcon width={'40px'} style={{ marginRight: '8px', width: 30, height: 30, color: "#1C6DD0" }} />


    },
    // {
    //     id: 14,
    //     listItem: 'My Ask',
    //     link: 'MyAsk',
    //     icon: <AssignmentIcon sx={{ mr: 1, width: 30, height: 30, color: "#1C6DD0" }} />

    // },

    // {
    //     id: 4,
    //     listItem: 'My Work',
    //     link: 'WriterStoryDetails',
    //     icon: <ChromeReaderModeIcon sx={{ mr: 1, width: 30, height: 30, color: "#1C6DD0" }} />


    // },
    {
        id: 11,
        listItem: 'Feedback',
        link: 'feedback',
        icon: < FeedbackIcon sx={{ mr: 1, width: 30, height: 30, color: "#1C6DD0" }} />


    },
    {
        id: 16,
        listItem: 'Pitch Alley',
        link: 'pitchAlley',
        icon: <VideoLibraryIcon sx={{ mr: 1, width: 30, height: 30, color: "#1C6DD0" }} />

    },
    {
        id: 17,
        listItem: 'MyAsk Universe',
        link: 'myask-universe',
        icon: <PublicIcon sx={{ mr: 1, width: 30, height: 30, color: "#1C6DD0" }} />

    },
    {
        id: 18,
        listItem: 'Wishlist',
        link: 'Wishlist',
        icon: <FavoriteIcon sx={{ mr: 1, width: 30, height: 30, color: "red" }} />

    },
    {
        id: 12,
        listItem: 'LogOut',
        link: 'LogOut',
        icon: < LogoutIcon sx={{ mr: 1, width: 30, height: 30, color: "#1C6DD0" }} />
    }
]

export const searchDataItem = [
    {
        "title": "Things Fall Apart"
    },
    {
        "title": "Fairy tales"
    },
    {
        "title": "The Divine Comedy"
    },
    {
        "title": "The Epic Of Gilgamesh"
    },
    {
        "title": "The Book Of Job"
    },
    {
        "title": "One Thousand and One Nights"
    },
    {
        "title": "Nj\u00e1l's Saga"
    },
    {
        "title": "Pride and Prejudice"
    }
]

export const suggestionDataItem = [
    {
        "id": "suggestionDataItem" + 1,
        "title": "Main Stage",
        "query": 1
    },
    {
        "id": "suggestionDataItem" + 2,
        "title": "Bleeding Edge",
        "query": 2

    },
    {
        "id": "suggestionDataItem" + 3,
        "title": "Specialist",
        "query": 3
    },
    {
        "id": "suggestionDataItem" + 4,
        "title": "Multi-Discipline",
        "query": 4
    },
    {
        "id": "suggestionDataItem" + 5,
        "title": "Organic",
        "query": 5
    },
    {
        "id": "suggestionDataItem" + 6,
        "title": "At-Large",
        "query": 6
    }
]

export const suggestionCategoryItem = [
    {
        "id": "suggestionCategoryItem" + 1,
        "title": "Author",
        "query": 7
    },
    {
        "id": "suggestionCategoryItem" + 2,
        "title": "Writer",
        "query": 8
    },
    {
        "id": "suggestionCategoryItem" + 3,
        "title": "Music",
        "query": 9
    },
    {
        "id": "suggestionCategoryItem" + 4,
        "title": "Audio",
        "query": 10
    },
    {
        "id": "suggestionCategoryItem" + 5,
        "title": "Music Video",
        "query": 11
    },
    {
        "id": "suggestionCategoryItem" + 6,
        "title": "Film",
        "query": 12
    },
    {
        "id": "suggestionCategoryItem" + 7,
        "title": "Television",
        "query": 13
    },
    {
        "id": "suggestionCategoryItem" + 8,
        "title": "Print or Media",
        "query": 14
    },
    {
        "id": "suggestionCategoryItem" + 9,
        "title": "Visual Arts",
        "query": 15
    },
    {
        "id": "suggestionCategoryItem" + 10,
        "title": "Performing Arts",
        "query": 16
    },

]

export const suggestionStatusItem = [
    {
        "id": "suggestionStatusItem" + 1,
        "title": "Creator",
        "query": '17'
    },
    {
        "id": "suggestionStatusItem" + 2,
        "title": "Collaborator",
        "query": '18'
    },
    {
        "id": "suggestionStatusItem" + 3,
        "title": "Influencer",
        "query": '19'
    },
    {
        "id": "suggestionStatusItem" + 4,
        "title": "Project Developer",
        "query": '20'
    },
    {
        "id": "suggestionStatusItem" + 5,
        "title": "Provider",
        "query": '21'
    },
    {
        "id": "suggestionStatusItem" + 6,
        "title": "Consumer",
        "query": '22'
    },

]


export const suggestionSubcategoryItem = [
    {
        "id": "suggestionCategoryItem" + 8,
        "title": "Autobiography & Biography",
        "query": '63c4405c5c70358dd4ce0c63'
    },
    {
        "id": "suggestionCategoryItem" + 9,
        "title": "Film Biographical",
        "query": '6404d5c7157c2e8322a45ba7'
    },
    {
        "id": "suggestionCategoryItem" + 10,
        "title": "Artist Or Performer String Instruments",
        "query": '63e58362887e336fa3b83814'
    },
    {
        "id": "suggestionCategoryItem" + 11,
        "title": "Woodwind Instruments",
        "query": '63e7575807c1e1bb966bfdfe'
    },
    {
        "id": "suggestionCategoryItem" + 12,
        "title": "Wi Flutes",
        "query": '63e75df007c1e1bb966bff8e'
    },
    {
        "id": "suggestionCategoryItem" + 13,
        "title": "String Instruments Plucked Or Strummed Guitar",
        "query": '63e75cb307c1e1bb966bff4c'
    },
    {
        "id": "suggestionCategoryItem" + 14,
        "title": "Network News Broadcast Media",
        "query": '6409ebe52ff2e4de280914d5'
    },
]

// Enhanved payments details
export const EplanData = [
    { title: 'Share Your Work Instantly', applied: true },
    { title: 'Share Your Pitch with MeBookMeta Universe', applied: true },
    { title: 'Share Your Ask with MeBookMeta Universe', applied: true },
    { title: 'Work Accessible and Promoted on Platform', applied: true },
    { title: 'Access Your MeBookMeta Dashboard/Back Office', applied: true },
    { title: 'Eliminate the cost of a website', applied: true },
    { title: 'Increase your Traffic/Connectivity', applied: true },
    { title: 'Define Your Work in Profile', applied: true },
    { title: 'Define Your Audience', applied: true },
    { title: 'Define Your Brand', applied: true },
    { title: 'Create More Opportunities', applied: true },
    { title: 'Take Control of Your Project’s Marketing and Promotion', applied: true },
    { title: 'Include Your Work on a Larger Platform', applied: true },
    { title: 'Engage MeBookMeta’s Unique AI Search Engine to discover and link to new Friends, Collaborators, Providers, Experts and Customers', applied: true },
    { title: 'Message other MeBookMeta Users, Collaborators and Customers', applied: true },
    { title: 'Sell Your Work Instantly (digital and services)', applied: true },
    { title: 'Earn More Money for Your Work', applied: true },
    { title: 'Benefit from More Expert Advice, Information, Features and Articles', applied: true },
    { title: 'See Your Work Cycled-In on MeBookMeta daily promotions', applied: true },

]

export const cardData = [
    {
        logo: <ChromeReaderModeIcon />,
        title: "Share Your Work Instantly",
        content: "If you are an author, singer, actor, dancer, reporter, photographer, spoken word performer, graphic artist, filmmaker, director, instrumentalist or contributor in any creative endeavor, MeBookMeta will allow you to share your work instantly and on-the-spot with new contacts and audiences."
    },
    {
        logo: <CampaignIcon />,
        title: "Share Your Pitch with the MeBookMeta Universe.",
        content: "Pitch yourself and your work to a greater audience. The Global Media Marketplace (“Pitch” video and text options included). Your pitch will tell others who you are, what you do, why you do it, what makes you unique and why they should be interested in you and what you are selling."
    },
    {
        logo: <LanguageIcon />,
        title: "Creating a Following Expanding Your Audience",
        content: "Eliminate the need, costs and complications of a website. Share your Profile, Pitch, Ask and Work Samples directly with new contacts and customers. Immediately increase your connectivity and productivity!"
    },
    {
        logo: <ChromeReaderModeIcon />,
        title: "Present Your Work Samples in Attractive and Effective Formats",
        content: "Sharing Work Samples involves simple uploads. Text (PDF), Music/Audio (MP3), Visual (Photo) and Mixed Media/Live Performance (Video). Sample sizes are restricted so the MeBookMeta focus is on sharing and selling you and your work.."
    },
    {
        logo: <ChromeReaderModeIcon />,
        title: "Website? Yes… Website Traffic? Sales? No.",
        content: "Eliminate the need, costs and complications of a website and the expense and efforts of driving traffic to your website. Being part of a well-trafficked platform makes you and your work (Enhanced Profile) searchable and discoverable to other MeBookMeta users and site visitors."
    },
    {
        logo: <ChromeReaderModeIcon />,
        title: "Ask the Universe…",
        content: "Pitch yourself and your work to a greater audience. The Global Media Marketplace (“Pitch” video and text options included). Seek an audience, assistance, collaboration, friends, providers, opportunities and answers by sharing your “Ask” with the Universe!"
    },
    {
        logo: <ChromeReaderModeIcon />,
        title: "Share Your Work, Sell Your Work",
        content: "Enhanced profile users also benefit from e-Commerce features that allow the sales of work and services and on-site promotions. Enhanced profile users can also message other  users and take advantage of upcoming collaboration and meeting features on the site."
    },
    {
        logo: <ChromeReaderModeIcon />,
        title: "And You Shall Receive",
        content: "MeBookMeta allows Enhanced profile users to make specific requests of other users, friends, visitors and the greater global marketplace. Similar to the “Pitch,” the “Ask” (video and text options included) allows Enhanced users to share needs, invitations, events, campaigns and future works with the Universe. The “Ask” also helps users focus for others what is needed and ways that will help bring desired and defined success."
    },
    // {
    //     logo: <ChromeReaderModeIcon />,
    //     title: "Benefit from More Expert",
    //     content: "Benefit from More Expert Advice, Information, Features and Articles"
    // },
    // {
    //     logo: <ChromeReaderModeIcon />,
    //     title: "See Your Work Cycled",
    //     content: "See Your Work Cycled-In on MeBookMeta daily promotions"
    // },
]
export const SplanData = [
    { title: 'Share Your Work Instantly', applied: true },
    { title: 'Share Your Pitch with MeBookMeta Universe', applied: true },
    { title: 'Share Your Ask with MeBookMeta Universe', applied: false },
    { title: 'Work Accessible and Promoted on Platform', applied: true },
    { title: 'Access Your MeBookMeta Dashboard/Back Office', applied: true },
    { title: 'Eliminate the cost of a website', applied: true },
    { title: 'Increase your Traffic/Connectivity', applied: true },
    { title: 'Define Your Work in Profile', applied: true },
    { title: 'Define Your Audience', applied: true },
    { title: 'Define Your Brand', applied: true },
    { title: 'Create More Opportunities', applied: false },
    { title: 'Take Control of Your Project’s Marketing and Promotion', applied: false },
    { title: 'Include Your Work on a Larger Platform', applied: false },
    { title: 'Engage MeBookMeta’s Unique AI Search Engine to discover and link to new Friends, Collaborators, Providers, Experts and Customers', applied: false },
    { title: 'Message other MeBookMeta Users, Collaborators and Customers', applied: false },
    { title: 'Sell Your Work Instantly (digital and services)', applied: 'false' },
    { title: 'Earn More Money for Your Work', applied: false },
    { title: 'Benefit from More Expert Advice, Information, Features and Articles', applied: false },
    { title: 'See Your Work Cycled-In on MeBookMeta daily promotions', applied: false },

]
