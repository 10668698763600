// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader_main {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: 3s;
  position: relative;
  background: linear-gradient(0deg, #000, #272727);
}


.loader_main:before,
.loader_main:after {
  content: '';
  position: absolute;
  left: -2px;
  top: -2px;
  background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000, #fb0094,
      #0000ff, #00ff00, #ffff00, #ff0000);
  background-size: 400%;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  z-index: 1;
  animation: steam 10s linear infinite;
  border-radius: 50%;

}

@keyframes steam {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.loader_main:after {
  filter: blur(50px);
}

.loader_img_container {
  width: 95%;
  height: 95%;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  background-color: #fff;
  /* background: linear-gradient(0deg, #000, #272727); */


}

.loader_img {
  width: 90%;

}`, "",{"version":3,"sources":["webpack://./src/components/componentsC/meCircularProgress/MeCircularProgress.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,cAAc;EACd,kBAAkB;EAClB,gDAAgD;AAClD;;;AAGA;;EAEE,WAAW;EACX,kBAAkB;EAClB,UAAU;EACV,SAAS;EACT;yCACuC;EACvC,qBAAqB;EACrB,uBAAuB;EACvB,wBAAwB;EACxB,UAAU;EACV,oCAAoC;EACpC,kBAAkB;;AAEpB;;AAEA;EACE;IACE,wBAAwB;EAC1B;;EAEA;IACE,2BAA2B;EAC7B;;EAEA;IACE,wBAAwB;EAC1B;AACF;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,UAAU;EACV,sBAAsB;EACtB,sDAAsD;;;AAGxD;;AAEA;EACE,UAAU;;AAEZ","sourcesContent":[".loader_main {\n  width: 60px;\n  height: 60px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 50%;\n  transition: 3s;\n  position: relative;\n  background: linear-gradient(0deg, #000, #272727);\n}\n\n\n.loader_main:before,\n.loader_main:after {\n  content: '';\n  position: absolute;\n  left: -2px;\n  top: -2px;\n  background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000, #fb0094,\n      #0000ff, #00ff00, #ffff00, #ff0000);\n  background-size: 400%;\n  width: calc(100% + 4px);\n  height: calc(100% + 4px);\n  z-index: 1;\n  animation: steam 10s linear infinite;\n  border-radius: 50%;\n\n}\n\n@keyframes steam {\n  0% {\n    background-position: 0 0;\n  }\n\n  50% {\n    background-position: 400% 0;\n  }\n\n  100% {\n    background-position: 0 0;\n  }\n}\n\n.loader_main:after {\n  filter: blur(50px);\n}\n\n.loader_img_container {\n  width: 95%;\n  height: 95%;\n  border-radius: 50%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  z-index: 2;\n  background-color: #fff;\n  /* background: linear-gradient(0deg, #000, #272727); */\n\n\n}\n\n.loader_img {\n  width: 90%;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
