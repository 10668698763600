import { combineReducers } from "redux";
import getData from "./getData";
import { sideBar } from "./sideBar";
import getProfileData from "./getProfileData";
import getWriter from "./getWriter";
import getCreatorData from "./getCreatorData";
import { getSubCategory } from "./getSubCreatorData";
import { mebookProfile } from "./mebookProfile";
import { userIdentity } from "./userIdentity";
import { userProfile } from "./userProfile";
import { errorPage } from "./errorPage";
import { callPopUp } from "./callPopUp";
import { userWorkDetail } from "./userWorkDetail";
import { userDashboardDetail } from "./userDashboardDetail";
import getPitch from "./getPitch";
import { updateUserDetail } from "./userUpdate";
import globalProgress from "./assets/globalProgress";
import { notificationRequest } from "./notificationRequest";
import messageSection from "./messageSection";
import getMessages from "./getMessages";
import musicPlayer from "./musicPlayer";
import { globalFormModal } from "./globalFormModal";
import getMePost from "./getMePost";
import getHomeSearch from './getHomeSearch'
import aiAllChat from './aiAllChat'
import pitchAlley from './pitchAlley'
import myAskCraousel from './myAskCraousel'
import getAllFavWork from "./getAllFavWork";
import musicList from './wishlist/musicList'
const rootReducer = combineReducers({
  getData,
  sideBar,
  getProfileData,
  getWriter,
  getCreatorData,
  getSubCategory,
  mebookProfile,
  userIdentity,
  userProfile,
  errorPage,
  userWorkDetail,
  userDashboardDetail,
  getPitch,
  updateUserDetail,
  globalProgress,
  notificationRequest,
  callPopUp,
  messageSection,
  getMessages,
  musicPlayer,
  globalFormModal,
  getMePost,
  getHomeSearch,
  aiAllChat,
  pitchAlley,
  myAskCraousel,
  getAllFavWork,

  // wishlist
  musicList

});

const defaultReducer = (state, action) =>
  rootReducer(action?.type === "USER_LOGOUT" ? undefined : state, action);

export default defaultReducer;
