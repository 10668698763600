import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useDispatch, useSelector } from 'react-redux';

export default function GlobalProgress() {
    const globalProgress = useSelector((state)=>state.globalProgress)
    const dispatch = useDispatch()
    const progressStyle = {
        width: '100%',
        display:globalProgress.display,
        position:'absolute',
        top:0,
        zIndex:99999
    }
    React.useEffect(()=>{
      if(globalProgress.progress===100){
        setTimeout(()=>{
          dispatch({ type: "COMPLETE__PROGRESS"})
        },400)
      } 
        if(globalProgress.progress>0){
            const timer = setInterval(() => {
            const fluctuateValue = 25
            if(globalProgress.progress<80){
            dispatch({type:"CALLING__PROGRESS",payload:globalProgress.progress+fluctuateValue})
            }else{
            // setTimeout(()=>{
            //     dispatch({type:"COMPLETE__PROGRESS"})
            // },500)
            clearInterval(timer)
            }
        }, 200);
        return ()=>clearInterval(timer)
        }
    },[globalProgress.progress])

  return (
    <Box sx={progressStyle}>
      <LinearProgress sx={{color:'red'}} variant="determinate" color='inherit' value={globalProgress.progress} />
    </Box>
  );
}