const initialState = {
    userProfile: false,
    loading: false,
    userFollower:{
        allFollower:false,
        errorCode:200,
        isFollow:false
    }
}

export const userProfile = (state = initialState, action) => {
    switch (action.type) {
        case "CALLING_USERPROFILE":
            return {
                ...state,
                loading: true
            }
        case "GET_USERPROFILE":
            return {
                ...state,
                loading: false,
                userProfile: action.payload,
            }
        case "GET_USERFOLLOWER":
            return {
                ...state,
                userFollower: action.payload,
            }
        case "increamentFollow":
            return {
                ...state,
                userFollower:{
                    ...state.userFollower,
                    allFollower:state.userFollower.allFollower+1,
                    isFollow:true
                }
            }
        case "decreamentFollow":
            return {
                ...state,
                userFollower:{
                    ...state.userFollower,
                    allFollower:state.userFollower.allFollower-1,
                    isFollow:false
                }
            }
        default:
            return state
    }
}
