import React from 'react'
import './MusicAnimation.css'
const MusicAnimation = () => {
    return (
        <div class="animation">
   
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>

    )
}

export default MusicAnimation