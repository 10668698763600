const initialState = {
    isOpen: false,
    data: null
}

export const globalFormModal = (state = initialState, action) => {
    switch (action.type) {
        case "OPEN_FORM_MODAL":
            return {
                ...state,
                isOpen: action.payload.isOpen,
                data: action.payload.data
            }
        default:
            return state;
    }
};