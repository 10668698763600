const initialState = {
    loading: false,
    play: false,
    count: 0,
    id: [],
    current: '',
    currentIndex: 0,
    musicArray: [],
    shuffledArray: [],
    progress: 0,
    duration: 0
}
const musicList = (state = initialState, action) => {

    switch (action.type) {
        case 'MUSIC_LIST_INITIATE':
            return {
                ...state, loading: true
            }

        case 'MUSIC_LIST_GET':

            return {
                ...state,
                musicArray: action.payload.musicArray,
                count: action.payload.count,
                id: action.payload.id,
                // current: action.payload.musicArray[state?.currentIndex],
                loading: false
            }

        case 'MUSIC_STATUS_TOGGLE':
            return {
                ...state,
                play: !state?.play
            }

        case 'MUSIC_PLAY_WITH_IDX':
            return {
                ...state,
                currentIndex: action?.payload?.index,
                play: action?.payload?.play
            }


        case 'MUSIC_SET_PREVIOUS':
            return {
                ...state,
                currentIndex: state?.currentIndex === 0 ?
                    state?.count - 1
                    :
                    state?.currentIndex - 1,
                play: true
            }
        case 'MUSIC_SET_NEXT':
            return {
                ...state,
                currentIndex: state?.currentIndex === state?.count - 1 ?
                    0
                    :
                    state?.currentIndex + 1,
                play: true
            }

        case 'MUSIC_DURATION':
            return {
                ...state,
                duration:action.payload.duration
            }

        case 'MUSIC_PROGRESS':
            return {
                ...state,
                progress:action.payload.progress
            }
        case 'MUSIC_LIST_FAILED':
            return {
                ...state, loading: false
            }
        default:
            return state
    }


}
export default musicList 