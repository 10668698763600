

export const callPopUp = (state = { showPop: false, PopType: "SuccessPayment" }, action) => {

    switch (action.type) {
        case "CALL_POPUP":

            if (action.payload.type === "signin") {
                return { showPop: true, PopType: action.payload.type }
            } else if (action.payload.type === "login") {
                return { showPop: true, PopType: action.payload.type }
            } else if (action.payload.type === "SuccessPayment") {
                return { showPop: true, PopType: action.payload.type }
            } else if (action.payload.type === "makeSubscription") {
                return { showPop: true, PopType: action.payload.type }
            } else if (action.payload.type === 'CouponCheck') {
                return { showPop: 'true', PopType: action.payload.type }
            } else if (action.payload.type === 'successPayment') {
                return { showPop: 'true', PopType: action.payload.type }
            } else if (action.payload.type === 'workDelete') {
                return { showPop: 'true', PopType: action.payload.type }
            } else if (action.payload.type === 'pitchDelete') {
                return { showPop: 'true', PopType: action.payload.type }
            } else if (action.payload.type === 'askDelete') {
                return { showPop: 'true', PopType: action.payload.type }
            } else if (action.payload.type === 'CancelSubscription') {
                return { showPop: 'true', PopType: action.payload.type }
            }
            else if (action.payload.type === 'ShowProfile') {
                return { showPop: 'true', PopType: action.payload.type }
            }
            else if (action.payload.type === 'ShowPitch') {
                return { showPop: 'true', PopType: action.payload.type }
            } else if (action.payload.type === 'ShowFollowers') {
                return { showPop: 'true', PopType: action.payload.type, data: action.payload.data }
            } else if (action.payload.type === 'ShowFPTags') {
                return { showPop: 'true', PopType: action.payload.type, data: action.payload.data }
            } else if (action.payload.type === 'VisitorCatSelect') {
                return { showPop: 'true', PopType: action.payload.type }
            } else if (action.payload.type === 'DonationForm') {
                return { showPop: 'true', PopType: action.payload.type }
            } else if (action.payload.type === 'SuccessDonation') {
                return { showPop: 'true', PopType: action.payload.type }
            } else if (action.payload.type === 'SuccessSponsor') {
                return { showPop: 'true', PopType: action.payload.type }
            } else if (action.payload.type === 'SponsershipForm') {
                return { showPop: 'true', PopType: action.payload.type }
            } else if (action.payload.type === 'sponsorCheck') {
                return { showPop: 'true', PopType: action.payload.type }
            } else if (action.payload.type === 'ShowProfileError') {
                return { showPop: 'true', PopType: action.payload.type }
            } else if (action.payload.type === 'CreatePost') {
                return { showPop: 'true', PopType: action.payload.type, data: action.payload.data }
            } else if (action.payload.type === 'DeletePost') {
                return { showPop: 'true', PopType: action.payload.type, postId: action.payload.data }
            } else if (action.payload.type === 'AIModalOne') {
                return { showPop: 'true', PopType: action.payload.type }
            } else if (action.payload.type === "AIModalTwo") {
                return { showPop: 'true', PopType: action.payload.type }
            } else if (action.payload.type === "SeeMorePost") {
                return { showPop: 'true', PopType: action.payload.type, data: action.payload?.data }
            } else if (action.payload.type === "AskMeAi") {
                return { showPop: 'true', PopType: action.payload.type }
            }
            else {
                return { showPop: false, PopType: action.payload.type }
            }

        case "REMOVE_POPUP":
            return { showPop: false, PopType: "SuccessPayment" }
        default:
            return state
    }
}
