import React, { useRef } from 'react';
import './player.scss';
// import {BsFillPlayCircleFill, BsFillPauseCircleFill, BsFillSkipStartCircleFill, BsSkipEndCircleFill, BsFillSkipEndCircleFill} from 'react-icons/bs';
import pic from "../../../../../mepic.png";

import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import { Padding } from '@mui/icons-material';
const Player = ({ audioElem, isplaying, setisplaying, currentSong, setCurrentSong, songs }) => {

  const clickRef = useRef();

  const PlayPause = () => {
    setisplaying(!isplaying);

  }


  const checkWidth = (e) => {
    let width = clickRef.current.clientWidth;
    const offset = e.nativeEvent.offsetX;

    const divprogress = offset / width * 100;
    audioElem.current.currentTime = divprogress / 100 * currentSong.length;
  }

  // const skipBack = () => {
  //   const index = songs.findIndex(x => x.title == currentSong.title);
  //   if (index == 0) {
  //     setCurrentSong(songs[songs.length - 1])
  //   }
  //   else {
  //     setCurrentSong(songs[index - 1])
  //   }
  //   audioElem.current.currentTime = 0;

  // }


  // const skiptoNext = () => {
  //   const index = songs.findIndex(x => x.title == currentSong.title);

  //   if (index == songs.length - 1) {
  //     setCurrentSong(songs[0])
  //   }
  //   else {
  //     setCurrentSong(songs[index + 1])
  //   }
  //   audioElem.current.currentTime = 0;

  // }
  const getTimeComponents = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
  };
  return (
    <div className='player_container' >
      <div className='pc_logo_div' >

        <img src={pic} />
      </div>
      <div className='pc_cover' >
        <img src={currentSong?.coverPicture}/>
      </div>
      <div className="title" >
        <p><b>{currentSong?.title}</b></p>
        <p style={{ color: 'white', fontSize: '.8rem' }}>By {currentSong?.name}</p>

      </div>
      <div className="navigation" >
        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',height:'.2rem',margin:0,padding:'5px 8px'}}>
        <h6>{getTimeComponents(currentSong?.currentTime)}</h6>

        <h6>{getTimeComponents(currentSong?.length)}</h6>

        </div>
        <div className="navigation_wrapper" onClick={checkWidth} ref={clickRef}>
          <div className="seek_bar" style={{ width: `${currentSong.progress + "%"}` }}></div>
        </div>
      </div>
      <div className="controls">
        <div>
        <span>-10s</span>
      <SkipPreviousIcon className='btn_action' onClick={()=>audioElem.current.currentTime=audioElem.current.currentTime-10}/> 
        </div>
        {isplaying ? <PauseCircleFilledIcon className='btn_action pp' onClick={PlayPause} /> : <PlayCircleFilledIcon className='btn_action pp' onClick={PlayPause} />}
        
        <div>
        <SkipNextIcon className='btn_action' onClick={()=>audioElem.current.currentTime=audioElem.current.currentTime+10}/>  
        <span>+10s</span>
        </div>    
      </div>
    </div>

  )
}

export default Player