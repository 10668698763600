import React, { useEffect } from 'react'
import './MusicWorkCard.css'
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector, useDispatch } from 'react-redux';
import { getAuth, openMusicPlayer } from '../../../Action';
import { delUserWork } from '../../../Action';
import { Box, Button, Modal } from '@mui/material';
import { newButton } from '../../background';
import { useNavigate } from 'react-router-dom';
import Edit from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import axios from 'axios';
import { SERVER } from '../../../server/server';
import { getWishlistByCategoryAction } from '../../../Action/wishlist/getWishlistByCategoryAction';

// Delete work modal
export const DeleteWork = ({ work }) => {

    const [openDel, setOpenDel] = React.useState(false);
    const handleCloseDel = () => setOpenDel(false);
    const dispatch = useDispatch()
    const handleDelete = (id) => {
        dispatch(delUserWork(id))

    }

    const styleDelete = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 450,
        bgcolor: "#2f2841",
        border: 'none',
        borderRadius: '5px',
        boxShadow: 24,
        padding: '1.5rem 1.5rem 0rem 1.5rem',
    };

    return (
        <>
            <Button onClick={() => setOpenDel(true)} style={{ color: 'white', cursor: 'pointer', '&:hover': { color: '#EFEFEF' } }}><DeleteIcon /></Button>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openDel}
                onClose={handleCloseDel}
                closeAfterTransition
            >
                <Box sx={styleDelete}>
                    <h6 id="transition-modal-description" style={{ color: "#fff", textAlign: 'center' }}>
                        Are you sure to delete this work permanently ?
                    </h6>
                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly', padding: "1rem" }}>
                        <Button onClick={handleCloseDel} variant="contained"
                            size="large"
                            sx={{
                                ...newButton,
                                py: "6px",
                                width: "6rem",
                                fontSize: "75%",
                            }}>Cancel</Button>

                        <Button varient="contained" onClick={() => { handleDelete(work._id); }} variant="contained"
                            size="large"
                            sx={{
                                ...newButton,
                                py: "6px",
                                width: "6rem",
                                fontSize: "75%",
                            }}>Delete</Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}
///////////////////////



const MusicWorkCard = ({ work, id }) => {
    console.log("workId", work)
    // const [active, setActive] = React.useState(false)
    const [catData, setCatData] = React.useState(null)
    const musicList = useSelector((state) => state?.musicList)

    // useEffect(() => {
    //     axios.get(`${SERVER}/favorite/get-all-favorite/${work?.category}`,
    //     {
    //       headers: { authorization: `Bearer ${getAuth()}` }
    //     })
    //     .then((response) => {
    //      console.log(response.data)
    //      setCatData(response.data?.favorite);
    //      console.log(response.data?.favorite)
    //     })
    //     .catch((error) => {
    //         console.error(error);
    //   })
    // }, [active])

    const btnStyle ={
    color: musicList?.id.includes(work?._id) ?  'red' : 'white',
    hover:"red"
    }
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const STORE = useSelector((state) => state);

    const handlePlay = (item) => {
        dispatch(openMusicPlayer(true, item))
    };

    const handleLiked = ({workId, category}) => {
        console.log("workId", category, workId)
        // console.error("active", active);
            console.error(" liked");
            axios.post(`${SERVER}/favorite/make-favorite/${workId}/${category}`, null ,
            {
                headers: { authorization: `Bearer ${getAuth()}` }
            })
            .then((res) =>{
                 console.log(res.data)
                 dispatch(getWishlistByCategoryAction('Music'))

            
            })
            .catch((error) => {
            console.error(error);
          })

        
        // setActive((prevState) => !prevState)
    }

    return (
        <>
            <>
                {/* <div className='music_work'> */}
                <div className='music_card'>
                    <div className='music_card_img'>
                        <img className='card_img' src={work?.coverPicture} loading="lazy" alt="" />
                    </div>
                    <div className='music_title'>
                    <div className='Heart_icons'>
                        <Button onClick={() => handleLiked({workId: work?._id, category: work?.category})} style={btnStyle}><FavoriteIcon /></Button>
                    </div>
                        <h2>{work?.title}</h2>
                        <p>{work?.description?.slice(0, 5).join(' ')}...</p>
                    </div>
                    <div className='play-icon' onClick={() => handlePlay(work)}>
                        <div className='circle'>
                            <div className='triangle'></div>
                        </div>
                    </div>
                    {id === STORE.getProfileData?.userData?._id ?
                        <>
                            <div className='Edit_icons'>
                                <Button onClick={(e) => navigate('/MainDashboard/createStory/Edit', { state: { workId: work._id } })} style={{ color: 'white', cursor: 'pointer', '&:hover': { color: '#EFEFEF' } }}><Edit /></Button>
                            </div>
                            <div className='Del_icons'>
                                <DeleteWork work={work} />
                            </div>
                            
                        </> : ""
                    }
                </div>
            </>
        </>
    )
}

export default MusicWorkCard;