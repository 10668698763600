import { useTheme } from "../../../contextApi/themeContext"; 
import { useEffect, useRef, useState } from "react";
import { S3_IMAGE_URL, S3_VIDEO_URL } from "../asset";
import ImageToVideo from "./ImageToVideo";

const Introvideo = () => {
    const [activeTab, setActiveTab] = useState(1);
    const { theme } = useTheme();

    const handleTabClick = (tabNumber) => {
        setActiveTab(tabNumber);
    };

    return (
        <>
            <div className="w-full flex  lg:flex-row md:flex-col flex-col items-center justify-center  lg:space-x-12 space-x-0 lg:space-y-0 space-y-4">


                <div className="w-full flex flex-col  space-y-4">
                    <h2 className="text-3xl font-bold tracking-widest" style={{ color: theme === 'dark' ? 'white' : 'black' }}>Introductory Video for Creatives</h2>
                    <div>
                        <h3 className="text-2xl font-bold tex-black " style={{ color: theme === 'dark' ? 'white' : 'black' }}> The Global Media Marketplace!</h3>
                        <div className="w-40 lg:w-96 border">
                        </div>
                    </div>
                    <p className="text-lg  tracking-widest" style={{ color: theme === 'dark' ? 'white' : 'black' }}>MeBookMeta creates a sustainable community of creators, collaborators, mentors, influencers, providers, consumers, viewers, readers and customers – a true marketplace</p>
                </div>

                <div className="w-full">
                    <div>
                        <video
                            className="border rounded-xl mb-4"
                            poster='/mepic.png'
                            src={`${S3_VIDEO_URL}/1725393224250-A+Universe+Of+Creativity+%281%29.mov`}
                            width="100%"
                            autoPlay
                            muted
                            controls
                            controlsList="nodownload"
                        ></video>
                    </div>
                </div>
            </div>

            {/* tabs */}

            <div className="w-full ">
                <div className=" rounded-lg  lg:p-4 p-2">
                    {/* <div className="flex justify-center space-x-8">
                        <div
                            className={`py-2 px-4 focus:outline-none  cursor-pointer ${activeTab === 1
                                ? 'border border-cyan-800 text-white rounded-lg bg-blue-800'
                                : 'border rounded-lg text-white bg-red-900'
                                }`}
                            onClick={() => handleTabClick(1)}
                         style={{ color: theme === 'dark' ? 'white' : 'black' }}>
                            The Greatest Challenge for Creatives
                        </div>
                        <div
                            className={`py-2 px-4 focus:outline-none  cursor-pointer ${activeTab === 2
                                ? 'border border-cyan-800 rounded-lg text-white bg-blue-800'
                                : 'border rounded-lg text-white bg-red-900'
                                }`}
                            onClick={() => handleTabClick(2)}
                        style={{ color: theme === 'dark' ? 'white' : 'black' }}>
                            MeBookMeta Universe – Languages
                        </div>
                    </div> */}

                    <div className="py-4 lg:p-4 p-2">
                        {
                            activeTab === 1 ? (
                                <ImageToVideo heading='The Greatest Challenge for Creatives' description='It’s not content creation. Creating is literally what creators do. No, the biggest challenge for creators is Sharing Work, and specifically, sharing work with ideal audiences, creating a following (a unique brand) and becoming profitable.'
                                imageUrl="/smallGrp.jpg"
                                videoUrl="https://mebook-work-v2.s3.us-east-2.amazonaws.com/1725645701351-The+Struggle+Is+Real.mov"
                                />
                            ) : 
                            <div id="tab2" className={`tab-content ${activeTab === 2 ? '' : 'hidden'}`}>
                                <div className="space-y-2 text-center">
                                    <h2 className="text-2xl text-black font-bold" style={{ color: theme === 'dark' ? 'white' : 'black' }}>MeBookMeta Universe – Languages</h2>
                                    <h4 className="text-lg text-gray-600 tracking-widest lg:px-8 xl:px-32" style={{ color: theme === 'dark' ? 'white' : 'black'}}>
                                        Within 90 days, MeBookMeta will be providing for additional languages, including Spanish, French, German, Chinese, Arabic, African languages, Dutch and Japanese with others to follow. Share, sell and collaborate on your work or projects in an expanding global marketplace.
                                    </h4>
                                    <img src="/lang.jpg" alt="image" className="mx-auto w-[70vw] h-[70vh] rounded-lg" />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Introvideo