let initialState = {
    success:false,
    userData : {}
}

const getProfileData = (state=initialState,action) =>{
    switch (action.type) {
        case "UPDATED_USER_DATA":
            return {
                ...state,
                userData:action.payload.userData,
                success:true
            }
    
        default:
            return state
    }
}

export default getProfileData;