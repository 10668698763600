const initialState = {
    loading: false,
    users: []
}

const getHomeSearch = (state = initialState, action) => {
    switch (action.type) {
        case "CALLING_HOME_SEARCH":
            console.log('CALLING_HOME_SEARCH')
            return {
                ...state,
                loading: true
            }


        case "GET_HOME_SEARCH":
            console.log('GET_HOME_SEARCH')
            console.log('payload 18', action.payload)

            let { filteredByCat, users } = action.payload
            if (filteredByCat.length>0) {
                users = users?.filter((item) => filteredByCat?.includes(item?._id))
            }


            return {
                users: users,
                loading: false
            }

        case "FAILED_HOME_SEARCH":
            console.log('FAILED_HOME_SEARCH')

            return {
                users: [],
                loading: false
            }

        default:
            return state
    }

}
export default getHomeSearch