// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.float-btn {
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  z-index: 999;
}

@media screen and (max-width: 344px) {
  .Box {
    width: 17rem !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/Component%20new/ChatBox/ChatBox.scss","webpack://./src/Component new/ChatBox/ChatBox.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,cAAA;EACA,aAAA;EACA,YAAA;ACCJ;;ADEA;EACI;IAAK,uBAAA;ECEP;AACF","sourcesContent":[".float-btn {\n    position: fixed;\n    bottom: 1.5rem;\n    right: 1.5rem;\n    z-index: 999;\n}\n\n@media screen and (max-width:344px) {\n    .Box{width: 17rem !important}\n}\n\n",".float-btn {\n  position: fixed;\n  bottom: 1.5rem;\n  right: 1.5rem;\n  z-index: 999;\n}\n\n@media screen and (max-width: 344px) {\n  .Box {\n    width: 17rem !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
