import axios from "axios";
import { SERVER } from "../../server/server";
import { getAuth } from "..";
import { toast } from "react-toastify";

export const getWishlistByCategoryAction = (category) => {
    return async (dispatch) => {
        if (!category) {
            return toast.error('Somthing went wrong')
        }
        try {
            dispatch({ type: 'MUSIC_LIST_INITIATE' })
            axios.get(`${SERVER}/favorite/get-all-favorite/${category}`, {
                headers: { Authorization: `Bearer ${getAuth()}` },
            })
                .then((response) => {
                    if (response?.data?.errorCode === 200) {

                        const fetchAudioLengths = async () => {
                            const updatedAudioObjects = await Promise.all(response?.data?.data?.workDetails?.map(async (audioItem) => {
                              const resp = await fetch(audioItem?.userWork);
                              const blob = await resp.blob();
                              const audio = new Audio(URL.createObjectURL(blob));
                              const duration = await new Promise((resolve) => {
                                audio.addEventListener('loadedmetadata', () => {
                                  resolve(audio.duration);
                                });
                              });
                              console.log('jjjjjjjj',{ ...audioItem, length: duration })

                              return { ...audioItem, length: convertSecToMinSec(duration) };
                            }));
                            dispatch({
                                type: 'MUSIC_LIST_GET',
                                payload: {
                                    musicArray: updatedAudioObjects,
                                    count: response?.data?.data?.favorite?.favorites?.Music?.count,
                                    id: response?.data?.data?.favorite?.favorites?.Music?.work
    
                                }
                            })
                        };
                          fetchAudioLengths();
                        // dispatch({
                        //     type: 'MUSIC_LIST_GET',
                        //     payload: {
                        //         musicArray: response?.data?.data?.workDetails,
                        //         count: response?.data?.data?.favorite?.favorites?.Music?.count,
                        //         id: response?.data?.data?.favorite?.favorites?.Music?.work

                        //     }
                        // })
                    }
                    console.log('response getWishlistByCategoryAction', response.data)
                })
        } catch (error) {
            dispatch({ type: 'MUSIC_LIST_FAILED' })
            console.log('error in getWishlistByCategoryAction', error)
        }
    }
}


function convertSecToMinSec(seconds) {
    // Calculate minutes and remaining seconds
    var minutes = Math.floor(seconds / 60);
    var remainingSeconds = Math.round(seconds % 60);
    
    // Return formatted result
    return {
        minutes: minutes,
        seconds: remainingSeconds
    };
}
// Example usage
// var audioLengthMs = 135000; // Example audio length in milliseconds
// var convertedTime = convertMsToMinSec(audioLengthMs);
// console.log(convertedTime.minutes + " minutes " + convertedTime.seconds + " seconds");
