// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* *{
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
} */

.Gpp{
    width: 55%;
    height:35rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
    border-radius: 10px;
    transition:3s;


    /* background: 'white'; */
    position: relative;
	/* margin: 300px auto 300px; */
	/* width: 500px; */
	/* height: 550px; */
	background: linear-gradient(0deg, #000, #272727);

}




.Gpp:before, .Gpp:after {
	content: '';
	position: absolute;
	left: -2px;
	top: -2px;
	background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00,#ffff00, #ff0000, #fb0094, 
		#0000ff, #00ff00,#ffff00, #ff0000);
	background-size: 400%;
	width: calc(100% + 4px);
	height: calc(100% + 4px);
	z-index: 1;
	animation: steam 20s linear infinite;
    border-radius: 10px;

}

@keyframes steam {
	0% {
		background-position: 0 0;
	}
	50% {
		background-position: 400% 0;
	}
	100% {
		background-position: 0 0;
	}
}

.Gpp:after {
	filter: blur(50px);
}
@media only screen and (max-width: 700px) {

	.Gpp{
		width: 100%;
	}

}`, "",{"version":3,"sources":["webpack://./src/Component new/userWork/workDisplay/audioPlayer/Gpp.css"],"names":[],"mappings":"AAEA;;;;;GAKG;;AAEH;IACI,UAAU;IACV,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;IACnB,mBAAmB;IACnB,aAAa;;;IAGb,yBAAyB;IACzB,kBAAkB;CACrB,8BAA8B;CAC9B,kBAAkB;CAClB,mBAAmB;CACnB,gDAAgD;;AAEjD;;;;;AAKA;CACC,WAAW;CACX,kBAAkB;CAClB,UAAU;CACV,SAAS;CACT;oCACmC;CACnC,qBAAqB;CACrB,uBAAuB;CACvB,wBAAwB;CACxB,UAAU;CACV,oCAAoC;IACjC,mBAAmB;;AAEvB;;AAEA;CACC;EACC,wBAAwB;CACzB;CACA;EACC,2BAA2B;CAC5B;CACA;EACC,wBAAwB;CACzB;AACD;;AAEA;CACC,kBAAkB;AACnB;AACA;;CAEC;EACC,WAAW;CACZ;;AAED","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');\n\n/* *{\n    margin: 0;\n    padding: 0;\n    font-family: 'Roboto', sans-serif;\n    font-weight: 400;\n} */\n\n.Gpp{\n    width: 55%;\n    height:35rem;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-bottom: 3rem;\n    border-radius: 10px;\n    transition:3s;\n\n\n    /* background: 'white'; */\n    position: relative;\n\t/* margin: 300px auto 300px; */\n\t/* width: 500px; */\n\t/* height: 550px; */\n\tbackground: linear-gradient(0deg, #000, #272727);\n\n}\n\n\n\n\n.Gpp:before, .Gpp:after {\n\tcontent: '';\n\tposition: absolute;\n\tleft: -2px;\n\ttop: -2px;\n\tbackground: linear-gradient(45deg, #fb0094, #0000ff, #00ff00,#ffff00, #ff0000, #fb0094, \n\t\t#0000ff, #00ff00,#ffff00, #ff0000);\n\tbackground-size: 400%;\n\twidth: calc(100% + 4px);\n\theight: calc(100% + 4px);\n\tz-index: 1;\n\tanimation: steam 20s linear infinite;\n    border-radius: 10px;\n\n}\n\n@keyframes steam {\n\t0% {\n\t\tbackground-position: 0 0;\n\t}\n\t50% {\n\t\tbackground-position: 400% 0;\n\t}\n\t100% {\n\t\tbackground-position: 0 0;\n\t}\n}\n\n.Gpp:after {\n\tfilter: blur(50px);\n}\n@media only screen and (max-width: 700px) {\n\n\t.Gpp{\n\t\twidth: 100%;\n\t}\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
