let initialState = {
    writerId: false
}

const getWriter = (state = initialState, action) => {
    switch (action.type) {
        case "WRITER_ID":
            return {
                ...state,
                writerId: action.payload
            }
        default:
            return state
    }
}

export default getWriter;