let initialState = {
    getMessages : []
}

const getMessages = (state=initialState,action) =>{
    switch (action.type) {
        case "GET_MESSAGE":
            return {
                ...state,
                getMessages:action.payload
            }
    
        default:
            return state
    }
}

export default getMessages;