import React, { useState } from "react";
import './NewCrousel.scss'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

// import { TiChevronLeftOutline, TiChevronRightOutline } from 'https://cdn.skypack.dev/react-icons/ti';

// const CARDS = 10;
const MAX_VISIBILITY = 3;

const Card = ({ item }) => (
    <div className='card'>
        <h2>{item.option}</h2>
        <p>{item.value}</p>
    </div>
);


const Carousel = ({ children }) => {
    const [active, setActive] = useState(2);
    const count = React.Children.count(children);

    return (
        <div className="" style={{ display: 'flex', justifyContent: "center", alignItems: "center",  }}>
            <div className='carousel'>
                {active > 0 && <button className='nav left' onClick={() => setActive(i => i - 1)}>
                    {/* <TiChevronLeftOutline /> */}
                <ArrowLeftIcon sx={{fontSize:{sx:"2rem", md:"6rem"}}} />
                </button>}
                {React.Children.map(children, (child, i) => (
                    <div className='card-container' style={{
                        '--active': i === active ? 1 : 0,
                        '--offset': (active - i) / 3,
                        '--direction': Math.sign(active - i),
                        '--abs-offset': Math.abs(active - i) / 3,
                        'pointerEvents': active === i ? 'auto' : 'none',
                        'opacity': Math.abs(active - i) >= MAX_VISIBILITY ? '0' : '1',
                        'display': Math.abs(active - i) > MAX_VISIBILITY ? 'none' : 'block',
                    }} key={'mer'+i}>
                        {child}
                    </div>
                ))}
                {active < count - 1 && <button className='nav right' onClick={() => setActive(i => i + 1)}>
                    {/* <TiChevronRightOutline /> */}
                    <ArrowRightIcon sx={{fontSize:{sx:"2rem", md:"6rem"}}} />
                    </button>}
            </div>
        </div>
    );
};

export default function NewCrousel({ array }) {

    return (
        <Carousel>
            {
                array?.map((item,i) => {
                    return (
                        <Card item={item} key={'riz'+i}/>
                    )
                })
            }
        </Carousel>
    )
} 