const initialState = {
    userDetail: false,
    loading: false
}

export const updateUserDetail = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATED_USER_DETAIL":
            return {
                ...state,
                loading: false,
                userDetail: action.payload,
            }
        default:
            return state
    }
}
