import { Box } from "@mui/material";
import { useTheme } from "../../../../contextApi/themeContext";
import sptLight from '../../../../assets/SPOTlights.png';
import PitchCraousel from "../../../../Component new/PitchCrousel/PitchCraousel";
import MyAskCraousel from "../../../../components/MyAskCraousel/MyAskCraousel";
import { S3_IMAGE_URL } from "../../asset";

const PreviewAsk = () => {
    const { theme } = useTheme();
    return (
        <div className="w-full flex flex-col lg:flex-row lg:space-x-12 space-x-0 space-y-8 lg:space-y-0 overflow-x-hidden">
            <div className="w-full flex flex-col items-center justify-center space-y-4">
                <h3 className="text-2xl text-black font-bold" style={{ color: theme === 'dark' ? 'white' : 'black' }}>MEBOOKMETA PITCH ALLEY PREVIEW</h3>
                <div className='d-flex align-items-center justify-content-center'>
                    <Box
                        component="img"
                        sx={{
                            height: 300,
                            width: 500,
                            maxHeight: { xs: 233, md: 350 },
                            maxWidth: { xs: 350, md: 500 },
                        }}
                        alt="left_bulb"
                        src={sptLight}
                    />
                    <div style={{ position: 'absolute', zIndex: "1", padding: "1rem", opacity: '0.9' }}>
                        <div style={{ top: "0%", left: "50%" }}><PitchCraousel /></div>
                    </div>
                </div>
            </div>
            <div className="w-full flex flex-col items-center justify-center space-y-4">
                <h3 className="text-2xl text-black font-bold" style={{ color: theme === 'dark' ? 'white' : 'black' }}>MEBOOKMETA ASK THE UNIVERSE PREVIEW</h3>
                <Box
                    component="video"
                    autoPlay loop muted
                    sx={{
                        height: 300,
                        width: 500,
                        maxHeight: { xs: 233, md: 350 },
                        maxWidth: { xs: 350, md: 500 },
                    }}
                    src={`${S3_IMAGE_URL}/1703593695843-space_-_67382+%28540p%29+%281%29.mp4`} type="video/mp4"
                />
                <div style={{ position: 'absolute', zIndex: "1", padding: "1rem", opacity: '0.6' }}>
                    <div style={{ top: "0%", left: "50%" }}><MyAskCraousel /></div>
                </div>
            </div>
        </div>
    );
};

export default PreviewAsk;
