
import React, { useEffect, useState } from "react";
import "../../Component new/PitchCrousel/PitchCraousel.css";
import { useDispatch, useSelector } from "react-redux";
import ReactPlayer from "react-player";
import { SERVER } from "../../server/server";
import axios from "axios";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Heading from "../componentsB/text/heading/HeadingTag";
import { useNavigate } from "react-router-dom";

const PitchCraousel = () => {
  const dispatch = useDispatch()
  const STORE = useSelector((state) => state?.myAskCraousel)
  const playing = STORE?.play
  const [btn, setBtn] = useState('')
  const navigate = useNavigate()

  async function fetchData() {
    try {
      await axios.post(`${SERVER}/get_all_ask`, { searchRoles: [] }).then((res) => {
        // console.log("res.myAsk",res)
        dispatch({ type: 'SET_MYASK_CRAOUSEL_DATA', payload: { resultWithUserId: res?.data?.data?.resultWithUserId, filteredByCat: res?.data?.data?.filteredByCat } })

      })

    } catch (error) {
      console.log('error in landich page pitch', error)
    }
  }
  useEffect(() => {
    fetchData()
  }, [])


  useEffect(() => {
    setBtn(STORE?.current?.name)

  }, [STORE])

  useEffect(() => {
    const isPageRefreshed = performance.navigation.type === 1;

    if (isPageRefreshed) {
      dispatch({ type: 'SET_MYASK_CRAOUSEL_CLEAR', })

    }
  }, []);

  const onReadyHandle = () => {

  }

  const handleOnEnded = () => {
    dispatch({ type: 'SET_NEXT_TO_CURRENT_MYASK_CRAOUSEL' })
    dispatch({ type: 'SET_TRUE_MYASK_CRAOUSEL' })

  }
  const onPauseHandle = () => {

    dispatch({ type: 'SET_FALSE_MYASK_CRAOUSEL_DATA' })

  }
  const onPlayHandle = () => {

    dispatch({ type: 'SET_TRUE_MYASK_CRAOUSEL' })

  }
  const onMouseEnterHandle = () => {

    setTimeout(() => {
      setBtn('view my profile  >>>')
    }, 100);

  }
  const onMouseLeaveHandle = () => {

    setTimeout(() => {
      setBtn(STORE?.current?.name)
    }, 100);

  }
  return (
    <>
      <div className="landing_pitch">

        <ArrowLeftIcon onClick={() => dispatch({ type: 'SET_PREV_TO_CURRENT_MYASK_CRAOUSEL' })} sx={{ color: 'red', fontSize: { xs: "2rem", md: "7rem", zIndex:"999" }, cursor: 'pointer' }} />

        <div className="vdo_div" style={{ position: 'relative', background: 'none', marginTop: "2rem" }}>
          <ReactPlayer className="ptch_vdo_player" playing={playing} controls config={{
          file: {
            attributes: {
              controlsList: 'nodownload', // This disables the download button
              disablePictureInPicture: true, // Optionally disable PiP
            },
          },
        }}
        url={STORE?.current?.askFile} onEnded={handleOnEnded} style={{ background: '', position: 'absolute', }} onReady={onReadyHandle} onPause={onPauseHandle} onPlay={onPlayHandle} width={"100%"} height={"100%"} />


          {!playing &&
            <PlayCircleIcon className="play_icon" sx={{ color: 'red', top: { xs: "44%", md: "25%" }, left: '40%', position: 'absolute', fontSize: { xs: "2rem", md: "5rem" }, cursor: 'pointer', pointerEvents: "none", zIndex:"999" }} onClick={() => dispatch({ type: 'SET_TRUE_MYASK_CRAOUSEL' })
            } />
          }

          <div onMouseEnter={() => onMouseEnterHandle()} onMouseLeave={() => onMouseLeaveHandle()}>
            <Heading level={6} text={btn} className={'pitch_vide_card_name'} onClick={() => localStorage.getItem('token') ?
              navigate(`/MainDashboard/UserProfile/${STORE?.current?.name}/${STORE?.current?.userId}`)
              :
              navigate(`/Profile/${STORE?.current?.name}/${STORE?.current?.userId}`)
            }
            >
              {btn}
            </Heading>
          </div>

        </div>

        <ArrowRightIcon onClick={() => dispatch({ type: 'SET_NEXT_TO_CURRENT_MYASK_CRAOUSEL' })} sx={{ color: 'red', fontSize: { xs: "2rem", md: "7rem" }, cursor: 'pointer', zIndex:"999" }} />

      </div >
    </>
  );
};




export default PitchCraousel;


