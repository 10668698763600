import React, { useEffect, useState, useRef } from 'react'
import { FaPlay } from "react-icons/fa";
import { FaPause } from "react-icons/fa";

import { FaCirclePlay } from "react-icons/fa6";
import { FaCirclePause } from "react-icons/fa6";

import { BsArrowRepeat } from "react-icons/bs";
import { RiShuffleFill } from "react-icons/ri";
import { IoMdArrowDropleft } from "react-icons/io";
import { IoMdArrowDropright } from "react-icons/io";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { FaRegCirclePause } from "react-icons/fa6";
import { FaRegCirclePlay } from "react-icons/fa6";
import { LuClock3 } from "react-icons/lu";

import { PiArrowClockwiseFill } from "react-icons/pi";
import { PiArrowCounterClockwiseFill } from "react-icons/pi";
import ReactPlayer from 'react-player';
import MeLogo from '../../../../assets/MeLogoMain';
import MusicAnimation from './MusicAnimation';
import { useDispatch, useSelector } from 'react-redux';
import { getWishlistByCategoryAction } from '../../../../Action/wishlist/getWishlistByCategoryAction';
import { Box } from '@mui/material';

const MusicList = () => {
    const dispatch = useDispatch()
    const musicList = useSelector((state) => state?.musicList)
    const [progress, setProgress] = useState(0);
    const [duration, setDuration] = useState(0);

    // console.log('progress', progress)
    // console.log('duration', duration)
    useEffect(() => {
        dispatch(getWishlistByCategoryAction('Music'))
    }, [])
    const handleProgress = (state) => {
        if (!state) return;
        const { played } = state;
        // setProgress(played * 100);
        dispatch({ type: 'MUSIC_PROGRESS',payload:{progress:played * 100} })

        
    };

    const handleDuration = (duration) => {
        // setDuration(duration);
        dispatch({ type: 'MUSIC_DURATION',payload:{duration} })

    };
    const handleSeek = (e) => {
        const clickedTime = (e.nativeEvent.offsetX / e.currentTarget.offsetWidth);
        setProgress(clickedTime * 100);
    };
    const handleOnSeek = (e) => {

    }
    const handleOnEnded = () => {
        dispatch({ type: 'MUSIC_SET_NEXT' })
    }



    const playerRef = useRef(null);
    const handleSkipForward = () => {
        const currentTime = playerRef.current.getCurrentTime();
        playerRef.current.seekTo(currentTime + 10);
    };
    const handleSkipBackward = () => {
        const currentTime = playerRef.current.getCurrentTime();
        if (currentTime > 10) {
            playerRef.current.seekTo(currentTime - 10);
        }
    };
    return (
        <Box className='col-md-12 col-sm-12 text-light d-flex flex-column justify-content-center align-items-center p-0' sx={{ minHeight: "100vh", gap: "2rem", marginTop: { xs: "8rem", sm: "2rem", md: "0rem" } }}>
            <Box sx={{ flexDirection: { xs: 'column-reverse', sm: 'column', md: 'row' } }} className='col-md-12 col-sm-12 d-flex p-0'
                style={{ height: '150px', }}
            >
                <Box className='col-md-2 col-sm-12 d-flex align-items-center justify-content-center'>
                    <span
                        className='d-flex flex-column align-items-center '

                    >
                        <span
                            onClick={() => musicList?.count > 0 && dispatch({ type: 'MUSIC_STATUS_TOGGLE' })}
                        >
                            {
                                musicList?.play ?
                                    <FaCirclePause
                                        style={{ fontSize: '70px', color: 'red' }}
                                    />
                                    :
                                    <FaCirclePlay
                                        style={{ fontSize: '70px', color: 'red' }}
                                    />
                            }
                        </span>
                    </span>
                </Box>

                <Box sx={{ flexDirection: { xs: 'column', sm: 'column', md: 'row' }, marginTop: { xs: "24rem", sm: "3rem", md: "0rem", } }} className='col-md-6 col-sm-12 d-flex align-items-center justify-content-around '>

                    <span
                        className='col-md-4 col-sm-4'
                        style={{
                            height: '150px', border: '1px solid #red'
                        }}
                    >
                        <img alt='1wdfgh5rtg'
                            src={musicList?.musicArray[musicList?.currentIndex]?.coverPicture}
                            style={{ width: '100%', height: '100%' }}
                        />
                    </span>

                    <span
                        className=' col-md-8 col-sm-8 d-flex flex-column align-items-center justify-content-between '
                        style={{
                            height: '150px'
                        }}

                    >
                        <span>
                            {musicList?.musicArray[musicList?.currentIndex]?.userId?.name}
                        </span>
                        <span>
                            <marquee behavior="scroll" direction="left">
                                {musicList?.musicArray[musicList?.currentIndex]?.title}
                            </marquee>

                        </span>
                        <div
                            style={{ width: '100%', height: '10px', backgroundColor: 'lightgray', position: 'relative', cursor: 'pointer', borderRadius: '5px' }}

                        >
                            <div
                                style={{
                                    width: `${musicList?.progress}%`, height: '100%',
                                    background: 'linear-gradient(to bottom right, red, blue)',
                                    position: 'absolute', top: 0, left: 0,
                                    borderRadius: '5px',

                                }}
                            ></div>
                        </div>
                        <span
                            className=' d-flex align-items-center justify-content-around'
                            style={{ width: '100%' }}
                        >

                            <IoMdArrowDropleft
                                onClick={() => dispatch({ type: 'MUSIC_SET_PREVIOUS' })}

                                style={{ fontSize: '30px', color: 'red' }}
                            />

                            {/* <PiArrowCounterClockwiseFill
                                style={{ fontSize: '25px', color: 'red' }}
                                onClick={handleSkipBackward}
                            /> */}


                            <span>
                                {musicList?.play ?
                                    <FaRegCirclePause
                                        onClick={() => musicList?.count > 0 && dispatch({ type: 'MUSIC_STATUS_TOGGLE' })}
                                        style={{ fontSize: '35px', color: 'red' }}
                                    />
                                    :
                                    <FaRegCirclePlay
                                        onClick={() => musicList?.count > 0 && dispatch({ type: 'MUSIC_STATUS_TOGGLE' })}
                                        style={{ fontSize: '35px', color: 'red' }}
                                    />
                                }
                            </span>


                            {/* <PiArrowClockwiseFill
                                style={{ fontSize: '25px', color: 'red' }}
                                onClick={handleSkipForward}

                            /> */}
                            <IoMdArrowDropright
                                onClick={() => dispatch({ type: 'MUSIC_SET_NEXT' })}
                                style={{ fontSize: '30px', color: 'red' }}
                            />

                        </span>
                    </span>
                </Box>

                <Box sx={{}} className='col-md-4 col-sm-12 d-flex align-items-center justify-content-center'>
                    < div style={{ position: 'absolute' }}>
                        <MeLogo />
                    </div >
                </Box >
            </Box>


            <div className='col-md-12'
                style={{ minHeight: '150px', width: '100%' }}
            >
                {/* <ReactPlayer
                    ref={playerRef}

                    url={musicList?.musicArray[musicList?.currentIndex]?.userWork}
                    controls={true}
                    playing={musicList?.play}
                    width='0px'
                    height='0px'
                    onProgress={handleProgress}
                    onDuration={handleDuration}
                    onEnded={handleOnEnded}


                /> */}

                {musicList?.count > 0 ?
                    musicList?.musicArray?.map((i, idx) => (
                        <div
                            key={i?._id}
                            className='col-12 d-flex align-items-center justify-content-between p-2'
                            style={{
                                width: '100%',
                                cursor: 'pointer',
                                border: musicList?.play &&
                                    i?._id === musicList?.musicArray[musicList?.currentIndex]?._id && '1px solid red'
                            }}
                            onClick={() => dispatch({
                                type: 'MUSIC_PLAY_WITH_IDX',
                                payload: {
                                    index: idx,
                                    play: true
                                }
                            })}
                        >
                            <div
                                className='col-2 d-flex align-items-center justify-content-between'
                                style={{ width: '20%' }}

                            >
                                <div className='text-center'>
                                    {
                                        i?._id === musicList?.musicArray[musicList?.currentIndex]?._id ?
                                            (
                                                musicList?.play ?
                                                    <FaPause />
                                                    :
                                                    <FaPlay />
                                            )
                                            :
                                            (idx + 1)
                                    }

                                </div>
                                <span>
                                    {/* <FaPlay /> */}
                                    {/* {
                                        musicList?.play &&
                                        i?._id === musicList?.musicArray[musicList?.currentIndex]?._id &&

                                        <MusicAnimation />
                                    } */}
                                </span>
                                <div>
                                    <img
                                        src={i?.coverPicture}
                                        style={{ height: '60px', width: '60px' }}
                                    />
                                </div>
                            </div>
                            <div
                                className='col-8 d-flex align-items-center justify-content-between '
                                style={{ width: '70%' }}

                            >
                                <div className='col-4'>
                                    {i?.title}
                                </div>
                                <div className='col-4'>
                                    {i?.userId?.name}
                                </div>
                                <div className='col-4'>
                                    <LuClock3 className='mr-2' />
                                    {`${i?.length?.minutes}:${i?.length?.seconds}`}
                                </div>
                            </div>

                        </div>
                    ))
                    :
                    <div className='text-center'>
                        <h4>No favorite music to show</h4>
                    </div>

                }

                {/* <div className=' d-flex align-items-center justify-content-between p-2'
                    style={{ width: '100%' }}
                >
                    <div
                        className=' d-flex align-items-center justify-content-between'
                        style={{ width: '20%' }}

                    >
                        <span >1</span>
                        <span>
                            <FaPlay />
                        </span>
                        <span>
                            <img
                                src='https://i.pinimg.com/736x/4b/4a/bf/4b4abf3207a1e1d7e18896f11ff47a47.jpg'
                                style={{ height: '60px', width: '60px' }}
                            />
                        </span>
                    </div>
                    <div
                        className=' d-flex align-items-center justify-content-between'
                        style={{ width: '70%' }}

                    >
                        <span>
                            Beliver by Imagine Dragons
                        </span>
                        <span>
                            Imagine Dragons
                        </span>
                        <span>
                            2:45
                        </span>
                    </div>

                </div>


                <div className=' d-flex align-items-center justify-content-between p-2'
                    style={{ width: '100%', border: '1px solid #1ED760' }}
                >
                    <div
                        className=' d-flex align-items-center justify-content-between'
                        style={{ width: '20%' }}

                    >
                        <span >1</span>
                        <span>
                            <FaPlay />
                        </span>
                        <span>
                            <img
                                src='https://i.pinimg.com/736x/4b/4a/bf/4b4abf3207a1e1d7e18896f11ff47a47.jpg'
                                style={{ height: '60px', width: '60px' }}
                            />
                        </span>
                    </div>
                    <div
                        className=' d-flex align-items-center justify-content-between'
                        style={{ width: '70%' }}

                    >
                        <span>
                            Beliver by Imagine Dragons
                        </span>
                        <span>
                            Imagine Dragons
                        </span>
                        <span>
                            2:45
                        </span>
                    </div>

                </div>
                <div className=' d-flex align-items-center justify-content-between p-2'
                    style={{ width: '100%' }}
                >
                    <div
                        className=' d-flex align-items-center justify-content-between'
                        style={{ width: '20%' }}

                    >
                        <span >1</span>
                        <span>
                            <FaPlay />
                        </span>
                        <span>
                            <img
                                src='https://i.pinimg.com/736x/4b/4a/bf/4b4abf3207a1e1d7e18896f11ff47a47.jpg'
                                style={{ height: '60px', width: '60px' }}
                            />
                        </span>
                    </div>
                    <div
                        className=' d-flex align-items-center justify-content-between'
                        style={{ width: '70%' }}

                    >
                        <span>
                            Beliver by Imagine Dragons
                        </span>
                        <span>
                            Imagine Dragons
                        </span>
                        <span>
                            2:45
                        </span>
                    </div>

                </div>
                <div className=' d-flex align-items-center justify-content-between p-2'
                    style={{ width: '100%' }}
                >
                    <div
                        className=' d-flex align-items-center justify-content-between'
                        style={{ width: '20%' }}

                    >
                        <span >1</span>
                        <span>
                            <FaPlay />
                        </span>
                        <span>
                            <img
                                src='https://i.pinimg.com/736x/4b/4a/bf/4b4abf3207a1e1d7e18896f11ff47a47.jpg'
                                style={{ height: '60px', width: '60px' }}
                            />
                        </span>
                    </div>
                    <div
                        className=' d-flex align-items-center justify-content-between'
                        style={{ width: '70%' }}

                    >
                        <span>
                            Beliver by Imagine Dragons
                        </span>
                        <span>
                            Imagine Dragons
                        </span>
                        <span>
                            2:45
                        </span>
                    </div>

                </div> */}
            </div>



        </Box>
    )
}

export default MusicList














