import React from 'react'
import './MeCircularProgress.css'
import pic from '../../../mepic.png'
const MeCircularProgress = () => {
  return (
    <div className='w-full h-screen flex justify-center items-center'>

      <div className='loader_main'>
        <div className="loader_img_container">
          <img className='loader_img' src={pic} />
        </div>
      </div>
    </div>
  )
}

export default MeCircularProgress