

import React, { useEffect, useState } from "react";
import "./PitchCraousel.css";
import { useDispatch, useSelector } from "react-redux";
import ReactPlayer from "react-player";
import { SERVER } from "../../server/server";
import axios from "axios";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Heading from "../../components/componentsB/text/heading/HeadingTag";
import { useNavigate } from "react-router-dom";
const PitchCraousel = () => {
  const dispatch = useDispatch()
  const STORE = useSelector((state) => state?.pitchAlley)
  const playing = STORE.play
  const [btn, setBtn] = useState('')
  const navigate = useNavigate()

  async function fetchData() {
    try {
      await axios.post(`${SERVER}/get_all_pitch`).then((res) => {
        // console.log("first ---", res)
        dispatch({ type: 'SET_PITCH_ALLEY_DATA', payload: {resultWithUserId:res?.data?.data?.resultWithUserId} })

      })

    } catch (error) {
      console.log('error in landich page pitch', error)
    }
  }
  useEffect(() => {
    fetchData()
  }, [])


  useEffect(() => {
    setBtn(STORE?.current?.name)

  }, [STORE])

  useEffect(() => {
    const isPageRefreshed = performance.navigation.type === 1;

    if (isPageRefreshed) {
      dispatch({ type: 'SET_PITCH_ALLEY_CLEAR', })

    }
  }, []);

  const onReadyHandle = () => {

  }

  const handleOnEnded = () => {
    dispatch({ type: 'SET_NEXT_TO_CURRENT_PITCH_ALLLEY' })
    dispatch({ type: 'SET_TRUE_PITCH_ALLEY' })

  }
  const onPauseHandle = () => {

    dispatch({ type: 'SET_FALSE_PITCH_ALLEY' })

  }
  const onPlayHandle = () => {

    dispatch({ type: 'SET_TRUE_PITCH_ALLEY' })

  }
  const onMouseEnterHandle = () => {

    setTimeout(() => {
      setBtn('view my profile  >>>')
    }, 100);

  }
  const onMouseLeaveHandle = () => {

    setTimeout(() => {
      setBtn(STORE.current.name)
    }, 100);

  }
  return (
    <>
      <div className="landing_pitch">
        <ArrowLeftIcon onClick={() => dispatch({ type: 'SET_PREV_TO_CURRENT_PITCH_ALLLEY' })} sx={{ color: 'red', fontSize: { xs: "2rem", md: "7rem" }, cursor: 'pointer' }} />
        <div className="vdo_div" style={{ position: 'relative', background: 'none', marginTop: "2rem" }}>
          <ReactPlayer className="ptch_vdo_player" playing={playing} controls config={{
          file: {
            attributes: {
              controlsList: 'nodownload', // This disables the download button
              disablePictureInPicture: true, // Optionally disable PiP
            },
          },
        }} url={STORE?.current?.pitchVdo} onEnded={handleOnEnded} style={{ background: '', position: 'absolute', }} onReady={onReadyHandle} onPause={onPauseHandle} onPlay={onPlayHandle} width={"100%"} height={"100%"} />
          {!playing &&
            <PlayCircleIcon className="play_icon" sx={{ color: 'red', top: { xs: "44%", md: "25%" }, left: '40%', position: 'absolute', fontSize: { xs: "2rem", md: "5rem" }, cursor: 'pointer', pointerEvents: "none" }} onClick={() => dispatch({ type: 'SET_TRUE_PITCH_ALLEY' })
            } />
          }
          <div onMouseEnter={() => onMouseEnterHandle()} onMouseLeave={() => onMouseLeaveHandle()}>
            <Heading level={6} text={btn} className={'pitch_vide_card_name'} onClick=
            
            {() => localStorage.getItem('token') ?
              navigate(`/MainDashboard/UserProfile/${STORE?.current?.name}/${STORE?.current?.userId}`)
              :
              navigate(`/Profile/${STORE?.current?.name}/${STORE?.current?.userId}`)
            }
           >
            {btn}
            </Heading>
          </div>
        </div>
        <ArrowRightIcon onClick={() => dispatch({ type: 'SET_NEXT_TO_CURRENT_PITCH_ALLLEY' })} sx={{ color: 'red', fontSize: { xs: "2rem", md: "7rem" }, cursor: 'pointer', }} />
      </div>
    </>
  );
};




export default PitchCraousel;