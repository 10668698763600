import React from "react";
import "./PostCard.css";
import { useDispatch, useSelector } from "react-redux";
import { getImgURL } from "../../../util/getImgUrl";
import DeleteIcon from "@mui/icons-material/Delete";
import { callPopUp, postSentiment } from "../../../Action";
import { useParams } from "react-router-dom";
import { Edit } from "@mui/icons-material";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { formatTimestamp } from "../../../assets/dateFun";
import { Avatar, Box, Paper, Tooltip } from "@mui/material";
import ShareIcon from '@mui/icons-material/Share';

const regex = /(https?:\/\/[^\s]+)/g;
const Text = ({ text }) => {
    const seprateLink = (props) => {
        const propsSplit = props?.split("\n");
        return propsSplit;
    };

    return (
        <p>
            {text.map((item) => {
                return item.match(regex) ? (
                    <>
                        <span>
                            {seprateLink(item)?.length > 1 ? seprateLink(item)?.[0] : ""}{" "}
                        </span>
                        <a
                            style={{
                                color: "blue",
                                width: "100%",
                                display: "inline-block",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                wordWrap: "break-word",
                                // wordBreak:"break-all"
                            }}
                            href={
                                seprateLink(item)?.length > 1
                                    ? seprateLink(item)?.[1]
                                    : seprateLink(item)?.[0]
                            }
                            target="_blank"
                            rel="noreferrer"
                        >
                            {seprateLink(item)?.length > 1
                                ? seprateLink(item)?.[1]
                                : seprateLink(item)?.[0]}
                        </a>{" "}
                    </>
                ) : (
                    item + " "
                );
            })}
        </p>
    );
};

const PostCard = ({ item, otherData }) => {
    const dispatch = useDispatch();
    const params = useParams();
    const { userId } = useParams();
    const myUserId=useSelector((state)=>state?.getProfileData?.userData?._id)
   
    const deletePost = async (props) => {
        dispatch(callPopUp({ type: "DeletePost", data: props }));
    };
    const handlePostSentiment = (_id, type) => {
        dispatch(postSentiment(params.userId, _id, type))
    }
    const handleShareClick = async(postId) => {
        try {
            await navigator.share({
                title: 'MeBookMeta post',
                text: 'Check out my latest post',
                url: `https://mebookmeta.com/singlepost/${postId}`,
            });
            console.log('Sharing successful');
        } catch (error) {
            console.error('Error sharing:', error);
        }
    }
    return (
        <>
            <Box component={Paper} sx={{ background: "#6C22A6", boxShadow: 3 }} className="friends_post p-0">
                <Box className="friend_post_top p-2">
                    <div className="img_and_name">
                        <img
                            src={
                                otherData?.userProfPic
                                    ? otherData?.userProfPic
                                    : "https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-PNG-Photos.png"
                            }
                            alt="img"
                        />

                        <div className="friends_name p-2">
                            <p style={{ fontSize: "14px", paddingTop: "8px" }} className="friends_name">{otherData?.userName}</p>
                            <p className="time text-light">{formatTimestamp(item?.createdAt)}</p>
                        </div>
                    </div>

                    <div style={{ display: 'flex', alignItems: "flex-end", gap: "10px" }}>
                        {localStorage.getItem('token')&&
                        
                        userId === myUserId &&
                            <>
                                <Avatar sx={{ background: "#eee", width: 35, height: 35, boxShadow: 3 }}>
                                    <Tooltip title="Delete">
                                        <DeleteIcon onClick={() => deletePost(item?._id)} sx={{ cursor: 'pointer', color: "red" }} />
                                    </Tooltip>                                </Avatar>
                                <Avatar sx={{ background: "#eee", width: 35, height: 35, boxShadow: 3 }}>
                                    <Tooltip title="Edit">
                                        <Edit style={{ cursor: "pointer", color: "green" }} onClick={() => dispatch(callPopUp({ type: "CreatePost", data: { process: "Edit", _id: item?._id } }))} />
                                    </Tooltip>
                                </Avatar>
                            </>
                        
                            

                        }



                    </div>
                </Box>
                <div className="" style={{ marginTop: "6px", padding: "0 13px", fontWeight: "500" }}>
                    <Text text={item?.description} />
                </div>
                {item.file && <embed src={getImgURL(item?.file)} />}


                <div className="col-12 bg-none d-flex align-items-center justify-content-between text-light m-0 p-2" >
                    <div >
                        <ThumbUpIcon sx={{ fontSize: '1.5rem', color: 'orange', marginRight: '10px' }} />
                        <span style={{ fontSize: '1rem' }}>{item?.likes}</span>
                    </div>
                    <div >
                        <ThumbDownIcon sx={{ fontSize: '1.5rem', color: 'silver', marginRight: '10px' }} />
                        <span style={{ fontSize: '1rem' }}>{item?.dislikes}</span>
                    </div>
                </div>

                <div className="col-12 d-flex align-items-center justify-content-around m-2 p-0">
                    <button className={`col-4 ${item?.likedBy?.includes(otherData?.thisUserId) ? 'post_liked' : 'post_like'}`} style={{ height: '3.5rem', outline: 'none', border: '2px solid white' }} onClick={() => handlePostSentiment(item?._id, 'like')}>
                        <ThumbUpIcon className={`${item?.likedBy?.includes(otherData?.thisUserId) ? 'post_liked_icon' : 'post_like_icon'}`} />
                    </button>
                    <button className={`col-4 ${item?.dislikedBy?.includes(otherData?.thisUserId) ? 'post_disliked' : 'post_dislike'}`} style={{ height: '3.5rem', outline: 'none', border: '2px solid white' }} onClick={() => handlePostSentiment(item?._id, 'dislike')}>
                        <ThumbDownIcon className={`${item?.dislikedBy?.includes(otherData?.thisUserId) && 'post_disliked_icon'}`} />
                    </button>
                    <Avatar onClick={() => handleShareClick(item?._id)} sx={{ cursor: "pointer", background: "black" }}>
                        <ShareIcon sx={{ color: "#white" }} />
                    </Avatar>
                </div>

            </Box>
        </>
    );
};
export default PostCard;