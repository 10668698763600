export const BackGround = {
    paddingTop: '', background: 'rgb(0,0,0)',
    background: 'linear-gradient(245deg, rgba(0,0,0,0.9) 69%, rgba(255,0,0,1) 100%)'
    
}
export const newButton = {
    background: 'rgb(14,0,255)',
    background: 'linear-gradient(90deg, rgba(14,0,255,1) 0%, rgba(255,0,0,1) 100%)', color: 'whitesmoke', animation: 'ease .1s',
    '&:hover': {
        background: 'rgb(14,0,255)',
        background: 'linear-gradient(245deg, rgba(14,0,255,1) 0%, rgba(255,0,0,1) 100%)', animation: 'ease .1s'
    },
    border: '1.5px solid #1C6DD0'

}
export const textHover = {
    color: 'white',
    // fontSize: '50px',
    '&:hover': {
        color: 'lightGray',
        animation: 'ease .1s',
        
    }
}