// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-bar {
    position: fixed;
    top: 50%;
    left:93%;
    transform: translateY(-50%);
    z-index: 999;
  }
  
  .icon-bar a {
    display: block;
    text-align: center;
    padding: 16px;
    transition: all 0.3s ease;
    color: white;
    font-size: 20px;
  }
  
  .icon-bar a:hover {
    background-color: #000;
  }
  
  .facebook {
    background: #3B5998;
    color: white;
  }
  
  .twitter {
    background: #55ACEE;
    color: white;
  }
  
  .google {
    background: #dd4b39;
    color: white;
  }
  
  .linkedin {
    background: #007bb5;
    color: white;
  }
  
  .youtube {
    background: #bb0000;
    color: white;
  }
  
  @media screen and (min-width:912px) {
    .icon-bar {
        left:90%;
    }
}

@media screen and (min-width: 500px) and (max-width: 912px) {
  .icon-bar {
    left: 87%;
  }
}
@media screen and (max-width:499px) {
    .icon-bar {
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/Component new/userWork/workViewSocial/WVSocial.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,QAAQ;IACR,QAAQ;IAGR,2BAA2B;IAC3B,YAAY;EACd;;EAEA;IACE,cAAc;IACd,kBAAkB;IAClB,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,mBAAmB;IACnB,YAAY;EACd;;EAEA;IACE,mBAAmB;IACnB,YAAY;EACd;;EAEA;IACE,mBAAmB;IACnB,YAAY;EACd;;EAEA;IACE,mBAAmB;IACnB,YAAY;EACd;;EAEA;IACE,mBAAmB;IACnB,YAAY;EACd;;EAEA;IACE;QACI,QAAQ;IACZ;AACJ;;AAEA;EACE;IACE,SAAS;EACX;AACF;AACA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".icon-bar {\n    position: fixed;\n    top: 50%;\n    left:93%;\n    -webkit-transform: translateY(-50%);\n    -ms-transform: translateY(-50%);\n    transform: translateY(-50%);\n    z-index: 999;\n  }\n  \n  .icon-bar a {\n    display: block;\n    text-align: center;\n    padding: 16px;\n    transition: all 0.3s ease;\n    color: white;\n    font-size: 20px;\n  }\n  \n  .icon-bar a:hover {\n    background-color: #000;\n  }\n  \n  .facebook {\n    background: #3B5998;\n    color: white;\n  }\n  \n  .twitter {\n    background: #55ACEE;\n    color: white;\n  }\n  \n  .google {\n    background: #dd4b39;\n    color: white;\n  }\n  \n  .linkedin {\n    background: #007bb5;\n    color: white;\n  }\n  \n  .youtube {\n    background: #bb0000;\n    color: white;\n  }\n  \n  @media screen and (min-width:912px) {\n    .icon-bar {\n        left:90%;\n    }\n}\n\n@media screen and (min-width: 500px) and (max-width: 912px) {\n  .icon-bar {\n    left: 87%;\n  }\n}\n@media screen and (max-width:499px) {\n    .icon-bar {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
