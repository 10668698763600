import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from '@mui/material';
const MuiAccordian = ({ accBg, iconColor, accSumTypo, accDetailTypo, accSummary, accDetails, accDetailsExtra }) => {
  return (
    <div>
      <Accordion sx={accBg}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={iconColor} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={accSumTypo}>{accSummary}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={accDetailTypo}>{accDetails}</Typography>
        </AccordionDetails>
        <AccordionDetails>
          <Typography sx={accDetailTypo}>{accDetailsExtra}</Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default MuiAccordian