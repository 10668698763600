import React, { useState } from 'react'
// import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import './WorkCard.scss';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { BackGround } from '../background';
import DeleteIcon from '@mui/icons-material/Delete'; import { CardActionArea } from '@mui/material';
import CopyrightIcon from '@mui/icons-material/Copyright';
import { useLocation, useNavigate } from 'react-router-dom';
import { SERVER } from '../../server/server';
import { newButton, textHover } from '../background';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import { useDispatch, useSelector } from 'react-redux';
import { delUserWork, getAuth } from '../../Action';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import PropTypes from "prop-types";
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { getImgURL } from '../../util/getImgUrl';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    className=""
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 20,
                        top: 20,
                        //    backgroundColor: (theme) => theme.palette.black,
                    }}
                >
                    <CloseIcon
                        sx={{
                            fontSize: "50px",
                            color: "black",
                            borderRadius: "50%",
                            backgroundColor: "white",
                        }}
                    />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};


export const DeleteWork = ({ work }) => {

    const [openDel, setOpenDel] = React.useState(false);
    const handleOpenDel = () => setOpenDel(true);
    const handleCloseDel = () => setOpenDel(false);
    const dispatch = useDispatch()
    const handleDelete = (id) => {
        dispatch(delUserWork(id))

    }

    const styleDelete = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 450,
        bgcolor: "#2f2841",
        border: 'none',
        borderRadius: '5px',
        boxShadow: 24,
        padding: '1.5rem 1.5rem 0rem 1.5rem',
    };

    return (
        <>
            <Button onClick={() => setOpenDel(true)} style={{ color: 'white', cursor: 'pointer', '&:hover': { color: '#EFEFEF' } }}>
                <DeleteIcon />
                Delete
            </Button>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openDel}
                onClose={handleCloseDel}
                closeAfterTransition
            >
                <Box sx={styleDelete}>
                    <h6 id="transition-modal-description" style={{ color: "#fff", textAlign: 'center' }}>
                        Are you sure to delete this work permanently ?
                    </h6>
                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly', padding: "1rem" }}>
                        <Button onClick={handleCloseDel} variant="contained"
                            size="large"
                            sx={{
                                ...newButton,
                                py: "6px",
                                width: "6rem",
                                fontSize: "75%",
                            }}>Cancel</Button>

                        <Button varient="contained" onClick={() => { handleDelete(work._id); }} variant="contained"
                            size="large"
                            sx={{
                                ...newButton,
                                py: "6px",
                                width: "6rem",
                                fontSize: "75%",
                            }}>Delete</Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}


const WorkCard = ({ id, work, idx, headingStyle }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const getImgURLPdf = (name) => `${SERVER}/uploads/${name}#toolbar=0`
    const theme = useTheme();
    const bg = { background: 'linear-gradient(90deg, rgba(14,0,255,1) 0%, rgba(255,0,0,1) 100%)', display: 'flex', maxWidth: '70%', maxHeight: '60%', margin: 'auto', marginTop: '2rem', marginBottom: '2rem', borderRadius: '1rem' }
    const delBtn = { ...textHover, height: '2rem', width: '2rem', marginRight: '1rem', cursor: 'pointer' }
    const cardName = { ...textHover, marginLeft: '1rem' }
    const cardCategory = { margin: 0, color: 'lightGray' }
    const cardTitle = { ...textHover }
    // console.log(getAuth() ,id)
    const modalStyle = {
        ...BackGround,
        // position: 'absolute',
        // top: '50%',
        // left: '55%',
        transform: 'translate(-50%, -50%)',
        // width: '75%',
        // bgcolor: 'white',
        border: '2px solid #000',
        // boxShadow: 24,
        // height: '100vh',
        // overflow: 'auto',
        // p: 4,
    };

    // const styleDelete = {
    //     position: 'absolute',
    //     top: '50%',
    //     left: '50%',
    //     transform: 'translate(-50%, -50%)',
    //     width: 450,
    //     bgcolor: '#191c24',
    //     border: '1px dotted #fff',
    //     boxShadow: 24,
    //     padding: '1.5rem 1.5rem 0rem 1.5rem',
    // };

    // for work modal
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    // // for delete modal
    // const [openDel, setOpenDel] = React.useState(false);
    // const handleOpenDel = () => setOpenDel(true);
    // const handleCloseDel = () => setOpenDel(false);
    const STORE = useSelector((state) => state);
    const userData = localStorage.getItem('userData');
    // const handleDelete = (id) => {
    //     dispatch(delUserWork(id))

    // }

    const tagsHover = {
        color: 'white',
        fontSize: '.8rem',
        '&:hover': {
            color: 'red',
            animation: 'ease .1s',

        }
    }
    const getDate = (str) => {
        // var str = "Fri Feb 08 2013 09:47:57 GMT +0530 (IST)";
        var date = new Date(str);
        var day = date.getDate(); //Date of the month: 2 in our example
        var month = date.getMonth(); //Month of the Year: 0-based index, so 1 in our example
        var year = date.getFullYear() //Year: 2013
        return `${day}/${month + 1}/${year}`;

    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '.5rem' }}>
            <p style={{fontFamily:''}}> <span style={{ color: 'lightGray', textAlign: 'left', fontSize: '1.2rem', ...headingStyle, fontWeight: 'bold' ,fontFamily:''}}>Work Sample #{idx + 1}: </span> <span style={{ fontSize: '1rem', color: 'white',fontFamily:'' }}>  {work?.title}</span></p>
            {/* NewWorkCard start */}

            {
                <div className="blog-card">

                    <div className="meta">
                        <div className="photo"
                            style={{ backgroundImage: `url(${getImgURL(work?.coverPicture)})` }}

                        >

                            {/* <img src={getImgURL(work?.coverPicture)}/>    */}
                        </div>

                        <ul className="details">
                            <li className="author"><a href="#">

                                {<Avatar
                                    alt={work?.userId?.name}
                                    src={getImgURL(work?.userId?.pdf)}

                                    sx={{ width: 24, height: 24 }}
                                />}
                                {work?.userId?.name}</a></li>
                            <li className="date"><CalendarMonthIcon />{getDate(work?.createdAt
                            )}</li>
                            <li>&nbsp;</li><li>&nbsp;</li><li>&nbsp;</li><li>&nbsp;</li><li>&nbsp;</li><li>&nbsp;</li>
                            <li className="tags">
                                <ul>
                                    {
                                        STORE?.getProfileData?.success ?
                                            <>
                                                {
                                                    id == STORE.getProfileData?.userData?._id ?
                                                        <>
                                                            <li>{<DeleteWork work={work} />}</li>
                                                            <li>&nbsp;</li>
                                                            <li>&nbsp;</li>
                                                            <li>&nbsp;</li>
                                                            <li>&nbsp;</li>
                                                            <li onClick={(e) => navigate('/MainDashboard/createStory/Edit', { state: { workId: work._id } })} style={{ color: 'white', cursor: 'pointer', '&:hover': { color: '#EFEFEF' } }}>
                                                                <EditIcon />
                                                                Edit
                                                            </li>
                                                        </>

                                                        : ""
                                                }
                                            </>
                                            : ""
                                    }
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div className="description">
                        <h1 style={{ fontSize: "125%",fontFamily:'' }}>{`Title: ${work?.title}`}</h1>
                        <h2 style={{ fontSize: "100%",fontFamily:'' }}>{`By ${work?.userId?.name}`}</h2>
                        <p style={{ fontSize: "100%",fontFamily:'' }}>{`Description: ${work?.description?.slice(0, 15).join(' ')}...`}</p>
                        <p> <span style={{ fontSize: '75%' }}><CopyrightIcon sx={{ width: '1rem', color: 'red' ,fontFamily:''}} /><span>Disposition of rights: {work?.copyrightId?.copyright}</span></span></p>
                        <p className="read-more">
                            <Button sx={{ ...newButton }} variant='contained' size="small"

                                onClick={() => {
                                        getAuth() ? (!id ?
                                            navigate(`/WorkView/${work?.userId?._id}/${work?._id}`)
                                            :
                                            navigate(`/MainDashboard/WorkView/${work?.userId?._id}/${work?._id}`)) : navigate(`/WorkView/${work?.userId?._id}/${work?._id}`)

                                }}

                            >Sample</Button>
                        </p>
                    </div>
                </div>
            }
            {/* NewWorkCard end */}


            {/* calling Modal here */}


            <BootstrapDialog
                className=""
                fullScreen
                TransitionComponent={Transition}
                open={open}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"

            >
                <DialogContent
                    className=""
                    // style={BackGround}
                    dividers
                >
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={() => setOpen(!open)}
                    >

                    </BootstrapDialogTitle>
                    <div className='row justify-content-center' style={{ position: 'relative' }}>

                        <Avatar
                            alt=""
                            src={getImgURL(work.userId.pdf)}
                            sx={{ width: 60, height: 60, cursor: 'pointer', position: 'sticky', top: '10px', zIndex: 9999 }}
                            onClick={() => navigate(`/MainDashboard/UserProfile/${work.userId.name}/${work.userId._id}`)}
                        />
                        <div className='col-md-10' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography variant="h4" gutterBottom sx={{ ...textHover, color: 'black' }}

                            ><span style={{ fontWeight: 'bold', marginRight: '5px' }}>TITLE:</span>
                                {work?.title.toUpperCase()}
                            </Typography>




                            <img style={{ width: '50%', height: '50%', marginBottom: '1rem', }} src={getImgURL(work.coverPicture)} alt='Work Cover Picture' />
                            <Typography variant="h4" gutterBottom sx={{ color: 'black', textAlign: 'justify', lineHeight: '1.6' }}

                            >
                                {
                                    work.subCategory.map((item, idx) => (
                                        <span key={idx} style={{ background: '#30E3DF', borderRadius: '10px', marginRight: '4px', padding: '0 8px 8px 8px', cursor: 'pointer', margin: '5px' }}>
                                            <span style={tagsHover}>
                                                {
                                                    item.name.toUpperCase()}
                                                {work.subCategory.length - 1 === idx ? '' : ', '}

                                            </span>
                                        </span>


                                    ))
                                }
                            </Typography>

                            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10px' }}>
                                <p style={{ margin: '0 15px 0 15px' }}><ThumbUpOffAltIcon sx={{ color: 'blue' }} style={{ width: '30px', height: '30px' }} /><span style={{ fontSize: '1.2rem', color: 'blue' }}>{work.liked}</span></p>
                                <p style={{ margin: '0 15px 0 15px' }}><AccessTimeIcon sx={{ color: 'blue' }} style={{ width: '30px', height: '30px' }} /><span style={{ fontSize: '1.2rem', color: 'blue' }}>{getDate(work.createdAt
                                )}</span></p>

                            </Box>
                            <Typography gutterBottom sx={cardCategory} style={{ color: 'black', marginTop: '1rem' }} >
                                <span style={{ fontWeight: 'bold', display: 'flex', flexDirection: 'column', fontSize: '1.3rem', alignItems: 'center' }}>DESCRIPTION:</span>
                                <br></br>
                                <p style={{ fontSize: '1rem', paddingLeft: '3rem' }}>
                                    {work?.description.join(" ")}

                                </p>

                            </Typography>


                            <div className='col-md-12 justify-content-center' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                                {work.youtubeUrl ? (<embed src={`https://www.youtube.com/embed/${work.youtubeUrl}`} type="" height='50%' width='100%' />)
                                    : (
                                        <embed src={getImgURLPdf(work.userWork)} type="" height='90%' width='85%' />
                                    )}

                            </div>


                        </div>


                    </div>
                </DialogContent>
            </BootstrapDialog>

            {/* <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                style={{ height: '100vh', overflow: 'scroll' }}
            // BackdropComponent={Backdrop}
            // BackdropProps={{
            //   timeout: 500,
            // }}
            >
                <Fade in={open}>
                    <Box sx={modalStyle}>
                        <CancelIcon onClick={() => setOpen(!open)} sx={{ color: 'white', fontSize: '50px', float: 'right', cursor: 'pointer' , marginBottom:'2rem'}} />
                        {/* <div style={{display:'flex', flexDirection:'row'}}>
                        <div style={{}}>
                        <img style={{ width: '30%', height: '40%', marginBottom: '1rem' }} src={getImgURL(work.coverPicture)} alt='Work Cover Picture' />
                        <Typography variant="h5" gutterBottom sx={{ ...textHover, width:'30%' }}
                        
                        > Tittle:
                            {work?.title}
                        </Typography>
                        <Typography variant="body1" gutterBottom sx={cardCategory} > Description:
                            {
                                work?.description.join(" ")

                            }
                        </Typography>
                        </div>
                       
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: "1rem", width:'40%' }} >
                        <div >
                            {work.youtubeUrl ? (<embed src={`https://www.youtube.com/embed/${work.youtubeUrl}`} type="" height='100%' width='100%' />)
                                : (
                                    <embed src={getImgURLPdf(work.userWork)} type="" height='100%' width='100%' />
                                )}
                        </div>
                        </div>
                        </div> */}
            {/* <div style={{display:'flex',flexDirection:'row'}}>
                            <div style={{marginTop:'4rem', width:'500px'}}>
                            <img style={{ width: '80%', height: '40%', marginBottom: '1rem' }} src={getImgURL(work.coverPicture)} alt='Work Cover Picture' />
                        <Typography variant="h5" gutterBottom sx={{ ...textHover, }}
                        
                        > Tittle:
                            {work?.title}
                        </Typography>
                        <Typography  gutterBottom sx={cardCategory} style={{marginRight:'10px'}} > Description:
                            {
                                work?.description.join(" ")

                            }
                        </Typography>
                            </div>
                            <div style={{marginTop:'4rem'}}>
                            
                            {work.youtubeUrl ? (<embed src={`https://www.youtube.com/embed/${work.youtubeUrl}`} type="" height='700px' width='500px' />)
                                : (
                                    <embed src={getImgURLPdf(work.userWork)} type="" height='700px' width='500px' />
                                )}
                            </div>

                        </div> */}
            {/* </Box> */}
            {/* </Fade> */}
            {/* </Modal>  */}

            {/* delete modal calling below */}
            {/* <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openDel}
                onClose={handleCloseDel}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openDel}>
                    <Box sx={styleDelete}>

                        <h6 id="transition-modal-description" style={{ color: "#fff" }}>
                            Are you sure to delete this work permanently ?
                        </h6>
                        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly', padding: "1rem" }}>
                            <Button onClick={handleCloseDel} varient="outline">Cancel</Button>

                            <Button varient="contained" onClick={() => { handleDelete(work._id); }} style={{ color: '#fff', background: "#EB1616" }}>Delete</Button>


                        </Box>
                    </Box>
                </Fade>
            </Modal> */}



        </div >
    )
}

export default WorkCard







{/* Old NewWorkCard start */ }
{/* <Card sx={bg}>
    <CardMedia
        component="img"
        sx={{ width: 190 }}
        image={getImgURL(work.coverPicture)}
        alt="Picture not uploaded"
    />
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Avatar
                        alt=""
                        src={getImgURL(work.userId.pdf)}
                        sx={{ width: 60, height: 60, cursor: 'pointer' }}
                        onClick={() => {

                            !id ?
                                navigate(`/MainDashboard/UserProfile/${work.userId.name}/${work.userId._id}`)
                                :
                                navigate(`/Profile/${work.userId.name}/${work.userId._id}`)
                        }
                        }
                    />

                    <Typography sx={cardName} component="div" variant="h5">
                        {work?.userId.name}

                    </Typography>
                </Box>

                {
                    STORE.getProfileData.success ?
                        <>
                            {
                                !id ?

                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <DeleteIcon sx={{ color: 'white', cursor: 'pointer', '&:hover': { color: 'lightgray' } }} onClick={() => setOpenDel(true)} />

                                        <EditIcon sx={{ color: 'white', cursor: 'pointer', '&:hover': { color: 'lightgray' } }} onClick={(e) => navigate('/MainDashboard/createStory', { state: { id: work._id } })} />


                                    </Box>

                                    : ""
                            }
                        </>
                        : ""
                }


            </Box>

        </CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', pl: 1, pr: 3, pb: 1, color: 'white' }}>
            <Typography variant="h5" gutterBottom sx={cardTitle}
            > Title :&nbsp;
                {work?.title}
            </Typography>
            <Typography variant="body1" gutterBottom> Description:
                {
                    work?.description.slice(0, 15).join(' ')

                }{`....`} <span onClick={() => {
                    !id ?
                        navigate(`/MainDashboard/WorkView/${work?.userId?._id}/${work?._id}`)
                        :
                        navigate(`/WorkView/${work?.userId?._id}/${work?._id}`)
                }} style={{ color: 'black', cursor: 'pointer', fontWeight: 'bold' }}>see more</span>
            </Typography>
            <span style={{ fontSize: '.8rem' }}><CopyrightIcon sx={{ width: '1rem', color: 'red' }} /><span>Disposition of rights: {work.copyright}</span></span>
        </Box>

    </Box>

</Card> */}
{/* Old NewWorkCard start */ }