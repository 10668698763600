import React, { useState } from "react";
import { AiOutlineLogout } from "react-icons/ai";
import { CiMenuFries } from "react-icons/ci";
import image from "../../../../mepic.png";
import { motion, AnimatePresence } from "framer-motion";
import { Avatar, IconButton } from "@mui/material";
import { LuArrowUpRightFromCircle } from "react-icons/lu";
import { MdCancelPresentation } from "react-icons/md";
export const searchCat = [
    { title: "Author", checked: false },
    { title: "Writer", checked: false },
    { title: "Music", checked: false },
    { title: "Audio", checked: false },
    { title: "Music Video", checked: false },
    { title: "Film", checked: false },
    { title: "Television", checked: false },
    { title: "Print or Media", checked: false },
    { title: "Visual Arts", checked: false },
    { title: "Performing Arts", checked: false }
];

const LandingNavbar = () => {
    const [show, setShow] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [checkedItems, setCheckedItems] = useState({});
    const [barClick, setBarClick] = useState(false)

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckedItems({ ...checkedItems, [name]: checked });
    };

    const handleShow = () => {
        setShow(true);
    };

    const handleHide = () => {
        setShow(false);
    };

    const handleFocus = () => {
        setShowFilter(true);
    };

    const handleCloseFilterBox = () => {
        setShowFilter(false);
    };

    const handleBarShow = () => {
        setBarClick(true);
    }

    return (
        <>
            <div className="w-full h-20 flex items-center justify-between bg-white border lg:px-8 px-2 relative">
                <div className=" flex items-center lg:flex hidden">
                    <img
                        src={image}
                        alt="logo"
                        width={60}
                        height={60}
                        className="cursor-pointer "
                    />
                    <h2 className="text-2xl font-bold tracking-widest text-gray-700 mt-2 ">BookMeta</h2>
                </div>
                <div className="relative">
                    <CiMenuFries className="lg:hidden cursor-pointer" onClick={handleBarShow} />
                    <AnimatePresence>
                            {barClick && (
                                <motion.div
                                    className="shadow-xl bg-white absolute top-[20px] left-0 flex flex-col gap-x-4 z-50 p-2 rounded-md"
                                    initial={{ opacity: 0, y: -10 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -10 }}
                                    transition={{ duration: 0.2 }}
                                >
                                    {searchCat.map((item, index) => (
                                        <motion.div
                                            key={index}
                                            className="text-black cursor-pointer hover:bg-gray-100 whitespace-nowrap hover:tracking-widest duration-300  px-3 p-1 rounded-sm"
                                            initial={{ opacity: 0, y: -10 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            exit={{ opacity: 0, y: -10 }}
                                            transition={{ duration: 0.2 }}
                                        >
                                            {item.title}
                                        </motion.div>
                                    ))}
                                </motion.div>
                            )}
                        </AnimatePresence>
                </div>
                <div className="">
                    <input
                        className="border px-4 py-2 lg:w-[400px] md:w-96 w-32 rounded-md outline-none"
                        type="text"
                        placeholder="Search"
                        onFocus={handleFocus}

                    />
                </div>
                <div className="flex items-center justify-center gap-x-12 lg:flex hidden  h-full">
                    <p className="text-gray-700 cursor-pointer font-semibold p-0 m-0 tracking-widest">
                        GOOD TO KNOW
                    </p>
                    <div
                        className="relative  h-full flex items-center justify-center"
                        onMouseEnter={handleShow}
                        onMouseLeave={handleHide}
                    >
                        <p className="text-gray-700 cursor-pointer font-semibold p-0 m-0 tracking-widest">
                            WORKS
                        </p>
                        <AnimatePresence>
                            {show && (
                                <motion.div
                                    className="shadow-xl bg-white absolute top-[79px] -left-10 flex flex-col gap-x-4 z-50 p-2 rounded-md"
                                    initial={{ opacity: 0, y: -10 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -10 }}
                                    transition={{ duration: 0.2 }}
                                >
                                    {searchCat.map((item, index) => (
                                        <motion.div
                                            key={index}
                                            className="text-black cursor-pointer hover:bg-gray-100 whitespace-nowrap hover:tracking-widest duration-300  px-3 p-1 rounded-sm"
                                            initial={{ opacity: 0, y: -10 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            exit={{ opacity: 0, y: -10 }}
                                            transition={{ duration: 0.2 }}
                                        >
                                            {item.title}
                                        </motion.div>
                                    ))}
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>
                    <div className="text-gray-700 rounded-md cursor-pointer font-semibold border px-3 p-2 m-0 tracking-widest flex items-center justify-center">
                        LOGIN <AiOutlineLogout className="ml-2" />
                    </div>
                </div>

                <div className="lg:hidden text-gray-700 rounded-md cursor-pointer font-semibold  px-3 p-2 m-0 tracking-widest flex items-center justify-center text-sm">
                    LOGIN <AiOutlineLogout className="ml-2" />
                </div>
                {/* filter div */}
                <AnimatePresence >
                    {
                        showFilter && (
                            <motion.div
                                initial={{ opacity: 0, y: -10 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -10 }}
                                transition={{ duration: 0.2 }}
                                className="lg:w-6/12 w-full border absolute top-[79px] left-0 right-0 mx-auto bg-white flex z-50 lg:p-4 p-3 rounded-sm justify-center h-[300px] space-x-4 ">

                                <div className="lg:w-4/12 md:w-5/12 w-4/12 flex flex-col  overflow-y-auto ">
                                    <p className="text-gray-700 cursor-pointer p-0 m-0 mb-3 font-bold text-sm lg:flex md:flex hidden">
                                        Filter By Category
                                    </p>
                                    {searchCat.map((item, index) => (
                                        <label className="inline-flex items-center cursor-pointer" key={index}>
                                            <input
                                                type="checkbox"
                                                checked={checkedItems[item.title] || false}
                                                onChange={handleCheckboxChange}
                                                name={item.title}
                                                className="form-checkbox h-3 w-3 lg:h-4 lg:w-4 text-blue-500"
                                            />
                                            <span className="ml-2 text-gray-700 lg:text-sm md:text-sm text-xs">{item.title}</span>
                                        </label>
                                    ))}
                                </div>
                                <div className="lg:w-8/12 md:w-7/12 w-8/12 overflow-y-auto ">



                                    {
                                        Array(40).fill(20).map((item, index) => (
                                            <div className="w-full flex items-center justify-between space-x-3 mb-2 ">
                                                <Avatar sx={{ width: 30, height: 30 }} alt="Remy Sharp" src="https://fastly.picsum.photos/id/1/5000/3333.jpg?hmac=Asv2DU3rA_5D1xSe22xZK47WEAN0wjWeFOhzd13ujW4" />
                                                <div className="text-gray-700 lg:text-sm md:text-sm text-xs ">Manu kumar </div>
                                                <div className="cursor-pointer rounded-sm px-2 p-1 flex items-center justify-center text-xs bg-gradient-to-r from-red-800 to-black duration-300 hover:bg-gradient-to-r hover:rounded-md text-white ">
                                                    <span className="lg:flex md:flex hidden">View Profile</span> <LuArrowUpRightFromCircle className="lg:ml-2 md:ml-2 sm:ml-0" />
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className="lg:flex md:flex hidden flex items-start justify-center top-0">
                                    <IconButton onClick={handleCloseFilterBox}>
                                        <MdCancelPresentation />
                                    </IconButton>
                                </div>

                                <div className="lg:hidden md:hidden flex absolute -bottom-10  bg-white w-14 h-14 flex items-center justify-center rounded-full shadow-lg ">

                                    <IconButton onClick={handleCloseFilterBox}>
                                        <MdCancelPresentation />
                                    </IconButton>
                                </div>
                            </motion.div>
                        )
                    }

                </AnimatePresence>


            </div >

        </>
    );
};

export default LandingNavbar;
