import React from 'react';
import './PaymentSuccess.css';
import CloseIcon from '@mui/icons-material/Close';

import CheckIcon from '@mui/icons-material/Check';
import { newButton } from '../background';
import { EplanData } from '../User Dashboard/sideBarItem/SideBar';
import { useSelector } from 'react-redux';


const PaymentSuccess = ({ handleModalClose }) => {
    const STORE = useSelector((state) => state)
    const paymentDetails = STORE?.userDashboardDetail?.userWorkDetail?.subscriptionType
    const date = new Date(STORE?.userDashboardDetail?.userWorkDetail?.subscriptionType?.createdAt)
    console.log("date", date);
    console.log("STORE", STORE);
    console.log("paymentDetails", STORE?.userDashboardDetail?.userWorkDetail?.subscriptionType);

    const subscriptionDate = new Date(date);
    const newDate = new Date(subscriptionDate.getTime() + 14 * 24 * 60 * 60 * 1000);

    const PlanStartDate = `${newDate.getFullYear().toString().slice(-2)}/${(newDate.getMonth() + 1).toString().padStart(2, '0')}/${newDate.getDate().toString().padStart(2, '0')}`;

    console.log("PlanSatrtDate", PlanStartDate);

    return (
        // <div className="bg" style={{ ...newButton }}>
        <div className='main_card'>
            <div className="p__card" style={{ position: 'relative' }}>
                <div style={{ position: "absolute", right: 15, top: 15, cursor: "pointer", zIndex: 5 }}>
                    <CloseIcon sx={{ color: 'white', background: 'black', fontSize: '30px' }} onClick={() => handleModalClose('payment')} />
                </div>
                <span className="card__success"><CheckIcon sx={{
                    color: 'white',
                    lineHeight: '100px',
                    fontSize: '50px',
                    marginTop: '15px'
                }} /></span>
                <h1 className="card__msg">
                    Congratulations, New MeBooker! You have successfully registered with MeBookMeta. Now you can enjoy your Free Two-Week Trial. Your billing will start once the Trial Period ends.
                </h1>
                {/* <h2 className="card__submsg">Thank you for your transfer</h2> */}

                <div className="card__body mt-3">

                    <div className="card__recipient-info">
                        <p className="card__recipient">Standard Plan Starts: <span>{PlanStartDate}</span></p>
                        {/* <p className="card__recipient">Trial Expire on: <span>16-06-23</span></p> */}
                    </div>

                    {/* <h1 className="card__price"><span>$</span>35<span>.00</span></h1> */}
                    <div className="card__details">
                        <div className='card__details__inner' >
                            {
                                EplanData.map((item, index) => (index <= 10 ?
                                    <div key={index} style={{ display: 'flex', margin: '3px 0' }}>
                                        <div style={{}}><CheckIcon sx={{ color: 'white', background: 'green', marginRight: '5px', borderRadius: '50%', width: '15px', height: "15px" }} /></div>
                                        <h6 style={{ fontSize: '13px' }}>{item.title}</h6>
                                    </div> : ''
                                ))
                            }
                        </div>
                        <div className='card__details__inner' >
                            {
                                EplanData.map((item, index) => (index > 10 ?
                                    <div key={index} style={{ display: 'flex', margin: '3px 0' }}>
                                        <div style={{}}><CheckIcon sx={{ color: 'white', background: 'green', marginRight: '5px', borderRadius: '50%', width: '15px', height: "15px" }} /></div>
                                        <h6 style={{ fontSize: '13px' }}>{item.title}</h6>
                                    </div> : ''
                                ))
                            }
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default PaymentSuccess
