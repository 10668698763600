import React from 'react'
import './BackBtn.css'
import Button from "@mui/material/Button";
import { useNavigate } from 'react-router';
import { newButton } from '../../../../Component new/background';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const BackBtn = ({style}) => {
     const navigate=useNavigate()

  return (
    <div>
        <Button sx={{ ...style, ...newButton, fontSize: "70%"}} onClick={() => navigate(-1)} variant="contained">Go Back</Button>
    </div>
  )
}

export default BackBtn