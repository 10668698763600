let initialState = {
    userCreatorData : []
}

const getCreatorData = (state=initialState,action) =>{
    switch (action.type) {
        case "GET_CREATOR_DATA":
            return {
                ...state,
                userCreatorData:action.payload
            }
    
        default:
            return state
    }
}

export default getCreatorData;