



import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { SOCKET } from "../App";

export function useOwnIdentity () {
   const {getProfileData,userDashboardDetail} = useSelector((state)=>state)
   const [obj , setObj] = React.useState({
    ownId:"",
    ownName:"",
    ownEmail:"",
    ownPdf:""
   })
   useEffect(()=>{
      SOCKET.on('connect',()=>{
         if(getProfileData?.userData?._id){
            SOCKET.timeout(1000).emit("add-user", {ownId:getProfileData?.userData?._id,id:SOCKET.id});
         }
      })
      return ()=>SOCKET.off('connect')
   },[])
   React.useEffect(()=>{
    setObj({
        ownId:getProfileData?.userData?._id,
        ownName:getProfileData?.userData?.name,
        ownEmail:getProfileData?.userData?.email,
        ownPdf:getProfileData?.userData?.pdf,
        userRole:getProfileData?.userData?.userRole,
        interestProfile:userDashboardDetail?.userWorkDetail?.interestProfile

        })
   },[getProfileData?.userData])
   return obj
}