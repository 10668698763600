let initialState = {
    userData: []
}

const getData = (state = initialState, action) => {
    switch (action.type) {
        case "GET_DATA":
            return {
                ...state,
                userData: action.payload
            }
        case "CLEAR_USER_DATA":
        return {
            ...state,
            userData: []
        }

        default:
            return state
    }
}

export default getData;