const initialState = {
  loading: false,
  userMePost: [],
};

const getMePost = (state = initialState, action) => {
  switch (action.type) {
    case "CALLING_ME_POST":
      return {
        ...state,
        loading: true,
      };
    case "GET_ME_POST":
      // console.log('hii 14',action.payload)
      return {
        ...state,
        userMePost: action.payload,
        loading: false,
      };
      case "GET_ME_POST_UPDATE":

      // console.log('hello 20',state?.userMePost?.data,action.payload?.data)
      const indexToUpdate = state?.userMePost?.data?.findIndex((item) => item._id === action.payload?.data._id);
      // console.log('hello23',indexToUpdate)
      if (indexToUpdate !== -1) {
        const updatedArray = [...state?.userMePost?.data];
        updatedArray[indexToUpdate] = action.payload?.data;
        // console.log(updatedArray);
        return{
          ...state,
        loading: false,
        userMePost: {data:updatedArray},
        }
      } else {
        // console.log("Object not found with the specified _id.");
        return state
      }


    case "NOT_ME_POST":
      return {
        ...state,
        loading: false,
        userMePost: [],
      };
    default:
      return state;
  }
};
export default getMePost;
