import React, { useEffect, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavigate } from 'react-router-dom';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import './style.css';

// import required modules
import { Pagination, Navigation } from 'swiper/modules';
import axios from 'axios';
import { SERVER } from '../../../../server/server';
import { getImgURL } from '../../../../util/getImgUrl';


const TrendingWorks = () => {
  const [state, setState] = useState([])
  const navigate = useNavigate()

  let LandingAPI = async () => {
    await axios.get(`${SERVER}/dashboard/landingWork`).then((res) => {
      if (res) {
        setState(res.data.message);
      } else {
        console.log('failed');
      }

    }).catch((error) => console.log(error))
  }
  useEffect(() => {
    LandingAPI()
  }, [])

  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        Navigation={true}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 50,
          },
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {state?.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="flex flex-col space-y-4 w-full shadow-md">
              <img
                src={getImgURL(item.work.coverPicture)}
                alt="image"
                style={{ width: '100%', height: '300px', objectFit: 'cover' }}
              />

              <div className="flex flex-col w-full px-4">
                <p className="text-sm text-gray-600 text-start">{item.name}</p>
                <p className="font-bold text-start">{item.work.title}</p>
                <p className="text-sm text-gray-600 tracking-widest text-justify">
                  {item.work.description.slice(0, 20).join(' ')}...
                </p>

                <div onClick={() => navigate(`WorkView/${item.work?.userId}/${item.work?._id}`)} className="w-20 h-8 mb-4 flex items-center justify-center cursor-pointer bg-gradient-to-r from-cyan-800 to-black hover:rounded-md duration-300 text-white text-sm">
                  view
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}


export default TrendingWorks;
