import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './style.css';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Avatar } from '@mui/material';
import { useTheme } from '../../../../contextApi/themeContext';

const Slider = ({ slide }) => {
  const { theme } = useTheme();
  const videosRef = useRef([]);

  const handleSlideChange = (swiper) => {
    // Pause all videos
    videosRef.current.forEach((video) => {
      if (video) {
        video.pause();
      }
    });

    // Play the current slide's video
    const currentSlideVideo = videosRef.current[swiper.activeIndex];
    if (currentSlideVideo) {
      currentSlideVideo.play();
    }
  };

  const handleVideoLoaded = (video) => {
    if (video) {
      video.volume = 1.0; // Set volume to full
    }
  };

  return (
    <div className='lg:px-8 xl:px-32'>
      <Swiper
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
        onSlideChange={handleSlideChange}
      >
        {slide?.map((item, index) => (
          <SwiperSlide key={item.option + item.id}>
            <div className='flex flex-col items-center justify-center space-y-4 w-full' style={{ background: theme === 'dark' ? 'black' : 'white' }}>
              {item?.type === 'video' ? (
                <>
                  <h2 className='text-2xl font-bold text-black' style={{ color: theme === 'dark' ? 'white' : 'black' }}>{item.option}</h2>
                  <div className='w-full lg:px-40 sm:px-4'>
                    <video
                      ref={(el) => {
                        videosRef.current[index] = el;
                        handleVideoLoaded(el);
                      }}
                      className="border rounded-xl mb-4 custom-video"
                      src={item?.url}
                      width="100%"
                      muted
                      loop
                      controls
                      controlsList="nodownload" // Prevents download option
                    />
                  </div>
                </>
              ) : (
                <>
                  <Avatar alt="Cindy Baker" sx={{ width: 100, height: 100 }} src={`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRuLEziy2FWAM6Z0NT0yTmsmD2VySobPG4y7eWSEd4heKgu-cU0WCZLxn5Poox0Y9KYmyc&usqp=CAU`} />
                  <h2 className='text-2xl font-bold text-black' style={{ color: theme === 'dark' ? 'white' : 'black' }}>{item.option}</h2>
                  <div className='w-full lg:px-40 sm:px-4'>
                    <p className='text-gray-600 text-md text-center tracking-widest w-full' style={{ color: theme === 'dark' ? 'white' : 'black' }}>{item?.value}</p>
                  </div>
                </>
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default Slider;
