export function onUplProg(progressEvent){
    const percentCompleted = (progressEvent.loaded / progressEvent.total) * 100
    localStorage.setItem('percentCompleted', Math.round(percentCompleted));
    
    if(percentCompleted>=100){
        setTimeout(()=>localStorage.setItem('percentCompleted',0),3000)
        
        
    }

} 
