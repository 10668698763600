import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import './UplProgBar.css'




function UplProgBar() {
  // const value = Math.round(
  //   props.value,
  // )
  const [value, setValue] = useState(localStorage.getItem('percentCompleted'));
  function color() {
    if (value >= 75)
      return 'progress-bar bg-success';
    else if (value >= 50 && value < 75)
      return 'progress-bar bg-warning';
    else if (value >= 25 && value < 50)
      return 'progress-bar bg-info';
    else if (value > 0 && value < 25)
      return 'progress-bar bg-danger'

  }
 

  useEffect(() => {
    const intervalId = setInterval(() => {
      // console.log('meraj1')
      const updatedValue = localStorage.getItem('percentCompleted');
      if (updatedValue !== value) {
        setValue(updatedValue);
      }
    }, 400);
    
    return () => clearInterval(intervalId);
  }, [value]);
  // console.log('localStorage',value)
  return (
    <>
      <Box className='prog_div' style={{ opacity:value>0?1:0}}>
        {/* <Typography variant="body2" color="white" style={{ textAlign: 'center' }}>Uploading</Typography> */}
        <div className="progress" >
          <div className={color()} role="progressbar" style={{ width: `${value}%`,minHeight:'15px' }} aria-valuenow={value} aria-valuemin="0" aria-valuemax="100">
            {`${Math.round(
            value,
          )}%`}
          </div>
        </div>
      </Box>



    </>
  );
}

export default UplProgBar
