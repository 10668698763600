export const array1 = [
    {
        option: "Find Your Tribe",
        id: "array101",
        type:'video',
        url: 'https://mebook-work-v2.s3.us-east-2.amazonaws.com/1725393859020-Find+Your+Tribe.mov'
    },
    {
        option: "Main Stage",
        id: "array102",
        type:'video',
        url: 'https://mebook-work-v2.s3.us-east-2.amazonaws.com/1725394161743-Main+Stage+%281%29.mov'
    },
    // {
    //     option: " Main Stage",
    //     id: "1",
    //     value:
    //         "This Audience Identity is reserved for creators, collaborators, influencers, providers, project developers and readers/consumers who are willing to pay a small premium to be certain that their profile is continually vetted*, free of errors, and fully functional on an ongoing monthly basis. This Audience Identity is purposed to provide and an extra layer of professionalism for profile presenters and profile seekers. *(content, claims and credentials will be verified by an investigative team for accuracy)",
    // },
    {
        option: "Bleeding Edge",
        id: "array103",
        type:'video',
        url: 'https://mebook-work-v2.s3.us-east-2.amazonaws.com/1725394478327-2+Bleeding+Edge.mov'
    },
    // {
    //     option: " Bleeding Edge",
    //     id: "2",
    //     value:
    //         "Bleeding Edge describes the very latest and most modern technology available. This Audience Identity is reserved for creators, collaborators, influencers, providers, project developers and reader/consumers who creatively contribute to a product or service, usually involving technology, that is available to consumers, but is considered “new,” “experimental” and “bold.” In the Global Media Marketplace, Bleeding Edge Creatives work with newer, more extreme, and riskier technologies and projects, providing engaged, charismatic trendsetters an opportunity to create and advance innovation and provide novel approaches to the most difficult challenges of Creatives.",
    // },
    {
        option: "Specialist",
        id: "array104",
        type:'video',
        url: 'https://mebook-work-v2.s3.us-east-2.amazonaws.com/1725394624384-1725298121928-1725297899387-Specialists+%282%29.mov'
    },
    // {
    //     option: "Specialist",
    //     id: "3",
    //     value:
    //         "This Audience Identity is reserved for creators, collaborators, influencers, providers, project and reader/consumers who, as a result of lengthy experience, education or specialized training, have attained a level of expertise in a specific area or discipline. A “Specialist-V” designation will indicate that the profile has been vetted by our research team. This Audience Identity is purposed to provide experienced individuals and experts an opportunity to share work and work, provide or integrate with other profiles on the platform.",
    // },
    {
        option: "Multi-Discipline",
        id: "array105",
        type:'video',
        url: 'https://mebook-work-v2.s3.us-east-2.amazonaws.com/1724957396590-multiDecipline.mp4'
    },
    // {
    //     option: "Multi-Discipline",
    //     id: "4",
    //     value:
    //         "This Audience Identity is reserved for creators,  collaborators, influencers, providers, project developers and reader/consumers whose talents and abilities legitimately expand across two or more levels of expertise — such that a single Audience Identity definition does not approximate actual comprehensive Audience Identity. This Audience Identity is purposed to provide profiles for individuals whose talents, abilities and experience expand across multiple disciplines. Consider carefully: in the Global Media Marketplace, specificity is helpful in defining a unique niche, although some seekers might consider “Multi- Discipline” a required niche for specific intentions and purposes.If more than 2 - 3 substantial identities, consider creating separate profiles.",
    // },
    {
        option: "Organic",
        id: "array106",
        type:'video',
        url: 'https://mebook-work-v2.s3.us-east-2.amazonaws.com/1724957525298-OrganicProfile.mp4'
    },
    // {
    //     option: "Organic",
    //     id: "5",
    //     value:
    //         "This Audience Identity is reserved for creators, collaborators, influencers, providers, project developers and reader/consumers whose focus is “authenticism,” which means that content presents original, unvetted material that might contain errors, coarse language and descriptions, misleading or false claims, profanity, adult or mild sexual content, all within site-defined or should be imposed limitations. This Audience Identity is purposed to provide a platform for users whose focus is more message and audience oriented, which often defies mainstream norms, allowing for greater free expression.",
    // },
    {
        option: "At-Large",
        id: "array107",
        type:'video',
        url: 'https://mebook-work-v2.s3.us-east-2.amazonaws.com/1724959689894-At+Large.mp4'
    },
    // {
    //     option: "At-Large",
    //     id: "6",
    //     value:
    //         "This Audience Identity is reserved for creators, collaborators, influencers, providers, project developers and reader/consumers who would rather not be defined by labels, and would rather be presented as uncharacterized, non-conformist individuals. Yet this Audience Identity values the importance  of the Global Media Marketplace and its necessity for individual success.This self-defined Audience Identity is purposed to give expression to the majority of users by providing the greatest exposure and potential for success. At-Large should be considered a 'catch-all' category."
    // }
];

export const array2 = [
    {
        option: "MeBookMeta Work Profile",
        id: "99",
        type:'video',
        url: 'https://mebook-work-v2.s3.us-east-2.amazonaws.com/1723577446846-WhatsApp+Video+2024-08-14+at+00.55.45.mp4'
    },
    {
        option: "Creator",
        id: "1",
        type:'video',
        value:
            "This profile is reserved for content creators, for those who generate original entertaining or educational material, to be expressed through any medium or channel. In the Global Media Marketplace, creators include presenters of all forms or writing, speech, performance, graphic and visual arts, music, sound and audio, dance and choreography, design, all aspects of media, television, film, and other original material.",
        url:'https://mebook-work-v2.s3.us-east-2.amazonaws.com/1723576933867-WhatsApp+Video+2024-08-13+at+23.36.48.mp4'
        },
    {
        option: "Collaborator",
        id: "2",
        type:'video',
        value:
        "This profile is reserved for those who work along with content creators, who generate original entertaining or educational material, to be expressed through any medium or channel. In the Global Media Marketplace, collaborators include those who work with presenters of all forms or writing, speech, performance, graphic and visual arts, music, sound and audio, dance and choreography, design, all aspects of media, television, film, and other original material. Those who work with any form of derivative work are also considered as collaborators.",
        url:'https://mebook-work-v2.s3.us-east-2.amazonaws.com/1723577759752-WhatsApp+Video+2024-08-14+at+00.55.45+%282%29.mp4'
    },
    {
        option: "Influencer",
        id: "3",
        type:'video',
        value:
            "This profile is reserved for those whose work product exerts a measurable influencer effect, on the Global Media Marketplace. Influencers include editorial writers, reviewers, commentary, criticism, opinion writers, bloggers, vloggers, ad writers, copy writers, satirists, video and short film makers, television and film favorites, celebrities, sports stars, media personalities and other niche personalities.",
        url:'https://mebook-work-v2.s3.us-east-2.amazonaws.com/1723576896170-WhatsApp+Video+2024-08-13+at+11.42.53+PM.mp4'
        },
    {
        option: "Project Developer",
        id: "4",
        type:'video',
        url:'https://mebook-work-v2.s3.us-east-2.amazonaws.com/1723577893873-WhatsApp+Video+2024-08-14+at+00.55.46.mp4',
        value:
            "This profile is reserved for those whose work involves the discovery and acquisition of original or existing work and developing that work to achieve a pre-determined goal. Project developers are often producers or entrepreneurs who are required to expand ideas to create overall visions, locate talented players for teams over multiple projects, develop budgets, find investors, manage, and complete projects on-time and within budget. Project Developers work with creators, collaborators, influencers and providers to bring work to the Global Media Marketplace.",
    },
    {
        option: "Provider",
        id: "5",
        type:'video',
        value:
            "This profile is reserved for those who provide necessary materials and services to creators, collaborators, influencers, project managers and reader/consumers to facilitate the proliferation and enjoyment of work. This vast category includes advertisers, app and website makers, beta testers, bookstores, casting agencies, caterers, coaches, coders, colleges and universities, designers, editors, equipment providers, financial institutions, investors, law firms, libraries, software makers, printers, promoters, publicists, publishers, rental and retail sources, scouts, security, software companies, talent agents, travel agents, and many other providers not listed above.",
        url: 'https://mebook-work-v2.s3.us-east-2.amazonaws.com/1723576055199-WhatsApp+Video+2024-08-13+at+11.34.44+PM.mp4'
    }
];

export const S3_VIDEO_URL = process.env.REACT_APP_API_S3URL;
export const S3_IMAGE_URL = process.env.REACT_APP_API_S3IMAGE_URL;